import React, { useEffect, useState, useContext, useRef } from "react";

// MUI components
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";

//base components
import ButtonIcon from "../../base_components/ButtonIcon";
import BaseTextField from "../../base_components/BaseTextField";

//import UploadDocument from "../UploadDocument/UploadDocument";
import UploadResources from "../UploadResources/UploadResources";
import TypingContext from "../../context/TypingContext";
import BaseButton from "../../base_components/BaseButton";

// contexts
import ThreadContext from "../../context/ThreadContext";
import PreferenceContext from "../../context/PreferenceContext";

// themes
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import highContrastTheme from "../../themes/highContrastTheme";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import shadows from "@mui/material/styles/shadows";
const FollowUpPromptComponent = ({
  followUpQuestions = null,
  handleOnSelect,
}) => {
  const preferenceCxt = useContext(PreferenceContext);
  const preferredTheme = preferenceCxt.preferenceList.theme;

  const borderColor = {
    1: lightTheme.palette.primary.main,
    2: darkTheme.palette.secondary.main,
    3: highContrastTheme.palette.secondary.main,
  };

  const backgroundColor = {
    1: lightTheme.palette.background.paper,
    2: darkTheme.palette.background.paper,
    3: highContrastTheme.palette.background.paper,
  };

  const FollowUpPromptStyle = {
    border: `1px solid ${borderColor[preferredTheme]}`,
    backgroundColor: backgroundColor[preferredTheme],
    '&:hover': {
      backgroundColor: backgroundColor[preferredTheme],
    },
    color: `${borderColor[preferredTheme]}`,
    textTransform: "none",
    margin: "1px",
  };

  return (
    <div style={{ padding: "5px" }}>
      {followUpQuestions &&
        followUpQuestions.map((question, index) => {
          return (
            <BaseButton
              variant="outlined"
              size="small"
              customStyles={FollowUpPromptStyle}
              handleOnClick={(e) => handleOnSelect(e, question)}
              key={index}
            >
              {question}
            </BaseButton>
          );
        })}
    </div>
  );
};

const MessagePrompt = ({
  handleChange,
  userInputText,
  handleButtonClick,
  handleKeyDown,
  loading,
  isEditing,
  isGeneratingResponse,
  followUpQuestions = null,
  customStyles,
  stagingThreadPreferences,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const typingCxt = useContext(TypingContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const threadCxt = useContext(ThreadContext);
  const messagepromptref = useRef(null)
  const preferenceCxt = useContext(PreferenceContext);
  const preferredTheme = preferenceCxt.preferenceList.theme;

  customStyles = {
    ...customStyles,
    backgroundColor: preferredTheme === 1 ? '#FFFFFF' : '#161F27',
    boxShadow: preferredTheme === 1 
  ? '0 2px 8px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)' 
  : '0 2px 8px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.25)'
  };

  useEffect(() => {
    //if user clicks outside the search bar component, then the search bar will shrink to original size
    const handleClickOutside = (event) => {
      if (
        messagepromptref.current &&
        !messagepromptref.current.contains(event.target)
      ) {
        setIsExpanded(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const iconAdornment = isExpanded
    ? <FollowUpPromptComponent
      followUpQuestions={followUpQuestions}
      handleOnSelect={handleKeyDown}
    />
    : <></>;
  return (
    <div >
      { drawerOpen && <UploadResources open={() => setDrawerOpen(true)} close={() => setDrawerOpen(false) } stagingThreadPreferences={stagingThreadPreferences} />}
      {iconAdornment}
      <ClickAwayListener onClickAway={() => setIsExpanded(false)}>
        <div>
          <BaseTextField
            ref={messagepromptref}
            value={userInputText}
            isMultiline
            minRows={1}
            maxRows={4}
            placeholder={
              !isGeneratingResponse
                ? "Send a message."
                : !!isEditing
                  ? "Editing..."
                  : "Generating response..."
            }
            handleOnChange={handleChange}
            handleOnKeyDown={handleKeyDown}
            handleOnClick={() => setIsExpanded(true)}
            autoComplete="off"
            size={"small"}
            isDisabled={typingCxt.hasTyped || isGeneratingResponse || isEditing}
            InputProps={{
              startAdornment: (
                <IconButton onClick={() => setDrawerOpen(true)}>
                  <CloudUploadIcon />
                </IconButton>
              ),
              endAdornment: (
                <ButtonIcon
                  disabled={loading || userInputText === ""}
                  buttonLabel="message-prompt"
                  handleSubmit={handleButtonClick}
                >
                  {loading ? (
                    <AutorenewIcon
                      sx={{
                        animation: "spin 2s linear infinite",
                        "@keyframes spin": {
                          "0%": {
                            transform: "rotate(0deg)",
                          },
                          "100%": {
                            transform: "rotate(360deg)",
                          },
                        },
                      }}
                    />
                  ) : (
                    <SendRoundedIcon />
                  )}
                </ButtonIcon>
              ),
            }}
            isFullWidth={true}
            id={"message-prompt-id"}
            customStyles={customStyles}
          />
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default MessagePrompt;