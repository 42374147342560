import React,{ createContext, useState } from "react";

const SearchResultContext = createContext({
  mostRecentSearchResults: [],
  setSearchResult: ()=>{},
});

export const SearchResultContextProvider = (props) => {
  const [mostRecentSearchResults, setMostRecentSearchResults] = useState('');

  const handleSetMostRecentSearchResults = (results) => {
    setMostRecentSearchResults(results);
  }

  const contextValue = {
    mostRecentSearchResults,
    setSearchResult: handleSetMostRecentSearchResults
  }

  return <SearchResultContext.Provider value={contextValue}>{props.children}</SearchResultContext.Provider>
}

export default SearchResultContext;