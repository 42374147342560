import flask from "./flask";

// Get metadata from the backend
const getDocumentMetadata = async (token) => {
  try {
    const response = await flask.get('metadata', {
      "headers": {
        "Authorization": `Bearer ${token}`
      }
    });

    return {"status": response.status, "data": response.data};
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default getDocumentMetadata;