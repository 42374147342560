// This component display code output in a styled, presentable code block format
import React, { useState } from 'react';
import rehypeHighlight from 'rehype-highlight';
import rehypeParse from 'rehype-parse';
import rehypeStringify from 'rehype-stringify';
import parse from 'html-react-parser';
import { rehype } from 'rehype';
import he from 'he';
import 'highlight.js/styles/ir-black.css'; // Import the CSS file for highlight.js styles

// MUI components
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { Container } from '@mui/material';

//base components
import BasePaper from "../../base_components/BasePaper";

const CodeBlock = ({ language = "python", code = "", showCopyIcon = true, customStyles = null, children }) => {

  //some custom styles that are re-used within the component
  const CodeBlockHeaderStyle = {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: "#141414",
    color: "#ffffff",
    padding: "3px",
    fontSize: "10pt",
    paddingLeft: "20px",
    paddingRight: "20px",
    marginTop: "30px",
  };

  let CodeBlockBodyStyle = {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
    backgroundColor: "#000000",
    color: "#ffffff",
    fontFamily: "monospace",
    marginBottom: "20px",
    flexGrow: 1
  };
  if (customStyles !== null) {
    CodeBlockBodyStyle = Object.assign(CodeBlockBodyStyle, customStyles);
  }

  const ContainerStyle = { marginLeft: '0', justifyContent: 'left', alignItems: 'left', display: "flex", flexDirection: "column" };
  const HeaderDivStyle = { display: "flex", justifyContent: "space-between", alignItems: "center" };
  const HeaderRightElementStyle = { display: "flex", alignItems: "center", justifyContent: "space-between", gap: "5px" };
  const CopyCodeButtonStyle = { background: "none", border: "none", font: "inherit", color: "inherit", cursor: "pointer" };
  const IconStyle = { fontSize: "12pt", color: "#ffffff" };

  //sub component to show Copy Code with icon
  const CopyCodeComponent = () => {
    return (
      <div style={HeaderRightElementStyle}>
        <ContentPasteRoundedIcon sx={IconStyle} />
        Copy code
      </div>
    );
  };

  //sub component to show Copied with icon
  const CopiedCodeComponent = () => {
    return (
      <div style={HeaderRightElementStyle} >
        <DoneRoundedIcon sx={IconStyle} />
        Copied
      </div >
    );
  }

  const CodeHighlighter = ({ code, language }) => {

    const highlightedCode = rehype()
      .use(rehypeParse, { fragment: true })
      .use(rehypeHighlight, { ignoreMissing: true })
      .use(rehypeStringify)
      .processSync(`<pre><code class="language-${language}">${he.encode(code)}</code></pre>`)
      .toString();
    const parsedCode = parse(highlightedCode);

    return <div>{parsedCode}</div>;

  }

  const [copyComponent, setCopyComponent] = useState(<CopyCodeComponent />);

  const onCopyCodeClick = () => {
    navigator.clipboard.writeText(code);
    showMessage(<CopyCodeComponent />, <CopiedCodeComponent />);
  };

  const showMessage = (originalComponent, newComponent) => {
    setCopyComponent(newComponent);

    setTimeout(() => {
      setCopyComponent(originalComponent);
    }, 2000);
  };

  return (
    <Container sx={ContainerStyle}>
      {/* Code block header */}
      <BasePaper customStyles={CodeBlockHeaderStyle} id={"code-block-header-id"}>
        <div style={HeaderDivStyle}>
          <span>{language.toLowerCase()}</span>
          {
            showCopyIcon ?
              <span>
                <button
                  onClick={onCopyCodeClick}
                  style={CopyCodeButtonStyle}
                >
                  {copyComponent}
                </button>
              </span>
              :
              <></>
          }
        </div>
      </BasePaper>


      {/* Code block body: contains code */}
      <BasePaper customStyles={CodeBlockBodyStyle} id={"code-block-body-id"}>
        {
          code !== "" ?     // if code is empty don't render it, thereby taking space if children is displayed
            <CodeHighlighter code={code} language={language} />
            :
            <></>
        }
        {children}
      </BasePaper>
    </Container>
  );
};

export default CodeBlock;