import React,{ createContext, useState } from "react";

const TokenContext = createContext({
    token: "",
    isLoggedIn: false,
    firstName: "",
    userName: "",
    azureToken: "",
    login: (token, firstName, userName, azureToken) => {},
    logout: () => {}
});

export const TokenContextProvider = (props) =>{
    const initialToken = sessionStorage.getItem("token");
    const initialFirstName = sessionStorage.getItem("firstName");
    const initialUserName = sessionStorage.getItem("userName");
    const initialAzureToken = sessionStorage.getItem("azureToken");
    const [token, setToken] = useState(initialToken);
    const [firstName, setFirstName] = useState(initialFirstName);
    const [userName, setUserName] = useState(initialUserName);
    const [azureToken, setAzureToken] = useState("");
    
    const isLoggedIn = !!token;

    const handleLogIn = (token, firstName, userName, azureToken) => {
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("firstName", firstName);
        sessionStorage.setItem("azureToken", azureToken);
        sessionStorage.setItem("userName", userName);
        setToken(token);
        setFirstName(firstName);
        setAzureToken(azureToken);
        setUserName(userName);
    }
    
    const handleLogOut = () => {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("firstName");
        sessionStorage.removeItem("azureToken");
        sessionStorage.removeItem("userName");
        setToken(null);
        setFirstName("");
        setAzureToken("");
        setUserName("");
    }

    const contextValue = {
        token,
        isLoggedIn,
        firstName,
        azureToken,
        userName,
        login: handleLogIn,
        logout: handleLogOut
    }

  return <TokenContext.Provider value={contextValue}>{props.children}</TokenContext.Provider>
}

export default TokenContext;