import React, { useState, useContext, useEffect } from 'react';
import { Chip, Menu, MenuItem, ListItemText, ListSubheader, Checkbox, ListItemIcon, Divider, Tooltip } from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import BaseTooltip from '../../base_components/BaseTooltip';
import { Reply } from "@mui/icons-material";
import { OpenInNew } from "@mui/icons-material";
import { OperationalModeContext } from '../../context/OperationalModeContext';
import PreferenceContext from '../../context/PreferenceContext';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';

const ModeSelectorChip = ({
  subLabel,
  handleOnClick,
  modeDetailsAnchorEl,
  handleOnClose,
  handleGeneralModeClick,
  handleMenuItemClick,
  handleOpenDocumentList,
  selectedInternalDocumentSources,
}) => {
  const {
    mode,
    changeMode,
  } = useContext(OperationalModeContext);
  const preferenceCxt = useContext(PreferenceContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    !!(preferenceCxt.preferenceList.documentMode == "Jarvis") && setSelected(preferenceCxt.preferenceList.selectedDocuments.split("##"));
  }, [selectedInternalDocumentSources])

  return (
    <div>
      <Chip
        icon={<LayersIcon color="primary" />}
        label={`Mode: ${mode} ${subLabel ? `(${subLabel})` : ""}`}

        variant="outlined"
        size="medium"
        onClick={(event) => handleOnClick(event)}
      />
      <Menu
        anchorEl={modeDetailsAnchorEl}
        open={Boolean(modeDetailsAnchorEl)}
        onClose={() => handleOnClose()}
      >
        <MenuItem
          key={3}
          onClick={() => handleGeneralModeClick()}
        >
          <ListItemIcon>
            <Reply fontSize="small" />
          </ListItemIcon>
          <ListItemText>General Mode</ListItemText>
        </MenuItem>
        <Divider />
        <ListSubheader sx={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary}}>Internal Document Sources (Jarvis)</ListSubheader>
        {["Shared", "Product Discovery", "Policies", "Nextens", "Tardis"].map(
          (item, index) => (
            <MenuItem
              key={index}
              onClick={() => handleMenuItemClick(item)}
            >
              {item !== "Policies" ? (
                <>
                  <Checkbox checked={!!selectedInternalDocumentSources ? selectedInternalDocumentSources.includes(item) : false} />
                  {item}
                </>
              ) :
                (
                  <>
                    <Checkbox checked={!!selectedInternalDocumentSources ? selectedInternalDocumentSources.includes(item) : false} />
                    {item}
                    <Tooltip title={
                      <span>
                        RELX Infosec IADP policies<br />
                        RELX InfoSec ISC policies<br />
                        HR Policies<br />
                        RISK Security Culture Policies<br />
                        RELX Compliance Policies
                      </span>
                    }
                    >
                      <InfoIcon style={{ marginLeft: '8px' }} />
                    </Tooltip>
                  </>
                )}
            </MenuItem>
          )
        )}
        <Divider />
        <ListSubheader sx={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary}}> User Uploaded Resources</ListSubheader>
        <MenuItem
          key={2}
          onClick={() => handleOpenDocumentList()}
          sx={{marginBottom: "7px"}}
        >
          <ListItemIcon>
            <OpenInNew fontSize="small" />
          </ListItemIcon>
          <ListItemText>My Resources</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ModeSelectorChip;
