import React, { useState, useContext, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import LoginPage from "./pages/login_page/LoginPage";
import CustomAlert from "./custom_components/CustomAlert";
import MainPage from "./pages/main_page/MainPage";
import getauthenticated from "./api/getauthenticated";
import { handleResponse, acquireToken, call_login, msalInstance, getProfileImage } from "./auth";
//contexts
import TokenContext from "./context/TokenContext";
import PreferenceContext from "./context/PreferenceContext";
import ThreadPreferenceContext from "./context/ThreadPreferenceContext";
import ThreadContext from "./context/ThreadContext";

//themes
import lightTheme from "./themes/lightTheme";
import darkTheme from "./themes/darkTheme";
import highContrastTheme from "./themes/highContrastTheme";
import { Box, CircularProgress, Typography } from "@mui/material";
import Logo from "./custom_components/Logo/Logo";
import './App.css';

import {
  THEME_NUMERIC_TO_WORDS,
  MODEL_WORDS_TO_NUMERIC,
  THEME_WORDS_TO_NUMERIC,
  EMBEDDING_MODEL_WORDS_TO_NUMERIC,
  CHUNKER_TYPE_WORDS_TO_NUMERIC,
} from "./config/constants";
import getSettings from "./api/getSettings";
import getThreadPreferences from "./api/getThreadPreferences";
import { OperationalModeContext } from "./context/OperationalModeContext";

function App() {
  const tokenCxt = useContext(TokenContext);
  const preferenceCxt = useContext(PreferenceContext);
  const threadPreferenceCxt = useContext(ThreadPreferenceContext);
  const threadCxt = useContext(ThreadContext);
  const { fetchSelectedDocuments } = useContext(OperationalModeContext);
  const [isLoading, setIsLoading] = useState(true);

  const handleRedirectAndLogin = async () => {
    try {
      const response = await msalInstance.handleRedirectPromise();
      let azureToken, graphToken, profileImage, backendResponse;

      if (!response) {
        const accounts = msalInstance.getAllAccounts();
        const storedToken = sessionStorage.getItem("token");
        const storedSession = sessionStorage.getItem("session");
        const storedFirstName = sessionStorage.getItem("firstName");
        const storedUserName = sessionStorage.getItem("userName");
        const storedAzureToken = sessionStorage.getItem("azureToken");

        if (
          accounts.length > 0 ||
          (storedToken && storedSession && storedFirstName && storedUserName && storedAzureToken)
        ) {
          azureToken =
            accounts.length > 0 ? await acquireToken(["700c77ee-dda2-4482-9022-a87273fc37c1/.default"]) : storedAzureToken;
          graphToken =
            accounts.length > 0 ? await acquireToken(["https://graph.microsoft.com/.default"]) : storedAzureToken;
          profileImage = await getProfileImage(graphToken);
          sessionStorage.setItem("profileImage", profileImage);
          backendResponse = await getauthenticated(azureToken);
          tokenCxt.login(backendResponse.data.jwt_token, backendResponse.data.first_name, backendResponse.data.username, azureToken, profileImage);
        } else {
          await msalInstance.loginRedirect(call_login);
        }
      } else {
        azureToken = await acquireToken(["700c77ee-dda2-4482-9022-a87273fc37c1/.default"]);
        graphToken = await acquireToken(["https://graph.microsoft.com/.default"]);
        profileImage = await getProfileImage(graphToken);
        sessionStorage.setItem("profileImage", profileImage);
        backendResponse = await getauthenticated(azureToken);
        tokenCxt.login(backendResponse.data.jwt_token, backendResponse.data.first_name, backendResponse.data.username, azureToken, profileImage);
      }
      setIsLoading(false);

    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };


  const preFetchPreferences = async () => {
    if (!tokenCxt.isLoggedIn) return;
    setIsLoading(true);
    try {
      const res = await getSettings(tokenCxt.token);
      const fetchedPreferences = res.data;

      if (fetchedPreferences && Object.keys(fetchedPreferences).length > 0) {
        fetchedPreferences["theme"] =
          typeof fetchedPreferences["theme"] == "number"
            ? fetchedPreferences["theme"]
            : THEME_WORDS_TO_NUMERIC[fetchedPreferences["theme"]];
        fetchedPreferences["model"] =
          typeof fetchedPreferences["model"] == "number"
            ? fetchedPreferences["model"]
            : MODEL_WORDS_TO_NUMERIC[fetchedPreferences["model"]];
        if (fetchedPreferences["embeddingModel"]) {
          fetchedPreferences["embeddingModel"] =
            typeof fetchedPreferences["embeddingModel"] == "number"
              ? fetchedPreferences["embeddingModel"]
              : EMBEDDING_MODEL_WORDS_TO_NUMERIC[fetchedPreferences["embeddingModel"]];
        }
        if (fetchedPreferences["chunkerType"]) {
          fetchedPreferences["chunkerType"] =
            typeof fetchedPreferences["chunkerType"] == "number"
              ? fetchedPreferences["chunkerType"]
              : CHUNKER_TYPE_WORDS_TO_NUMERIC[fetchedPreferences["chunkerType"]];
        }
        
        Object.keys(fetchedPreferences).forEach((key) => {
          if (typeof fetchedPreferences[key] != "number" && key != "stop" && key != "modelPersona" && key != "userBackground" && key != "documentMode" && key != "selectedDocuments") {
            fetchedPreferences[key] = Number(fetchedPreferences[key]);
          }
        });
        preferenceCxt.updatePreferences(fetchedPreferences);
      }
    } catch (error) {
      console.error("Error fetching preferences from db: ", error);
    }
  }

  useEffect(() => {
    handleRedirectAndLogin();
    !!tokenCxt.isLoggedIn && preFetchPreferences();
  }, [tokenCxt.isLoggedIn]);

  useEffect(() => {
    !!preferenceCxt.preferenceList && fetchSelectedDocuments(preferenceCxt.preferenceList);
  }, [threadCxt.threadID, preferenceCxt.preferenceList])

  const themeValueToNameMapping = {
    //maps theme value to actual MUI theme imported above
    1: lightTheme,
    2: darkTheme,
    3: highContrastTheme,
  };
  const loadingDesign = {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <>
      {isLoading && !tokenCxt.isLoggedIn ? (
        <Box sx={loadingDesign}>
          <Logo />
        </Box>
      ) : (
        <ThemeProvider
          theme={
            themeValueToNameMapping[preferenceCxt.preferenceList.theme] ||
            lightTheme
          }
        >
          <CssBaseline />
          {tokenCxt.isLoggedIn ? (
            <MainPage />
          ) : (
            <Box sx={loadingDesign}>
              <Logo />
            </Box>
          )}
          <CustomAlert />
        </ThemeProvider>
      )}
    </>
  );
}
export default App;
