import flask from "./flask";

// Get user's thread list
const putThreadPreferences = async (token, thread_id, preferences) => {
    const payload = {
        preferences: preferences
    }
    try {
        const response = await flask.put(`thread/${thread_id}/preferences`, payload,
        { headers: { 'Authorization': `Bearer ${token}` }}
        ); 

        return response;
    } catch (e) {
        return {
        status: e.response.status,
        error: e.response.statusText,
        data: "",
        };
    }
};

export default putThreadPreferences;