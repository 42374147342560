import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';

/**
  * Base speed dial component.
  * @param {string} ariaLabel: The aria-label of the button element. Also used to provide the id for the SpeedDial element and its children.
  * @param {node} children: SpeedDialActions to display when the SpeedDial is open.
  * @param {object} classes: Override or extend the styles applied to the component.
  * @param {string} direction: The direction the actions open relative to the floating action button.
  * @param {object} FabProps: Props applied to the Fab element.
  * @param {boolean} hidden: If true, the SpeedDial is hidden.
  * @param {node} icon: The icon to display in the SpeedDial Fab. The SpeedDialIcon component provides a default Icon with animation.
  * @param {func} onClose: Callback fired when the component requests to be closed.
  * @param {func} onOpen: Callback fired when the component requests to be open.
  * @param {boolean} open: If true, the component is shown.
  * @param {node} openIcon: If true, the component is shown.
  * @param {Array<func|object|bool>|func|object} sx: The system prop that allows defining system overrides as well as additional CSS styles.
  * @param {JSX.Element} TransitionComponent: The component used for the transition.
  * @param {integer} transitionDuration: The duration for the transition, in milliseconds. You may specify a single timeout for all transitions, or individually with an object.
  * @param {Object} TransitionProps: Props applied to the transition element. By default, the element is based on this Transition component.
  * @returns {JSX.Element} The rendered speed dial component.
*/

export default function BaseSpeedDial({ ariaLabel = '',
                                        children,
                                        classes,
                                        direction = "up",
                                        FabProps = {},
                                        hidden=false,
                                        customStyles = {},
                                        icon = "",
                                        id,
                                        open,
                                        onClose = () => open = false,
                                        onOpen  = () => open = true,
                                        openIcon,
                                        TransitionComponent,
                                        transitionDuration,
                                        TransitionProps
                                    })
    {
    return (
      <SpeedDial
        ariaLabel={ariaLabel}
        children = {children}
        classes = {classes}
        data-testid={id}
        direction = {direction}
        FabProps = {FabProps}
        hidden= {hidden}
        sx={customStyles}
        icon={icon}
        id={id}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        openIcon = {openIcon}
        TransitionComponent = {TransitionComponent}
        transitionDuration = {transitionDuration}
        TransitionProps ={TransitionProps}
        >
          {children}
        </SpeedDial>
    );
  }