import flask from "./flask";
import { parseMessages } from "./utils";

// threadID, sender, content, parentID
const postMessage = async (threadID, content, parentID, token, handleOnUploadProgress, 
  mode, selectedUserDocuments, selectedInternalDocumentSources, stagingThreadPreferences
  ) => {
  try {
    console.log(stagingThreadPreferences);
    let payload = (!threadID && !parentID) ?  
    {
      "content": content,
      "mode": mode,
      "selected_user_documents": selectedUserDocuments,
      "selected_internal_document_sources": selectedInternalDocumentSources
    } : {
      "thread_id": threadID,
      "content": content,
      "parent_id": parentID,
      "mode": mode,
      "selected_user_documents": selectedUserDocuments,
      "selected_internal_document_sources": selectedInternalDocumentSources,
    };

    if (!!stagingThreadPreferences && !threadID && !parentID){
      payload = {...payload, "staging_thread_preferences": stagingThreadPreferences};
    }

    // The condition to check for threadID and parentID doesn't work when using RAG and going back to the previous thread
    // if (!!stagingThreadPreferences){
    //   payload = {...payload, "staging_thread_preferences": stagingThreadPreferences};
    // }

    const response = await flask.post(`message`, 
      payload, 
      {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        onUploadProgress: handleOnUploadProgress
      }
    );
    const messages = parseMessages(response.data);

    return {
      status: response.status,
      data: messages,
    };
  } 
  catch (e) {
    console.log("Error posting message: ", e);
    console.log(e)
    return {
      status: e.response.status,
      error: e.response.statusText,
      msg: e.response.data.message,
      data: "",
    };
  }
};

export default postMessage;