import flask from "./flask";


// Edit a user's document metadata
const putDocument = async (token, documentId, new_title=null, new_tags=null) => {
  let payload = {
    "new_title": new_title,
    "new_tags": new_tags
  }

  try {
    const response = await flask.put(`document/${documentId}` , payload, {
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    });

    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default putDocument;