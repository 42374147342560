import React, { useState, useEffect } from 'react';
import Joyride from 'react-joyride';

//constants
import { TOUR_STEPS } from "../../config/constants";

const Tour = ({ handleBeginTourClose }) => {
  // custom styles
  const tourBoxStyle = {
    tooltipContainer: {
      textAlign: "left"
    },
    buttonNext: {
      backgroundColor: "#202123"
    },
    buttonBack: {
      color: "#202123"
    }
  };

  const [isTourRunning, setIsTourRunning] = useState(false);

  const handleTourClose = () => {
    setIsTourRunning(false);
    handleBeginTourClose();
  };

  const handleTourReset = (data) => {
    const { action } = data;

    if (action === "close" || action === "reset") {
      handleTourClose();
    }
  };

  useEffect(() => {
    setIsTourRunning(true); // Start the tour once the target element is mounted
  }, []);

  return (
    <>
      <div>
        <Joyride
          steps={TOUR_STEPS}
          run={isTourRunning}
          continuous={true}
          styles={tourBoxStyle}
          callback={handleTourReset}
        />
      </div>
    </>
  );
};

export default Tour;