import * as React from 'react';

// MUI components
import Slider from '@mui/material/Slider';

/**
  * Base Slider component.
  * @param {integer|float|Array} value: The value of the slider
  * @param {integer|float} min: The minimum value of the slider
  * @param {integer|float} max: The maximum value of the slider.
  * @param {integer|float} step: The step size used when changing the value of the slider.
  * @param {Array} marks: An array of objects defining custom marks on the slider.
  * @param {boolean} isDisabled: A boolean indicating whether the slider is disabled.
  * @param {string} orientation: The orientation of the slider, either "horizontal" or "vertical".
  * @param {integer|float|Array} defaultValue: The default value of the slider.
  * @param {string} valueLabelDisplay: Determines when to display the value label. Possible values are "off", "auto", or "on".
  * @param {string|boolean} track: The style of the track. Possible values are "normal", "inverted", or false to hide the track.
  * @param {string} color: The color of the slider. Possible values are "primary", "secondary", or a custom color.
  * @param {Function} getAriaValueText: A function that returns the text to be used as the aria-valuetext attribute.
  * @param {string} ariaLabel: The label of the slider.
  * @param {string} ariaLabelledBy: The ID of the element that serves as the label for the slider.
  * @param {string} size: The The size of the slider. Ex: 'small', 'medium' or any other string.
  * @param {object} slotProps: The props used for each slot inside the Slider.
  * @param {string|JSX.Element} slots: The components used for each slot inside the Slider. Either a string to use a HTML element or a component.
  * @param {object} classes: An object containing custom CSS classes for different parts of the slider.
  * @param {Object} customStyles: any object with valid CSS attributes
  * @param {Function} handleOnChange: A callback function called when the value of the slider changes.
  * @param {JSX.Element} children: any child React elements to be rendered within the button
  * @returns {JSX.Element} The rendered Slider component.
*/


export default function BaseSlider({  value,
                                      min=0,
                                      max=100,
                                      step=1,
                                      marks=false,
                                      isDisabled=false,
                                      orientation='horizontal',
                                      defaultValue,
                                      valueLabelDisplay="off",
                                      track="normal",
                                      color="primary",
                                      getAriaValueText,
                                      ariaLabel,
                                      ariaLabelledBy,
                                      size="medium",
                                      slotProps,
                                      slots,
                                      classes,
                                      customStyles,
                                      handleOnChange,
                                      children = "Default",
                                      name
                                    }) {

  return (
    <Slider
      value={value}
      min={min}
      max={max}
      step={step}
      marks={marks}
      disabled={isDisabled}
      orientation={orientation}
      defaultValue={defaultValue}
      valueLabelDisplay={valueLabelDisplay}
      track={track}
      color={color}
      getAriaValueText={getAriaValueText}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      classes={classes}
      size={size}
      slotProps={slotProps}
      slots={slots}
      sx={customStyles}
      onChange={handleOnChange}
      name={name}
    >
      {children}
    </Slider>
  );
}