import { createTheme } from '@mui/material/styles';
import { red, yellow, green } from '@mui/material/colors';


const highContrastTheme = createTheme({
  typography: {
    //fontFamily: 'Open Sans',
    fontSize: 12
  },
  palette: {
    type: 'dark', // Set the theme type to 'dark' for a high contrast effect
    primary: {
      main: '#000000', // Customize the primary color
    },
    secondary: {
      main: '#03C2FE', // Customize the secondary color
    },
    success: {
      main: '#00ff00', // Customize the error color
    },
    warning: {
      main: '#FFA500', // Customize the error color
    },
    error: {
      main: '#ff0000', // Customize the error color
    },
    info: {
      main: '#00FFFF', // Customize the error color
    },
    background: {
      default: '#000000', // Customize the background color
      // paper: '#353740', // Customize the paper color
      paper: '#000000', // Customize the paper color
    },
    text: {
      primary: '#ffffff', // Customize the primary text color
      secondary: '#cccccc', // Customize the secondary text color
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          border: '1px solid #03C2FE',
          '&:hover': {
            color: '#FFFFFF',
            border: '1px solid #03C2FE', // Change the color on hover
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          outline: 'solid',
          outlineColor: '#03C2FE'
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          color: '#FFFFFF'
        }
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'document_card' },
          style: {
            backgroundColor: '#000000',
          },
        },
        {
          props: { variant: 'selected_document_card' },
          style: {
            backgroundColor: '#489d48',
          },
        }
      ],
      styleOverrides: {
        root: {
          border: '1px solid #03C2FE'
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#03C2FE',
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { id: 'code-block-body-id' },
          style: {
            border: '1px solid #03C2FE',
          },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          border: '1px solid #03C2FE',
        },
        icon: {
          color: '#03C2FE', //icon color in select input(like the dropdown arrow)
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { disabled: true },
          style: {
            outline: 'none',
            border: 'none',
            borderRadius: '10px',
            backgroundImage: 'linear-gradient(90deg, #03C2FE 50%, transparent 50%), linear-gradient(90deg, #03C2FE 50%, transparent 50%), linear-gradient(0deg, #03C2FE 50%, transparent 50%), linear-gradient(0deg, #03C2FE 50%, transparent 50%)',
            backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
            backgroundSize: '15px 4px, 15px 4px, 4px 15px, 4px 15px',
            animation: 'border-dance 4s infinite linear',
            "@keyframes border-dance": {
              "0%": {
                backgroundPosition: "left top, right bottom, left bottom, right   top",
              },
              "100%": {
                backgroundPosition: "left 15px top, right 15px bottom , left bottom 15px , right   top 15px",
              }
            }
          },
        },
        {
          props: { placeholder: "Search..." },
          style: {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
              },
              "&:hover fieldset": {
                border: "2px solid #03C2FE",
              },
              "&.Mui-focused fieldset": {
                border: "1.5px solid #03C2FE",
              },
            },
          },
        }
      ],
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#03C2FE',
            },
            '&:hover fieldset': {
              borderColor: '#03C2FE',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#03C2FE',
            },
          },
          backgroundColor: '#000000',
        },

      },
    },
    MuiSlider: {  //overriding the slider color
      styleOverrides: {
        track: {
          backgroundColor: '#03C2FE',
        },
        thumb: {
          backgroundColor: '#03C2FE',
        },
        rail: {
          backgroundColor: '#9E9E9E',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        standardSuccess: {
          backgroundColor: green[600],
          color: '#FFF',
        },
        standardInfo: {
          backgroundColor: '#1976d2',
          color: '#FFF',
        },
        standardWarning: {
          backgroundColor: yellow[900],
          color: '#FFF',
        },
        standardError: {
          backgroundColor: red[700],
          color: '#FFF',
        },
      },
    },
    MuiSpeedDial: {
      styleOverrides: {
        fab: {
          backgroundColor: '#000000', // Custom icon color
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#000000',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          border: "1px solid #03C2FE",
          borderRadius: '10px',
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          border: "1px solid #03C2FE",
          '&:hover': {
            backgroundColor: "rgba(8,179,248,0.5)",
          },
          '&.Mui-selected': {
            '&:hover': {
              backgroundColor: "rgba(8,179,248,0.5)",
            },
            backgroundColor: "#03C2FE",
          },
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#000000',
          borderBottom: '1px solid #03C2FE',
        }
      }
    },
    MuiDrawer: {
      variants: [
        {
          props: { id : "sidebar-drawer-id" },
          style: {
            '& .MuiDrawer-paper': {
              width: 240,
              display: 'grid',
              background: '#000000',
              boxShadow: '2px 0 6px rgba(0, 0, 0, 0.1), 3px 0 8px rgba(0, 0, 0, 0.1 )',
              transition: 'all 0.3s ease',
              color: '#F7F7F8',
            },
            
          },
        },
      ],
      styleOverrides: {
        paper: {
          borderRight: '1px solid #03C2FE',
          backgroundColor: '#000000',
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: '1px solid #03C2FE',
          minWidth: '25vw',
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #03C2FE',
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          border: '1px solid #03C2FE',
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "0px 0px 0px 0px !important",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        // Customize the scrollbar for the root container (e.g., body or a specific component)
        '::-webkit-scrollbar': {
          width: '8px', // Specify the width of the scrollbar
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Specify the background color of the scrollbar track
        },
        '::-webkit-scrollbar-thumb': {
          background: '#888', // Specify the color of the scrollbar thumb
          borderRadius: '4px', // Specify the border radius of the scrollbar thumb
        },
      },
    },
  }
});

export default highContrastTheme;
