import React, { useEffect, useState } from 'react';
import bot11 from "../../assets/bot11.svg";

const Logo = () => {
  const [isDrawn, setIsDrawn] = useState(false);

  useEffect(() => {
    // Set a timeout to simulate the drawing effect
    const timeout = setTimeout(() => {
      setIsDrawn(true);
    }, 100);

    return () => clearTimeout(timeout); // Clean up the timeout on unmount
  }, []);

  return (
    <div>
      <img src={bot11} alt="Logo"
        style={{
          width: '50px',
          height: '50px',
          marginRight: '15px',
          animation: 'zoomAnimation 4s infinite',

        }} />
      <style>
        {`
          @keyframes zoomAnimation {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.1);
            }
            100% {
              transform: scale(1);
            }
          }
        `}
      </style>


      {/* <img src={bot11} alt="Logo"
        style={{
          position: 'relative',
          width:50,
          height:50,
          // transform: 'translate(-50%, -50%)',
          animation: 'bounce 3s ease-in-out infinite',
        }} />
      <style>
        {`
          @keyframes bounce {
            0%,
          100% {
            transform: translate(-50%, 50%);
          }
          50% {
            transform: translate(-50%, 65%);
          }
        `}
      </style> */}

    </div>
  );
}

export default Logo;
