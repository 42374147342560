import * as React from 'react';

// MUI components
import Paper from '@mui/material/Paper';

/**
  * Base Paper component.
  * 
  * @param {object} classes: An object containing custom CSS classes for different parts of the slider.
  * @param {string|JSX.Element} component: The component used for the root node. Either a string to use a HTML element or a component.
  * @param {integer} elevation: The elevation level of the paper. It can be a value between 0 and 24.
  * @param {boolean} isSquare: If true, rounded corners are disabled
  * @param {Object} customStyles: any object with valid CSS attributes
  * @param {string} variant: The variant of the paper. It can be "elevation" or "outlined"
  * @returns {JSX.Element} The rendered Paper component.
*/


export default function BasePaper({ classes,
                                    component,
                                    elevation=1,
                                    id,
                                    isSquare=false,
                                    handleClick,
                                    customStyles,
                                    variant="elevation",
                                    children = "Default"
                                    }) {

  return (
    <Paper
      classes={classes}
      component={component}
      data-testid={id}
      elevation={elevation}
      id={id}
      square={isSquare}
      onClick={handleClick}
      sx={customStyles}
      variant={variant}
    >
      {children}
    </Paper>
  );
}