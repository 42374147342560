import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import BaseTooltip from "../../base_components/BaseTooltip";
import BaseSlider from "../../base_components/BaseSlider";

const SettingSlider = ({ value, onChange, isDisabled = false, title, name, min = 0, max = 2, step = 0.1, tooltipContent }) => {
    const CustomIconStyle = { fontSize: 18, marginLeft: 1 };

    return (
        <Box sx={{ maxWidth: "90%", p: 2, mx: "auto" }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} lg={3}>
                    <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
                        {title}
                        <BaseTooltip title={tooltipContent} isArrow>
                            <HelpOutlineRoundedIcon sx={CustomIconStyle} />
                        </BaseTooltip>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <BaseSlider
                        value={value}
                        defaultValue={1}
                        valueLabelDisplay="auto"
                        min={min}
                        max={max}
                        step={step}
                        handleOnChange={onChange}
                        isDisabled={isDisabled}
                        name={name}
                        customStyles={{ width: '100%' }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettingSlider;
