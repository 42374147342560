import flask from "./flask";

// Get user's thread list
const getThreadList = async (token) => {
  try {
    const response = await flask.get('thread',
      { headers: { 'Authorization': `Bearer ${token}` }}
    ); 
    return response;
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default getThreadList;