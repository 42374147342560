import React, { useContext, useState, useEffect } from "react";

// Base Components
import BaseButton from "../../base_components/BaseButton";

// Mui Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Mui Components
import Box from '@mui/material/Box';

// Custom Components
import ThreadItem from "../ThreadItem/ThreadItem";

// Backend Calls
import getThreadList from "../../api/getThreadList"
import deleteThread from "../../api/deleteThread";
import getMessages from "../../api/getMessages";

// Context
import TokenContext from "../../context/TokenContext";
import ThreadContext from "../../context/ThreadContext";
import { AlertContext } from "../../context/AlertContext";


//custom styles
const BoxStyle = {
  marginLeft: '2%',
  marginTop: '13px',
  marginRight: '2%',
};

const ThreadListStyle = {
  marginTop: '10px',
  height: '80vh',
  overflowY: 'auto'
};

const BaseNewChatButtonStyle = {
  color: 'white',
  marginBottom: '5%',
  outlinesStyle: 'solid',
  borderColor: 'rgba(217, 217, 227, 0.5)',
  backgroundColor: '#0E1117',
  borderRadius: '10px',
  padding: '10px 10px',
  textTransform: 'uppercase',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: '#171C26',
    transform: 'translateY(-1px)',
    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)',

  },
  '&:active': {
    backgroundColor: '#0E0F11',
    transform: 'translateY(0)',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  },
  '&:focus': {
    boxShadow: '0 0 0 3px rgba(255, 255, 255, 0.5)',

  },
};

// const BaseNewChatButtonStyle = {
//   color: 'white',
//   marginBottom: '5%',
//   // outlineStyle: 'solid',
//   // outlineWidth: '0.5px',
//   // border: '1px solid rgba(217, 217, 227, 0.5)',
//   borderRadius: '10px'
// };

const NewChatIconStyle = {
  marginRight: '8px',
  color: "#FFFFFF"
};


const ThreadList = () => {
  const tokenCxt = useContext(TokenContext);
  const threadCxt = useContext(ThreadContext)
  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    const getUserThreads = async () => {
      const userThreads = await getThreadList(tokenCxt.token);
      if (userThreads && userThreads.status === 200)
        threadCxt.setThreadList(userThreads.data);
      else
        console.error("ThreadList Get Failed!");
    };
    !!tokenCxt.token && getUserThreads();
    return () => {
      // Cleanup function for when component unmounts.
    };
  }, []);

  const handleMessageThread = (threadID) => {
    threadCxt.threadselect(threadID);
    threadCxt.setMessageSelected(false);
    threadCxt.messageselect('')
  }

  const handleDeleteThread = (threadID) => {
    threadCxt.setThreadList(threadCxt.threadList.filter((value) => value.thread_id !== threadID));
    deleteThread(threadID, tokenCxt.token)
      .then((res) => {
        if (res && res.status === 200) {
          threadCxt.threadselect(null);
          showAlert("Thread successfully deleted", "success");
        }
      })
      .catch((err) => {
        showAlert("Thread deletion unsuccessful.", "error");
        console.error("Delete Thread Failed! ");
      });
  }

  return (
    <Box sx={BoxStyle}>
      <div className='newChatButton'>
        <BaseButton
          isFullWidth={true}
          customStyles={BaseNewChatButtonStyle}
          handleOnClick={() => threadCxt.threadselect(null)}
          id={"new-chat-button-id"}
        >
          <AddCircleOutlineIcon
            color="white"
            fontSize="small"
            sx={NewChatIconStyle}
          /> New Chat
        </BaseButton>
      </div>

      <div className="threadList" style={ThreadListStyle} data-testid={"thread-list-id"}>
        {threadCxt.threadList !== undefined ?
          threadCxt.threadList.map((thread, index) => (
            <ThreadItem
              key={thread.thread_id}
              handleDeleteThread={handleDeleteThread}
              handleMessageThread={handleMessageThread}
              text={thread.thread_name}
              index={index}
              thread_id={thread.thread_id}
              selected={threadCxt.threadID == thread.thread_id} />
          )) : <></>}
      </div>
    </Box>
  )
}
export default ThreadList;