
import React, { useContext, useEffect } from "react";
import { AlertContext } from "../context/AlertContext";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const CustomAlert = () => {
  const { alert, hideAlert } = useContext(AlertContext);

  useEffect(() => {
    if (alert.open) {
      const timer = setTimeout(() => {
        hideAlert();
      }, alert.duration);

      return () => clearTimeout(timer);
    }
  }, [alert, hideAlert]);

  return (
    <Snackbar
      open={alert.open}
      autoHideDuration={alert.duration}
      onClose={hideAlert}
      anchorOrigin={{ vertical: alert.vertical, horizontal: alert.horizontal }}
      key={alert.vertical + alert.horizontal}
      variant={alert.variant}
    >
      <Alert
        onClose={hideAlert}
        severity={alert.severity}
        sx={{ width: "100%" }}
      >
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;
