import React, { useState, useContext, useEffect } from 'react';
import jsPDF from 'jspdf';
import saveAs from 'file-saver';

//base components
import BaseSpeedDial from "../../base_components/BaseSpeedDial";

// MUI components
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import SpeedDialAction from "@mui/material/SpeedDialAction";

//context
import ThreadContext from "../../context/ThreadContext";


const SpeedDialActions = (actions) => {
  return actions.map((action) => (
    <SpeedDialAction
      key={action.name}
      icon={action.icon}
      tooltipTitle={action.name}
      onClick={action.handler}
    />
  ))
};

const ChatDownload = ({ conversation, title, customStyles }) => {

  const [open, setOpen] = useState(false);
  const [adjustedConversation, setAdjustedConversation] = useState(conversation);
  const threadCxt = useContext(ThreadContext);

  useEffect(() => {
    //keep only sender, content and timestamp in a conversation
    const truncatedConversation = conversation.map(({messageID, siblings, parentID, isAnEdit, threadID, ...rest}) => rest); 
    setAdjustedConversation(truncatedConversation);
  }, [conversation]);

  const handleTextDownload = () => {
    let data = JSON.stringify(adjustedConversation);
    let type = 'text/plain;charset=utf-8';
    const file = new Blob([data], { type });
    saveAs(file, "chat-history-text.txt");
  };

  const handleJSONDownload = () => {
    let data = JSON.stringify(adjustedConversation);
    let type = 'application/json;charset=utf-8';
    const file = new Blob([data], { type });
    saveAs(file, "chat-history-json.json");
  };

  // Check if content exceeds available space on current page
  const checkPageOverflow = (contentHeight, currentYPosition, pageHeight) => {
    return (currentYPosition + contentHeight) > pageHeight;
  }

  const getThreadName = (conversation) => {
    if (!!threadCxt.threadList && !!conversation) {
      const threadName = threadCxt.threadList.filter((aThread) => (aThread.thread_id===conversation[0].threadID))[0].thread_name;
      return threadName
    }
  };

  const getSenderFirstName = (sender) => {
    const firstWhiteSpace = sender.indexOf(" ");
    return sender.substring(0, firstWhiteSpace);
  }

  const handlePDFDownload = () => {
    //IMPORTANT: using conversation in this handler because we need to use thread_id
    const threadName = getThreadName(conversation);
    const senderFirstName = getSenderFirstName(conversation[0].sender);

    const doc = new jsPDF(); //defaults to A4 size

    // Styling
    const styleForUser = { color: 'blue' };
    const styleForModel = { color: 'green' };
    const codeBlockStyle = { font: 'courier', fontSize: 10, fillColor: '#F2F2F2', textColor: '#333333', padding: 5 };
    const fontSize = 11;
    const fontHeight = 5; // 5mm is roughly the height of an 11 sized font on A4 paper
    const regularLineWidth = 160;
    const codeLineWidth = 160;
    const headerText = title ? title : threadName;

    // Set font size and style for the header
    doc.setFontSize(15);
    doc.setFont('helvetica', 'bold');
    doc.text(headerText, 10, 20);

    // Define page dimensions and margins
    const pageHeight = doc.internal.pageSize.getHeight();
    const marginTop = 30;
    const marginBottom = 10;
    const contentHeight = pageHeight - marginTop - marginBottom;

    // Initialize page counter and current Y position
    let pageCounter = 1;
    let currentYPosition = marginTop;

    conversation.forEach(({ content, sender }) => {
      const style = sender === 'model' ? styleForModel : styleForUser; // Apply styling based on the speaker
      sender = sender === 'model'? 'LN Chat' : senderFirstName;

      const regex = /```([\s\S]*?)```/g;
      const splitMessages = content.split(regex);
      splitMessages.map((part, index) => {
        if (!part) {
          return null;
        }
        if (index % 2 === 0) { //messages only occurs on even indices, and code occurs on odd indices
          let rawMessageLines = doc.splitTextToSize(part, regularLineWidth);
          const messageLines = rawMessageLines.filter((element) => element.trim() !== '');

          if (checkPageOverflow(messageLines.length * fontHeight, currentYPosition, contentHeight)) {
            doc.addPage();
            pageCounter++;
            currentYPosition = marginTop;
          }

          // Sender
          doc.setFontSize(fontSize);
          doc.setFont('helvetica', 'bold');
          doc.setTextColor(style.color);
          doc.text(sender, 10, currentYPosition);

          // Add regular message to the PDF
          doc.setFontSize(fontSize);
          doc.setFont('helvetica', 'normal');
          doc.setTextColor('black');
          doc.text(messageLines, 30, currentYPosition);
          currentYPosition += (messageLines.length * fontHeight) + fontHeight; 
        }
        else {
          const codeLines = doc.splitTextToSize(part, codeLineWidth - 35);
          const codeBlockHeight = (codeLines.length * (codeBlockStyle.fontSize / 2)) + (2 * codeBlockStyle.padding);

          if (checkPageOverflow(codeBlockHeight, currentYPosition, contentHeight)) {
            doc.addPage();
            pageCounter++;
            currentYPosition = marginTop;
          }

          // Add code block to the PDF with styling
          doc.setFontSize(codeBlockStyle.fontSize);
          doc.setFont(codeBlockStyle.font);
          doc.setFillColor(codeBlockStyle.fillColor);
          doc.setTextColor(codeBlockStyle.textColor);

          doc.rect(30, currentYPosition + codeBlockStyle.padding, codeLineWidth, codeBlockHeight, 'FD');
          doc.text(codeLines, 35, currentYPosition + codeBlockStyle.padding + codeBlockStyle.fontSize);
          currentYPosition += codeBlockHeight + 15;
        }
      })
    });

    doc.save('chat-history-pdf.pdf');
  };


  const actions = [
    { icon: <PictureAsPdfRoundedIcon />, type: 'pdf', name: 'Download as PDF', handler: handlePDFDownload },
    { icon: <DataObjectRoundedIcon />, type: 'json', name: 'Download as JSON', handler: handleJSONDownload },
    { icon: <ArticleRoundedIcon />, type: 'txt', name: 'Download as Text', handler: handleTextDownload },
  ];

  return (
      <div className="chatDownloadButton">
        <BaseSpeedDial 
          direction="up" 
          icon={<DownloadIcon />} 
          children={SpeedDialActions(actions)}
          customStyles={customStyles} 
          open={open}
          onOpen={ () => setOpen(true) }
          onClose={ () => setOpen(false) }
          id={"chat-download-speed-dial-id"}
        />
      </div>
  );
};

export default ChatDownload;
