import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Box,
  Typography,
  useTheme,
  Icon,
  CircularProgress,
} from "@mui/material";
import BaseTextField from "../../base_components/BaseTextField";
import BasePaper from "../../base_components/BasePaper";
import Tags from "./Tags";
import { AlertContext } from "../../context/AlertContext";
import PreferenceContext from "../../context/PreferenceContext";
import ThreadPreferenceContext from "../../context/ThreadPreferenceContext";
import TokenContext from "../../context/TokenContext";
import ThreadContext from "../../context/ThreadContext";
import postDocument from "../../api/postDocument";
import postDocumentMetadata from "../../api/postDocumentMetadata";
import getDocumentMetadata from "../../api/getDocumentMetadata";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import UploadIcon from "@mui/icons-material/CloudUpload";
import BaseButton from "../../base_components/BaseButton";

import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import highContrastTheme from "../../themes/highContrastTheme";
import { CHUNKER_TYPE_NUMERIC_TO_READABLE_WORDS, EMBEDDING_MODELS_NUMERIC_TO_READABLE_WORDS } from "../../config/constants";

const UploadText = ({currentChunkerType, currentEmbeddingModel}) => {
  const tokenCxt = useContext(TokenContext);
  const { showAlert } = useContext(AlertContext);
  const theme = useTheme();
  const [documentInfo, setDocumentInfo] = useState({
    title: "",
    tags: "",
    rawText: "",
    url: "",
    org: "Risk",
    vertical: "",
    department: "",
    product: "",
    team: "",
    customMetadata: [],
    accessibility: "",
    accessibleBy: [],
    isCustom: false,
    type: "text/plain",
    name: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const preferenceCxt = useContext(PreferenceContext);
  const preferredTheme = preferenceCxt.preferenceList.theme;
  const [tags, setTags] = useState([]);
  
  const threadCxt = useContext(ThreadContext);
  const threadPreferenceCxt = useContext(ThreadPreferenceContext);

  const [stagingThreadPreferences, setStagingThreadPreferences] = useState({});

  useEffect(() => {
    if (!threadCxt.threadID && !!preferenceCxt.preferenceList) {
        setStagingThreadPreferences({ ...preferenceCxt.preferenceList });
    }
}, [threadCxt.threadID, preferenceCxt.preferenceList]);

  const chunkerType = currentChunkerType;
  const isValidChunker = chunkerType === "Text Chunker" || chunkerType === "Semantic Chunker";
  const embeddingModel = currentEmbeddingModel;
  const isViableEmbeddingModel = true; // All embedding models are viable for text uploads

  const backgroundColor = {
    1: lightTheme.palette.background.default,
    2: darkTheme.palette.background.tabPaper,
    3: highContrastTheme.palette.background.default,
  };

  const textFieldColor = {
    1: lightTheme.palette.background.primary,
    2: '#2A303C',
    3: highContrastTheme.palette.text.primary,
  }

  const TabPaperStyle = {
    p: 2,
    mb: 2,
    backgroundColor: backgroundColor[preferredTheme],
    width: "100%",
  };

  const customTextFieldStyle = {
    backgroundColor: textFieldColor[preferredTheme],
    marginTop: "8px",
    marginBottom: "16px",
  };

  const handleChange = (prop) => (event) => {
    setDocumentInfo(prev => ({ ...prev, [prop]: event.target.value }));
  };

  const extractDocumentID = (url) => {
    let urlParts = url.split("/");
    urlParts = urlParts[4] + "/" + urlParts[5];
    return decodeURIComponent(urlParts.split("?")[0]);
  };

  const handleSubmit = async () => {
    if (documentInfo.title === "" || documentInfo.rawText === "") {
      showAlert("Title and content for the document cannot be empty", "error");
      return;
    }

    // Check for unique document name
    try {
      const existingDocs = await getDocumentMetadata(tokenCxt.token);
      if (existingDocs.data.some(doc => doc.document_name === documentInfo.title)) {
        showAlert("Document with the same title already exists!", "error");
        return;
      }
    } catch (error) {
      showAlert("Error retrieving existing documents metadata. Please try again.", "error");
      console.error("Error fetching document metadata:", error);
      return;
    }

    // Prepare FormData
    const formData = new FormData();
    formData.append('files[]', JSON.stringify({
      title: documentInfo.title,
      raw_text: documentInfo.rawText,
      url: documentInfo.url,
      organization: documentInfo.org,
      vertical: documentInfo.vertical,
      department: documentInfo.department,
      product: documentInfo.product,
      team: documentInfo.team,
      custom_metadata: JSON.stringify(documentInfo.customMetadata),
      accessibility: documentInfo.accessibility,
      accessible_by: JSON.stringify(documentInfo.accessibleBy),
      owner: tokenCxt.userName,
      is_custom: documentInfo.isCustom,
      type: documentInfo.type,
      tags: tags.join(","),
    }));

    // Append file to FormData
    if (documentInfo.file) {
      formData.append('file_0', documentInfo.file);
    }
    setIsLoading(true);
    try {
      const response = await postDocument(formData, tokenCxt.token, tokenCxt.userName);

      if (response.status === 200) {

        // Post metadata after successful document upload
        const metadataPayload = [{
          document_name: documentInfo.title,
          document_type: "text/plain",
          size: new Blob([documentInfo.rawText]).size,
          tags: tags.join(","),
          full_identifier: extractDocumentID(response.data[0]),
          "chunker_type": chunkerType,
          "embedding_model": embeddingModel,
        }];
        await postDocumentMetadata(metadataPayload, tokenCxt.token, tokenCxt.userName);

        showAlert("Document successfully uploaded!", "success");
      } else {
        showAlert("Error during the document submission: " + response.data.error, "error");
      }
    } catch (error) {
      showAlert("Error during the document submission. Please try again.", "error");
      console.error("Submission error:", error);
    } finally {
      setIsLoading(false);
    }

    // Reset document info after processing
    setDocumentInfo({
      title: "",
      tags: "",
      rawText: "",
      url: "",
      org: "Risk",
      vertical: "",
      department: "",
      product: "",
      team: "",
      customMetadata: [],
      accessibility: "",
      accessibleBy: [],
      isCustom: false,
      type: "text/plain",
      name: ""
    });
  };

  return (
    <Box p={4} mt={0} flexGrow={1}>
      <BasePaper customStyles={TabPaperStyle} elevation={0}>
        <Typography variant="body1" mt={1}>
          Enter text directly into the fields below to create a document that can be queried and retrieved for information.
        </Typography>
        <Typography variant="body1" mt={2}>
          Please be aware that currently only the 'Private' mode is available. Your uploaded data is not used to generate answers for any other users.
        </Typography>
        <Typography variant="body1" mt={2}>
          Both the <strong>Title</strong> and <strong>Body</strong> fields are required. Any text entered using this form will be uploaded as a text file (.txt format). This means that for optimal results, ensure you use either Text or Semantic chunker for upload.
        </Typography>
        <Typography variant="body1" mt={4}>
          <strong>Chunker Selection</strong>
        </Typography>
        <Typography variant="body1" mt={2}>
          RiskGPT offers different chunking methods to process your text effectively. The chunker selection determines how the text is split into smaller units for processing. The following chunkers are relevant for text uploads:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <strong>Text Chunker:</strong> Use this for basic splitting based on delimiters like new lines or paragraph breaks. Ideal for simple segmentation without semantic analysis.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Semantic Chunker:</strong> This option analyzes the meaning and structure of text, dividing it into logical units like sentences or topics. Best for unstructured data such as plain text or articles.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" mt={2}>
          <strong>Note:</strong> Only Text or Semantic chunkers are suitable for text uploads. Using other chunkers, such as JSON or Code Chunkers, may result in sub-optimal performance and even errors due to the text format of the uploaded data.
        </Typography>
        <Box mt={2} display="flex" alignItems="center" sx={{ border: 1, borderColor: 'grey.500', p: 1, borderRadius: 1 }}>
          <Typography variant="body1" sx={{ mr: 1 }}>
            Current Chunker Selection: <strong>{chunkerType}</strong>
          </Typography>
          {isValidChunker ? (
            <CheckCircleIcon style={{ color: theme.palette.success.main }} />
          ) : (
            <WarningIcon style={{ color: theme.palette.error.main }} />
          )}
          {!isValidChunker && (
            <Typography variant="body1" sx={{ ml: 1 }}>
              Please change to Text or Semantic chunker for optimal performance.
            </Typography>
          )}
        </Box>
        <Typography variant="body1" mt={4}>
          <strong>Embedding Model Selection</strong>
        </Typography>
        <Typography variant="body1" mt={2}>
          You can choose from four embedding models to process your text, each with increasing capabilities. It is crucial to use the same model for embedding and querying to ensure consistency:
        </Typography>
        <ul>
          <li><Typography variant="body1">Text Embedding ADA v2</Typography></li>
          <li><Typography variant="body1">Text Embedding v3 Small</Typography></li>
          <li><Typography variant="body1">Text Embedding v3 Medium</Typography></li>
          <li><Typography variant="body1">Text Embedding v3 Large</Typography></li>
        </ul>
        <Box mt={2} display="flex" alignItems="center" sx={{ border: 1, borderColor: 'grey.500', p: 1, borderRadius: 1 }}>
          <Typography variant="body1" sx={{ mr: 1 }}>
            Current Embedding Model: <strong>{embeddingModel}</strong>
          </Typography>
          <CheckCircleIcon style={{ color: theme.palette.success.main }} />
        </Box>
      </BasePaper>

      <BaseTextField
        placeholder="Enter Title"
        value={documentInfo.title}
        handleOnChange={handleChange('title')}
        isFullWidth
        id="document-title-text-id"
        size="small"
        customStyles={customTextFieldStyle}
      />

      <BaseTextField
        placeholder="Enter Body"
        value={documentInfo.rawText}
        handleOnChange={handleChange('rawText')}
        isFullWidth
        isMultiline
        minRows={15}
        id="document-body-text-id"
        size="small"
        customStyles={customTextFieldStyle}
      />
      <Tags
        selectedTags={tags}
        onUpdateTags={setTags}
        customTextFieldStyles={customTextFieldStyle}
      />

      <Box mt='5vh' display="flex" justifyContent="center">
        <BaseButton
          variant="contained"
          startIcon={<UploadIcon />}
          color="primary"
          handleOnClick={handleSubmit}
          sx={{
            transition: "all 0.5s ease",
            "&.Mui-disabled": {
              background: "",
              textShadow: "none",
              boxShadow: "none",
            },
          }}
          disabled={!isValidChunker || !isViableEmbeddingModel || isLoading || documentInfo.title === "" || documentInfo.rawText === ""}
        >
          {isLoading ? <CircularProgress size={24} /> : "Upload"}
        </BaseButton>
      </Box>
    </Box>
  );
};

export default UploadText;
