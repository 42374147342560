import React, { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import BaseTooltip from "../../base_components/BaseTooltip";
import BaseTextField from "../../base_components/BaseTextField";
import PreferenceContext from '../../context/PreferenceContext';

const SettingTextBox = ({ 
    value, 
    name, 
    title, 
    onChange, 
    tooltipContent, 
    minRows = 4, 
    placeholder 
}) => {
    const { preferenceList: { theme } } = useContext(PreferenceContext);

    const customStyles = {
        backgroundColor: theme === 1 ? "#FFFFFF" : theme === 2 ? "#262F40" : "#000000",
    };

    return (
        <Box sx={{ maxWidth: "90%", p: 2, mx: "auto" }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} lg={3}>
                    <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
                        {title}
                        <BaseTooltip title={tooltipContent} isArrow>
                            <HelpOutlineRoundedIcon sx={{ fontSize: 18, marginLeft: 1 }} />
                        </BaseTooltip>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <BaseTextField
                        placeholder={placeholder}
                        value={value}
                        handleOnChange={onChange}
                        isFullWidth
                        name={name}
                        isMultiline
                        minRows={minRows}
                        customStyles={customStyles}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettingTextBox;
