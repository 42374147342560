import * as React from 'react';

// MUI components
import Select from '@mui/material/Select';

/**
  * Base select input (dropdown) component.
  *
  * @param {string} value: specifies the current selected value
  * @param {string} variant: variant of the select, such as 'outlined', 'filled', or 'standard'
  * @param {boolean} isDisabled: true/false to enable/disable dropdown
  * @param {boolean} isAutoWidth: true/false to enable/disable full width adjustment to content
  * @param {string} selectLabel: any string value depicting label text for the select
  * @param {boolean} isError: true/false indicating if the select has an error state
  * @param {boolean} isRequired: true/false indicating if the select is required
  * @param {boolean} isMultiple: true/false indicating if selecting multiple options are allowed
  * @param {Object} MenuProps: props applied to the Menu component.
  * @param {boolean} isNative: true/false enabling user to use the native <select> element instead of a custom implementation
  * @param {Object} inputProps: aditional props passed to the underlying input element
  * @param {JSX.Element} startAdornment: elements to display at the start of the select input element
  * @param {JSX.Element} endAdornment: elements to display at the end of the select input element
  * @param {Object} classes: CSS classes to override or extend the default styles
  * @param {string} id: ID attribute for the select input of string type
  * @param {string} name: name of select input. string type
  * @param {boolean} isOpen: true/false enabling user to know if the component is opened or closed
  * @param {Object} customStyles: any object with valid CSS attributes
  * @param {Function} handleOnChange: handler/function that's triggered when the value changes
  * @param {Function} handleOnClose: handler/function that's triggered when the component requests to be closed
  * @param {Function} handleOnOpen: handler/function that's triggered when the component requests to be opened
  * @returns {JSX.Element} The rendered select component.
*/


// name: The name attribute for the select input.


export default function BaseSelect({  value,
                                      variant = "outlined",
                                      isDisabled = false,
                                      isAutoWidth = false,
                                      selectLabel="default-select-label",
                                      isError=false,
                                      isRequired=false,
                                      isMultiple=false,
                                      MenuProps,
                                      isNative=false,
                                      inputProps,
                                      startAdornment,
                                      endAdornment,
                                      classes,
                                      id="default-select-label",
                                      isOpen,
                                      customStyles,
                                      handleOnChange,
                                      handleOnClose,
                                      handleOnOpen,
                                      children = "Default",
                                      name }) {

  return (
    <Select
      value={value}
      variant={variant}
      disabled={isDisabled}
      autoWidth={isAutoWidth}
      label={selectLabel}
      error={isError}
      required={isRequired}
      multiple={isMultiple}
      MenuProps={MenuProps}
      native={isNative}
      inputProps={inputProps}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      classes={classes}
      id={id}
      open={isOpen}
      sx={customStyles}
      onChange={handleOnChange}
      onClose={handleOnClose}
      onOpen={handleOnOpen}
      name={name}
    >
      {children}
    </Select>
  );
}