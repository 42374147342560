import React, { createContext, useState } from "react";

const AlertContext = createContext();

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
    duration: 3000,
    vertical: "top",
    horizontal: "center",
    variant: "filled",
  });

  const showAlert = (
    message,
    severity = "success",
    duration = 3000,
    vertical = "top",
    horizontal = "center",
    variant = "filled"
  ) => {
    setAlert({
      open: true,
      message,
      severity,
      duration,
      vertical,
      horizontal,
      variant,
    });
  };

  const hideAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

export { AlertContext, AlertProvider };
