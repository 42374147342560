import flask from "./flask";

const getauthenticated = async (azureToken) => {
  const payload = {
    "token": azureToken
  }
  try {
    const response = await flask.post(`auth`, payload, 
    { headers: { 
      'Content-Type' : 'application/json'
    }}
    );
    
    return response;
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default getauthenticated;