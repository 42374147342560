import flask from "./flask";

// Get user's thread list
const getThreadPreferences = async (token, thread_id) => {
  try {
    const response = await flask.get(`thread/${thread_id}/preferences`,
      { headers: { 'Authorization': `Bearer ${token}` }}
    ); 
    return response;
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default getThreadPreferences;