// Constants used in the QuickActionsPanel component
export const DOCUMENTATION_URL = "https://reedelsevier.sharepoint.com/sites/InnovationEngineeringGroup/SitePages/RiskGPT.aspx";
export const R_AND_D_URL = "https://reedelsevier.sharepoint.com/sites/InnovationEngineeringGroup/SitePages/RiskGPT-R&D.aspx";
export const BEST_PRACTICES_URL = "https://reedelsevier.sharepoint.com/sites/InnovationEngineeringGroup/SitePages/Getting-started-with-RiskGPT.aspx";
export const TEAM_EMAIL = "innovation.engineering.group@lexisnexisrisk.com";
export const DISCLAIMER_MSG = "While there are safeguards in place, the system may occasionally generate incorrect or misleading information and produce offensive or biased content. It is not intended to give advice.";

// Max token values used in the SettingsList component
export const CHATGPT_MAX_TOKENS = 4096;
export const CHATGPT_LARGE_MAX_TOKENS = 16384;
export const GPT4_MAX_TOKENS = 8192;
export const GPT4_LARGE_MAX_TOKENS = 32768;
export const GPT4_OMNI_MAX_TOKENS = 123000;

// ToolTip content in SettingsList component
export const TEMPERATURE_TOOLTIP = "What sampling temperature to use, between 0 and 2. Default value is 1. Higher values means the model will take more risks. Try 0.9 for more creative applications, and 0 (argmax sampling) for ones with a well-defined answer. We generally recommend altering this or top_p but not both.";
export const TOPP_TOOLTIP = "An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered. We generally recommend altering this or temperature but not both. Default value is 1. ";
export const MAX_TOKENS_TOOLTIP = "The maximum number of tokens to generate in the completion.";
export const FREQUENCY_PENALTY_TOOLTIP = "Number between -2.0 and 2.0. Default value is 0. Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim.";
export const PRESENCE_PENALTY_TOOLTIP = "Number between -2.0 and 2.0. Default value is 0. Positive values penalize new tokens based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics.";
export const STOP_TOOLTIP = "Up to four sequences where the API will stop generating further tokens. The returned text won't contain the stop sequence.";
export const MODEL_PERSONA_TOOLTIP = "Helps you customize the model's personality. Use this to get responses in a style specific to your needs. The model will use this for every conversation. Setting this to an empty string will result in default model responses.";
export const USER_BACKGROUND_TOOLTIP = "Helps you provide context to the model. The model will use this for every conversation. Setting this to an empty string will result in default model responses.";
export const CHUNKER_TYPE_TOOLTIP = [
  "Code chunker - Optimized of chunking code by splitting based on language-specific syntax for 'cpp', 'go', 'java’, ’js', 'php', 'proto', 'python’, 'rst', 'ruby', 'rust', 'scala', 'swift', 'markdown', 'latex', 'html'",
  "JSON chunker - Optimized for JSON files",
  "Semantic chunker - Segments text into semantically meaningful chunks based on their underlying meaning. Can be used for any type of file format",
  "Text chunker - Splits text into smaller pieces, based on characters like new line, new paragraph. Does not take into account semantics."
];export const PREFERENCE_CONTROL_TOOLTIP = "This setting allows you to control the model's preference for the currently opened thread. If this option is enabled, the model will try to continue the conversation in the same direction as the current thread. Otherwise, the model will not consider the current thread's context and will generate a response based on the user's preferences.";

export const USER_BACKGROUND_PLACEHOLDER = "Example: I’m a software developer proficient in Python. I'm working on a project that requires me to use the Flask framework.";
export const MODEL_PERSONA_PLACEHOLDER = "Example: When I ask you for code, please just give me the code without any explanation on how it works. Bias towards the most efficient solution."

//Constants for Document Table
export const DOCUMENT_TABLE_TOOLTIP = "Double-click on the Tags field to edit";


// Default settings used in SettingsList component
export const SETTINGSLIST_DEFAULTS = {
  'themeValue': 2,
  'modelNameValue': 1,
  'maxTokensValue': 4096,
  'temperature': 1,
  'topP': 1,
  'frequencyPenalty': 0,
  'presencePenalty': 0,
  'embeddingModel': 1,
  'chunkerType': 4,
  "threadPreferenceFlag": 0,
  "documentMode": "General"
};

// Theme mapping from numeric to actual words. Used in SettingsList component
export const THEME_NUMERIC_TO_WORDS = {
  1: "lightTheme",
  2: "darkTheme",
  3: "highContrastTheme"
}

export const THEME_WORDS_TO_NUMERIC = {
  "lightTheme": 1,
  "darkTheme": 2,
  "highContrastTheme": 3
}

// Model mapping from numeric to actual words. Used in SettingsList component
export const MODEL_NUMERIC_TO_WORDS = {
  1: "gpt-35-turbo",
  2: "gpt-35-turbo-16k",
  3: "gpt-4",
  4: "gpt-4o"
}

export const MODEL_WORDS_TO_NUMERIC = {
  "gpt-35-turbo": 1,
  "gpt-35-turbo-16k": 2,
  "gpt-4": 3,
  "gpt-4o": 4
}

export const CHAT_MODELS = {
  "gpt-35-turbo": {
    "maxTokens": 4096
  },
  "gpt-35-turbo-16k": {
    "maxTokens": 16384
  },
  "gpt-4": {
    "maxTokens": 8192
  },
  "gpt-4o": {
    "maxTokens": 128000
  }
}

export const EMBEDDING_MODEL_NUMERIC_TO_WORDS = {
  1: "text-embedding-ada-002",
  2: "text-embedding-3-small",
  3: "text-embedding-3-medium",
  4: "text-embedding-3-large",
}

export const EMBEDDING_MODEL_WORDS_TO_NUMERIC = {
  "text-embedding-ada-002": 1,
  "text-embedding-3-small": 2,
  "text-embedding-3-medium": 3,
  "text-embedding-3-large": 4,
}

export const EMBEDDING_MODELS_NUMERIC_TO_READABLE_WORDS = {
  1: "Text Embedding ADA v2",
  2: "Text Embedding v3 Small",
  3: "Text Embedding v3 Medium",
  4: "Text Embedding v3 Large",
}

export const CHUNKER_TYPE_NUMERIC_TO_WORDS = {
  1: "codeChunker",
  2: "jsonChunker",
  3: "semanticChunker",
  4: "textChunker"
}

export const CHUNKER_TYPE_WORDS_TO_NUMERIC = {
  "codeChunker": 1,
  "jsonChunker": 2,
  "semanticChunker": 3,
  "textChunker": 4
}

export const CHUNKER_TYPE_NUMERIC_TO_READABLE_WORDS = {
  1: "Code Chunker",
  2: "JSON Chunker",
  3: "Semantic Chunker",
  4: "Text Chunker"
}

export const DEFAULT_SETTINGS = {
  theme: SETTINGSLIST_DEFAULTS.themeValue,
  model: SETTINGSLIST_DEFAULTS.modelNameValue,
  maxTokens: SETTINGSLIST_DEFAULTS.maxTokensValue,
  temperature: SETTINGSLIST_DEFAULTS.temperature,
  topP: SETTINGSLIST_DEFAULTS.topP,
  frequencyPenalty: SETTINGSLIST_DEFAULTS.frequencyPenalty,
  presencePenalty: SETTINGSLIST_DEFAULTS.presencePenalty,
  stop: "",
  modelPersona: "",
  userBackground: "",
  threadPreferenceFlag: SETTINGSLIST_DEFAULTS.threadPreferenceFlag,
  selectedDocuments: "",
  documentMode: SETTINGSLIST_DEFAULTS.documentMode,
  embeddingModel: SETTINGSLIST_DEFAULTS.embeddingModel,
  chunkerType: SETTINGSLIST_DEFAULTS.chunkerType,
}

// Search options used in Search Bar component
export const SEARCH_OPTIONS = {
  0: "current_thread",
  1: "all_threads"
};

// all tour steps used in the TourComponent
export const TOUR_STEPS = [
  {
    target: '.button-selector',
    content: 'This is a button',
    placement: 'right',
  },
  {
    target: ".newChatButton",
    content: "Embark on a conversation adventure with an AI by clicking the new chat button",
    placement: "right",
  },
  {
    target: ".searchBar",
    content: "The search bar is your gateway to finding precisely what you're looking for in your treasure trove of past conversations.",
    placement: "bottom",
  },
  {
    target: ".searchToggleButtons",
    content: "Whether you're on a mission to find something specific within a single thread (the CURRENT thread) or explore across all threads (ALL), our search feature has got you covered.",
    placement: "bottom",
  },
  {
    target: ".settingsButton",
    content: "Take control of your experience by accessing the settings and effortlessly customize them to your liking.",
    placement: "bottom",
  },
  {
    target: ".infoListButton",
    content: "Unleash your inner wizardry with our documentation: your guidebook to unlocking the full power and possibilities of our application.",
    placement: "bottom",
  },
  {
    target: ".logoutButton",
    content: "Bid farewell to your digital realm with the mighty log out button, until your next adventure begins.",
    placement: "bottom",
  },
  {
    target: ".documentationQAPButton",
    content: "Unleash your inner wizardry with our documentation: your guidebook to unlocking the full power and possibilities of our application.",
    placement: "right",
  },
  {
    target: ".tourQAPButton",
    content: "Embark on a guided tour with a single click of the tour button, revealing the wonders of our application in a delightful adventure.",
    placement: "bottom",
  },
  {
    target: ".settingsQAPButton",
    content: "Take control of your experience by accessing the settings and effortlessly customize them to your liking.",
    placement: "bottom",
  },
  {
    target: ".contactUsQAPButton",
    content: "Connect with us in a breeze by tapping the Contact Us button, where our team eagerly awaits to assist and engage with you.",
    placement: "left",
  },
  {
    target: ".disclaimerQAPButton",
    content: "Pssst! Before we dive into the excitement, take a moment to read the disclaimer - the secret scroll that unravels important information to our adventure!",
    placement: "bottom",
  },
  {
    target: ".messagePrompt",
    content: "Ignite a conversation by simply typing your message into the prompt, setting the stage for an engaging and interactive exchange of ideas and thoughts.",
    placement: "bottom",
  },
  {
    target: ".threadList",
    content: "Whisk your way into one of your exciting conversations with a simple selection listed on the sidebar",
    placement: "right",
  },
  // {
  //   target: ".chatDownloadButton",
  //   content: "Unlock the time capsule of memories by downloading your chat history in three captivating formats",
  //   placement: "top",
  // },
];

