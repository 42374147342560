import React, { useState } from "react";
// Base Components
import ButtonIcon from "../../base_components/ButtonIcon";
import BaseButton from "../../base_components/BaseButton";

// Mui Icons
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import TextsmsIcon from '@mui/icons-material/Textsms';
import DeleteIcon from '@mui/icons-material/Delete';

//MUI components
import ButtonGroup from '@mui/material/ButtonGroup';
import { useTheme } from "@emotion/react";

const ButtonIconStyle = {
  color: 'white',
  outline: 'none',
}

const DeleteButtonStyle = {
  color: 'white',
  marginTop: '25%'
}

const ThreadItemTextStyle = {
  width: '150px',
  color: '#E5E5E5',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  textTransform: 'capitalize',
  outline: 'none',
  border: 'none',
};

const ThreadItem = ({ 
  handleDeleteThread, 
  handleMessageThread, 
  text, 
  index, 
  thread_id,
  selected = false
}) => {

  const [deleteSelected, setDeleteSelected] = useState([]);

  const theme = useTheme();

  const handleButtonSwap = (valueToSwap) =>
    setDeleteSelected(deleteSelected => [...deleteSelected, valueToSwap])

  const resetButtonSwap = (valueToReset) =>
    setDeleteSelected(deleteSelected => {
      return deleteSelected.filter((value) => value !== valueToReset)
    });

  return (
    <ButtonGroup 
      variant="text" 
      fullWidth={true}
      aria-label="outlined button group">
      <BaseButton
        id={'thread-item-header-id'}
        key={index}
        variant="outlined"
        startIcon={
          <TextsmsIcon
            fontSize='small'
            sx={ButtonIconStyle} />
        }
        handleOnClick={() => handleMessageThread(thread_id)}
        customStyles={{
          marginTop: '7px',
          border: selected ? '1px solid #09af00' : 'none',
          outline: 'none',
          backgroundColor: selected ? theme.palette.background.tabPaper : "linear-gradient(175deg, #1F2027 0%, #1B1C22 100%)",
          '&:hover': {
            backgroundColor: 'rgba(42, 47, 56, 1)', // Change the color on hover
          },
        }}>
        <span style={ThreadItemTextStyle}>
          {text}
        </span>
      </BaseButton>
      {deleteSelected.indexOf(text) > -1 ?
        <>
          <ButtonIcon handleSubmit={() => handleDeleteThread(thread_id)}>
            <CheckIcon fontSize='small' sx={ButtonIconStyle} />
          </ButtonIcon>
          <ButtonIcon handleSubmit={() => resetButtonSwap(text)}>
            <CloseIcon fontSize='small' sx={ButtonIconStyle} />
          </ButtonIcon>
        </>
        :
        <div>
          <ButtonIcon handleSubmit={() => handleButtonSwap(text)}>
            <DeleteIcon fontSize='small' sx={DeleteButtonStyle} />
          </ButtonIcon>
        </div>}
    </ButtonGroup>

  )
}

export default ThreadItem;