import React,{ createContext, useState, useEffect } from "react";
import { MODEL_WORDS_TO_NUMERIC, SETTINGSLIST_DEFAULTS, THEME_WORDS_TO_NUMERIC, THEME_NUMERIC_TO_WORDS, MODEL_NUMERIC_TO_WORDS, EMBEDDING_MODEL_NUMERIC_TO_WORDS, CHUNKER_TYPE_NUMERIC_TO_WORDS} from "../config/constants";
import postSettings from "../api/postSettings";


const PreferenceContext = createContext({
    preferenceList: {},
    updatePreferences: (preferences) => { },
    savePreferences: (newPreferenceList, token) => { }
})
export const PreferenceContextProvider = (props) => {
    const sessionTheme = Number(sessionStorage.getItem("theme"));
    const initialPreferences = {
        theme: !!sessionTheme ? sessionTheme : 1,
        model: 1,
        stop: "",
        modelPersona: "",
        userBackground: "",
        topP: SETTINGSLIST_DEFAULTS.topP,
        temperature: SETTINGSLIST_DEFAULTS.temperature,
        frequencyPenalty: SETTINGSLIST_DEFAULTS.frequencyPenalty,
        presencePenalty: SETTINGSLIST_DEFAULTS.presencePenalty,
        maxTokens: SETTINGSLIST_DEFAULTS.maxTokensValue,
        embeddingModel: SETTINGSLIST_DEFAULTS.embeddingModel,
        chunkerType: SETTINGSLIST_DEFAULTS.chunkerType,
        threadPreferenceFlag: SETTINGSLIST_DEFAULTS.threadPreferenceFlag,
        selectedDocuments: ""
    }

    const [preferenceList, setPreferenceList] = useState(initialPreferences);

    const updatePreferences = (fetchedPreferences) => {
        Object.keys(fetchedPreferences).forEach((key) => {
            if (fetchedPreferences[key] === NaN || fetchedPreferences[key] === undefined) {
                fetchedPreferences[key] = initialPreferences[key]
            }
        })
        const updatedPreferences = { ...preferenceList, ...fetchedPreferences }
        setPreferenceList((prev) =>({...prev, ...updatedPreferences}));
        if (!!fetchedPreferences["theme"]) {
            sessionStorage.setItem("theme", fetchedPreferences["theme"])
        } else {
            sessionStorage.setItem("theme", SETTINGSLIST_DEFAULTS.themeValue);
        }

        return updatedPreferences
    }

    const savePreferences = async (newPreferenceList, token) => {
        try {
            const preferencePayload = {...newPreferenceList};
            preferencePayload["theme"] = typeof preferencePayload["theme"] == "number" ? THEME_NUMERIC_TO_WORDS[preferencePayload["theme"]] : preferencePayload["theme"];
            preferencePayload["model"] = typeof preferencePayload["model"] == "number" ? MODEL_NUMERIC_TO_WORDS[preferencePayload["model"]] : preferencePayload["model"];
            preferencePayload["embeddingModel"] = typeof preferencePayload["embeddingModel"] == "number" ? EMBEDDING_MODEL_NUMERIC_TO_WORDS[preferencePayload["embeddingModel"]] : preferencePayload["embeddingModel"];
            preferencePayload["chunkerType"] = typeof preferencePayload["chunkerType"] == "number" ? CHUNKER_TYPE_NUMERIC_TO_WORDS[preferencePayload["chunkerType"]] : preferencePayload["chunkerType"];
            const response = await postSettings(preferencePayload, token);
            if (response.status === 200) {
                return true;
            }
            if (!!newPreferenceList["theme"]) {
                sessionStorage.setItem("theme", newPreferenceList["theme"])
            }
        } catch (error) {
            console.log(error);
            console.error("Error saving preferences to db")
        }
        return false;
    }

    const contextValue = {
        preferenceList,
        updatePreferences,
        savePreferences
    }

    return <PreferenceContext.Provider value={contextValue}>{props.children}</PreferenceContext.Provider>
}

export default PreferenceContext;