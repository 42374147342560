import React, { useEffect, useState, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Typography,
  IconButton,
  Box,
  Link,
  Divider,
  Dialog,
  DialogContent,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from "@mui/lab";
import { useTheme } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import InfoIcon from "@mui/icons-material/Info";
import BookIcon from "@mui/icons-material/Book";
import PeopleIcon from "@mui/icons-material/People";
import GitHubIcon from '@mui/icons-material/GitHub';
// import { releases } from "../../config/releases";
import getReleases from "../../api/getReleases";
import TokenContext from "../../context/TokenContext";
import PreferenceContext from "../../context/PreferenceContext";

//themes
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import highContrastTheme from "../../themes/highContrastTheme";


const ReleaseTimeline = ({ open, handleOpen, handleClose }) => {
  const [releases, setReleases] = useState([]);

  const tokenCxt = useContext(TokenContext);
  const preferenceCxt = useContext(PreferenceContext);
  const preferredTheme = preferenceCxt.preferenceList.theme;

  useEffect(() => {
    const fetchReleases = async () => {
      try {
        let response = await getReleases(tokenCxt.token);
        if (response && response.status === 200) {
          setReleases([...releases, ...response.data]);
        } else {
          console.error("Error while retrieving releases from backend.");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchReleases();
  }, []);

  const theme = useTheme();

  const StyledBox = styled(Box)({
    '& .custom-bullet-list': {
      padding: '8px 0',
      listStyleType: 'disc',
      marginLeft: '24px',

    }
  });

  const styledDialogColor = {
    1: lightTheme.palette.text.primary,
    2: darkTheme.palette.text.primary,
    3: highContrastTheme.palette.text.primary,
  }

  const styledDialogBackground = {
    1: lightTheme.palette.background.default,
    2: darkTheme.palette.background.default,
    3: highContrastTheme.palette.background.default,
  }

  const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
      //background: "linear-gradient(120deg, #f6f8fa, #eaf3ff)",
      color: styledDialogColor[preferredTheme],
      background: styledDialogBackground[preferredTheme]
    }
  });

  // Utility function to convert URLs in text to clickable links
  const createLinkElements = (text) => {
    const urlRegex = /(\bhttps?:\/\/[^\s]+)(\s|$)/gi;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return <a href={part} key={index} target="_blank" rel="noopener noreferrer">here</a>;
      }
      return part;
    });
  };

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <Box
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right -200px top -100px",
          p: 4,
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Typography variant="h4" fontWeight="bold" >
          Release Timeline
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Timeline align="alternate" sx={{ ml: -50, mr: 25 }}>
          {releases.map((release, idx) => (
            <TimelineItem key={release.release_id}>
              <TimelineOppositeContent>
                <Typography>
                  {release.release_date}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                {idx !== releases.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Accordion elevation={2}
                  expanded={true}
                  sx={{

                    '&:hover': {
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'
                    },
                    transition: 'box-shadow 0.3s'
                  }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                    id="panel-header"
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="subtitle" component="div">
                        {release.app_version}
                      </Typography>
                      <Tooltip title="Click to see more details">
                        <InfoIcon
                          fontSize="small"
                          style={{ marginLeft: "8px" }}
                        />
                      </Tooltip>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ flexDirection: 'column', borderRadius: '0 0 4px 4px', }}>
                    {JSON.parse(release.details).map((detail) => (
                      <StyledBox key={detail.Title} mb={2}>
                        <Typography gutterBottom variant="subtitle2">
                          <BookIcon
                            fontSize="small"
                            style={{
                              verticalAlign: "middle",
                              marginRight: "4px",
                            }}
                          />
                          {detail.Title}
                        </Typography>
                        <ul className="custom-bullet-list">
                          {detail.Content.split(/(?<=[.!?])\s+/).map(
                            (sentence, index) => (
                              <li key={index}>
                                <Typography variant="body2">
                                  {createLinkElements(sentence.trim())}
                                </Typography>
                              </li>
                            )
                          )}
                        </ul>
                      </StyledBox>
                    ))}

                    <Divider light sx={{ my: 2 }} />
                    {release.pr &&
                      JSON.parse(release.pr).map((pr, release_index) => (
                        <Box display="flex" alignItems="center" my={1} key={release_index}>
                          <GitHubIcon fontSize="small" sx={{ mr: 1 }} />
                          <Typography
                            variant="body2"
                            noWrap
                            component={Link}
                            to={pr}
                          >
                            <Link href={pr} underline="none">
                              {pr}
                            </Link>
                          </Typography>
                        </Box>
                      ))}

                    {release.release_type && (
                      <Typography gutterBottom variant="body2">
                        Release Type: {release.release_type}
                      </Typography>
                    )}
                    {JSON.parse(release.deprecated_features) &&
                      JSON.parse(release.deprecated_features).length > 0 && (
                        <Typography gutterBottom variant="body2">
                          Deprecated Features:{" "}
                          {JSON.parse(release.deprecated_features).join(", ")}
                        </Typography>
                      )}
                    {JSON.parse(release.contributors) &&
                      JSON.parse(release.contributors).length > 0 && (
                        <Box display="flex" alignItems="center" my={1}>
                          <PeopleIcon fontSize="small" sx={{ mr: 1 }} />
                          <Typography variant="body2">
                            Contributors: {JSON.parse(release.contributors).join(", ")}
                          </Typography>
                        </Box>
                      )}
                  </AccordionDetails>
                </Accordion>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </DialogContent>
    </StyledDialog>
  );
};

export default ReleaseTimeline;