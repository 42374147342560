export const msalConfig = {
  auth: {
    clientId: "700c77ee-dda2-4482-9022-a87273fc37c1",
    authority:
      "https://login.microsoftonline.com/9274ee3f-9425-4109-a27f-9fb15c10675d",
    // redirectUri: "http://localhost:3000/",
    // redirectUri: 'https://ln-chatgpt.azurewebsites.net/'
    // redirectUri: "https://ln-chatgpt-dev.azurewebsites.net/",
    redirectUri: process.env.REACT_APP_REDIRECT_URI,

  },
  system: {
    concurrentPopups: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};
