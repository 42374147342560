import React, { useState, createContext, useContext, useEffect } from "react";
import PreferenceContext from "./PreferenceContext";
import TokenContext from "./TokenContext";
import ThreadContext from "./ThreadContext";
import putThreadPreferences from "../api/putThreadPreferences";
import { THEME_NUMERIC_TO_WORDS, MODEL_NUMERIC_TO_WORDS, THEME_WORDS_TO_NUMERIC, MODEL_WORDS_TO_NUMERIC, EMBEDDING_MODEL_NUMERIC_TO_WORDS, CHUNKER_TYPE_NUMERIC_TO_WORDS, EMBEDDING_MODEL_WORDS_TO_NUMERIC, CHUNKER_TYPE_WORDS_TO_NUMERIC } from "../config/constants";
import ThreadPreferenceContext from "./ThreadPreferenceContext";

const OperationalModeContext = createContext({
  mode : '',
  details: '',
  changeMode : (newMode, newDetails) => {},
  selectedUserDocuments: [],
  addSelectedUserDocument: (docs) => {},
  removeSelectedUserDocument: (doc) => {},
  clearSelectedUserDocuments: () => {},
  selectedInternalDocumentSources: [],
  addSelectedInternalDocumentSource: (source) => {},
  removeSelectedInternalDocumentSource: (source) => {},
  clearSelectedInternalDocumentSources: () => {},
  saveDocuments: () => {},
  fetchSelectedDocuments: ({ documentMode, selectedDocuments}) => {}
});

const OperationalModeContextProvider = ({ children }) => {
  const MODES = ["General", "Jarvis", "User Uploaded Doc Q&A"];
  const [mode, setMode] = useState(MODES[0]);
  const [details, setDetails] = useState("Hidden"); // This is used only for the User Uploaded Doc Q&A mode
  const [selectedUserDocuments, setSelectedUserDocuments] = useState([]); // User uploaded documents
  const [selectedInternalDocumentSources, setSelectedInternalDocumentSources] =
    useState([]); // Jarvis
  
  const preferenceCxt = useContext(PreferenceContext);
  const threadPreferenceCxt = useContext(ThreadPreferenceContext);
  const tokenCxt = useContext(TokenContext);
  const threadCxt  = useContext(ThreadContext);

  const changeMode = (newMode, newDetails) => {
    if (newMode === MODES[1]) {
      clearSelectedUserDocuments();
    }else if (newMode === MODES[2]) {
      clearSelectedInternalDocumentSources();
    }else if (newMode === MODES[0]) {
      clearSelectedUserDocuments();
      clearSelectedInternalDocumentSources();
    }
    setMode(newMode);

    // If the mode is General, automatically set details to Hidden
    if (newMode === MODES[0]) {
      setDetails("Hidden");
    } else {
      setDetails(newDetails);
    }
  };

  const saveDocuments = async (mode, sources) =>{
    if (!sources || sources.length === 0) {
      mode = "General";
      sources = [];
      changeMode(mode);
    } else if (mode === "Jarvis") {
      changeMode("Jarvis");
      setSelectedInternalDocumentSources(selectedInternalDocumentSources => ([...sources]));
    } else if (mode === "User Uploaded Doc Q&A") {
      changeMode("User Uploaded Doc Q&A");
      setSelectedUserDocuments(selectedUserDocuments=>([...sources.map((doc_id) => ({ id: doc_id }))]));
    }

    try{
      const response = await handleSaveSelectedDocuments(mode, sources.join("##"));

      return response;
    }
    catch(error){
      console.log("Error saving documents", error);
      return false;
    }
  }

  const  fetchSelectedDocuments = ({ documentMode, selectedDocuments }) => {
    if (documentMode == "User Uploaded Doc Q&A") {
      changeMode("User Uploaded Doc Q&A");
      !!selectedDocuments && setSelectedUserDocuments(selectedUserDocuments=>([...selectedDocuments.split("##").map((doc_id) => ({ id: doc_id }))]));
    } else if (documentMode == "Jarvis") {
      changeMode("Jarvis");
      !!selectedDocuments && setSelectedInternalDocumentSources(selectedInternalDocumentSources=>([...selectedDocuments.split("##")]));
    } else {
      changeMode("General");
    }
  }

  const handleSaveSelectedDocuments = async (mode, selectedDocuments) => {
    const preferences = {...preferenceCxt.preferenceList};
    const preferencePayload = {...preferences, selectedDocuments: selectedDocuments, documentMode: mode};
    preferencePayload["theme"] = typeof preferencePayload["theme"] == "number" ? THEME_NUMERIC_TO_WORDS[preferencePayload["theme"]] : preferencePayload["theme"];
    preferencePayload["model"] = typeof preferencePayload["model"] == "number" ? MODEL_NUMERIC_TO_WORDS[preferencePayload["model"]] : preferencePayload["model"];
    preferencePayload["embeddingModel"] = typeof preferencePayload["embeddingModel"] == "number" ? EMBEDDING_MODEL_NUMERIC_TO_WORDS[preferencePayload["embeddingModel"]] : preferencePayload["embeddingModel"];
    preferencePayload["chunkerType"] = typeof preferencePayload["chunkerType"] == "number" ? CHUNKER_TYPE_NUMERIC_TO_WORDS[preferencePayload["chunkerType"]] : preferencePayload["chunkerType"];
    
    
    if (!!threadCxt.threadID){
      const response = await putThreadPreferences(tokenCxt.token, threadCxt.threadID, preferencePayload);

      if (response.status !== 200) {
          return false;
      }

      preferencePayload["theme"] = THEME_WORDS_TO_NUMERIC[preferencePayload["theme"]];
      preferencePayload["model"] = MODEL_WORDS_TO_NUMERIC[preferencePayload["model"]];
      preferencePayload["embeddingModel"] = EMBEDDING_MODEL_WORDS_TO_NUMERIC[preferencePayload["embeddingModel"]];
      preferencePayload["chunkerType"] = CHUNKER_TYPE_WORDS_TO_NUMERIC[preferencePayload["chunkerType"]];
          
      threadPreferenceCxt.updatePreferences({...preferences, ...preferencePayload})

      return true;
    }else{
      const newPreferenceList = {...preferenceCxt.preferenceList, selectedDocuments: selectedDocuments, documentMode: mode}

      const response = await preferenceCxt.savePreferences(newPreferenceList, tokenCxt.token);
      
      if (!!response){
        const updatedPayload = {...newPreferenceList};

        preferenceCxt.updatePreferences(updatedPayload);

        return true;
      }
    }
    
  }

  const addSelectedUserDocument = (docs) => {
    changeMode("User Uploaded Doc Q&A");
    if (Array.isArray(docs)) {
        setSelectedUserDocuments(prev => [...prev, ...docs]);
    } else {
        setSelectedUserDocuments(prev => [...prev, docs]);
    }
};

  const removeSelectedUserDocument = (doc) => {
    setSelectedUserDocuments(
      selectedUserDocuments.filter((d) => d.id !== doc.id)
    );
  };

  const clearSelectedUserDocuments = () => {
    setSelectedUserDocuments(selectedUserDocuments=>[]);
  };

  const addSelectedInternalDocumentSource = (source) => {
    if (Array.isArray(source)){
      setSelectedInternalDocumentSources(prev => [...prev, ...source]);
    }else{
      setSelectedInternalDocumentSources(prev => [
        ...prev,
        source,
      ]);
    }
  };

  const removeSelectedInternalDocumentSource = (source) => {
    setSelectedInternalDocumentSources(
      selectedInternalDocumentSources.filter((s) => s !== source)
    );
  };
  

  const clearSelectedInternalDocumentSources = () => {
    setSelectedInternalDocumentSources(selectedInternalDocumentSources=>[]);
  };

  return (
    <OperationalModeContext.Provider
      value={{
        mode,
        details,
        changeMode,
        selectedUserDocuments,
        addSelectedUserDocument,
        removeSelectedUserDocument,
        clearSelectedUserDocuments,
        selectedInternalDocumentSources,
        addSelectedInternalDocumentSource,
        removeSelectedInternalDocumentSource,
        clearSelectedInternalDocumentSources,
        saveDocuments,
        fetchSelectedDocuments
      }}
    >
      {children}
    </OperationalModeContext.Provider>
  );
};

export { OperationalModeContext, OperationalModeContextProvider };
