import React from "react";
import SettingSlider from "../SettingSlider/SettingSlider";
import SettingTextBox from "../SettingTextBox/SettingTextBox";
import SettingModelDropdown from "../SettingModelDropdown/SettingModelDropdown";
import { Typography, Divider, Box, MenuItem } from "@mui/material";
import SettingEmbeddingModelDropdown from "../SettingEmbeddingModelDropdown/SettingEmbeddingModelDropdown";
import SettingChunkerTypeDropdown from "../SettingChunkerTypeDropdown/SettingChunkerTypeDropdown";
import BaseSelect from "../../base_components/BaseSelect";


import {
    TEMPERATURE_TOOLTIP, TOPP_TOOLTIP, PRESENCE_PENALTY_TOOLTIP, MAX_TOKENS_TOOLTIP, STOP_TOOLTIP,
    MODEL_PERSONA_TOOLTIP, USER_BACKGROUND_TOOLTIP, FREQUENCY_PENALTY_TOOLTIP,
    USER_BACKGROUND_PLACEHOLDER, MODEL_PERSONA_PLACEHOLDER, CHUNKER_TYPE_TOOLTIP
} from "../../config/constants";

const PreferenceDialogFormContent = ({ formInputValues, handleInputChange, disableTopPSlider, disableTemperatureSlider, upperTokenLimit, disabled = false }) => {

    const customDividerSyle = {
        width: "100%",
        marginTop: 3,
        marginBottom: 3,
        height: 1,
        background: "rgba(255, 255, 255, 0.4)"
    }

    return <>
        <Divider sx={customDividerSyle} />
        <Typography variant="h6">Model Parameters</Typography>
        <SettingModelDropdown
            name="model"
            value={formInputValues.model}
            onChange={handleInputChange}
        />
        <SettingSlider
            value={formInputValues.temperature}
            onChange={handleInputChange}
            title="Temperature"
            name="temperature"
            isDisabled={disableTemperatureSlider}
            tooltipContent={TEMPERATURE_TOOLTIP}
        />
        <SettingSlider
            value={formInputValues.topP}
            onChange={handleInputChange}
            title="Top p"
            name="topP"
            isDisabled={disableTopPSlider}
            tooltipContent={TOPP_TOOLTIP}
        />
        <SettingSlider
            value={formInputValues.maxTokens}
            onChange={handleInputChange}
            title="Max Tokens"
            name="maxTokens"
            min={100}
            max={upperTokenLimit}
            step={1}
            tooltipContent={MAX_TOKENS_TOOLTIP}
        />
        <SettingSlider
            value={formInputValues.frequencyPenalty}
            onChange={handleInputChange}
            title="Frequency Penalty"
            name="frequencyPenalty"
            min={-2}
            max={2}
            step={0.1}
            tooltipContent={FREQUENCY_PENALTY_TOOLTIP}
        />
        <SettingSlider
            value={formInputValues.presencePenalty}
            onChange={handleInputChange}
            title="Presence Penalty"
            name="presencePenalty"
            min={-2}
            max={2}
            step={0.1}
            tooltipContent={PRESENCE_PENALTY_TOOLTIP}
        />
        <SettingTextBox
            value={formInputValues.stop}
            onChange={handleInputChange}
            title="Stop"
            name="stop"
            tooltipContent={STOP_TOOLTIP}
            minRows={1}
            placeholder="Example: ['</code>', 'test']"
        />
        {/* <Divider sx={customDividerSyle} />
        <Typography variant="h6">RAG Preferences</Typography>
        <SettingEmbeddingModelDropdown
            name="embeddingModel"
            value={formInputValues.embeddingModel}
            onChange={handleInputChange}
        />
        <SettingChunkerTypeDropdown
            name="chunkerType"
            value={formInputValues.chunkerType}
            onChange={handleInputChange}
            tooltipContent={CHUNKER_TYPE_TOOLTIP}
        /> */}
        <Divider sx={customDividerSyle} />
        <Typography variant="h6" sx={{ marginBottom: 2 }}>Custom Instructions</Typography>
        <SettingTextBox
            value={formInputValues.userBackground}
            onChange={handleInputChange}
            title="User Background"
            name="userBackground"
            tooltipContent={USER_BACKGROUND_TOOLTIP}
            minRows={4}
            placeholder="Example: I’m a software developer proficient in Python. I'm working on a project that requires me to use the Flask framework."
        />
        <SettingTextBox
            value={formInputValues.modelPersona}
            onChange={handleInputChange}
            title="Model Persona"
            name="modelPersona"
            tooltipContent={MODEL_PERSONA_TOOLTIP}
            minRows={4}
            placeholder="Example: When I ask you for code, please just give me the code without any explanation on how it works. Bias towards the most efficient solution."
        />
    </>
}

export default PreferenceDialogFormContent;