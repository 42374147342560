import flask from "./flask";
import { parseToCamelCase } from "./utils";

// Get user's saved preferences
const getSettings = async (token) => {
  try {
    const response = await flask.get('preferences',
      {
        headers: { 'Authorization': `Bearer ${token}` }
      }
    );
    return {
      status: response.status,
      data: parseToCamelCase(response.data)
    };
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default getSettings;