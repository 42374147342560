import flask from "./flask";
import { parseMessages } from "./utils";

// Get authenticated with provided risk id as username and password
const getMessages = async (threadID, token) => {
  try {
    const response = await flask.get(`message/thread/${threadID}`, {
      "headers": {
        "Authorization": `Bearer ${token}`
      }
    });
    
    const messages = parseMessages(response.data); 
    
    
    return {"status": response.status, "data": messages};
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default getMessages;