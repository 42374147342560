import React, { useContext, useEffect, useState } from "react";
// Base Components
import BaseAppBar from "../../base_components/BaseAppBar";
import SettingsList from "../SettingsList/SettingsList";
import BaseTooltip from "../../base_components/BaseTooltip";
import ButtonIcon from "../../base_components/ButtonIcon";
import ReorderIcon from '@mui/icons-material/Reorder';
//Custom components
import SearchBar from "../SearchBar/SearchBar";
import SearchResultsList from "../SearchResultsList/SearchResultsList";
import DocumentList from "../DocumentList/DocumentList";
import ReleaseTimeline from "../ReleaseTimeline/ReleaseTimeline";
import AnalyticsDashboard from "../AnalyticsDashboard/AnalyticsDashboard";

// Mui Components
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from '@mui/material/Tooltip';
import { ListItemText, ListSubheader, ListItemIcon } from "@mui/material";

// Mui Icons
import SettingsIcon from "@mui/icons-material/Settings";
import DescriptionIcon from "@mui/icons-material/Description";
import TravelExploreRoundedIcon from "@mui/icons-material/TravelExploreRounded";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import FaceIcon from "@mui/icons-material/Face";

import TimelineIcon from "@mui/icons-material/Timeline";

import AnalyticsIcon from "@mui/icons-material/Assessment";

//Context
import TokenContext from "../../context/TokenContext";
import SearchResultContext from "../../context/SearchResultContext";

import { DOCUMENTATION_URL, TEAM_EMAIL } from "../../config/constants";
import SidebarContext from "../../context/SidebarContext";
import darkTheme from "../../themes/darkTheme";

const MenuItemStyle = { width: "200px" };
const DropdownIconStlye = { marginLeft: "10px" };

const NavBar = ({
  beginTour,
  onClickTour,
  handleBeginTourClose,
  renderDocumentList,
  setRenderDocumentList
}) => {
  const searchResultsCxt = useContext(SearchResultContext);
  const tokenCxt = useContext(TokenContext);
  const sidebarCxt = useContext(SidebarContext);
  const [renderSettingsList, setSettingsList] = useState(false);
  const [renderSearchResults, setRenderSearchResults] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchResultsList, setSearchResultsList] = useState([]);
  const open = Boolean(anchorEl);
  const profileImage = sessionStorage.getItem("profileImage");
  const [renderReleaseTimeline, setRenderReleaseTimeline] = useState(false);
  const [sideBarCollapse, setSideBarCollapse] = useState(false);
  const [isAnalyticsDialogOpen, setAnalyticsDialogOpen] = useState(false);
  const AppBarStyle = {
    outlineWidth: "0px",
    maxWidth: `calc(100% - ${sidebarCxt.sidebarWidth}px)`,
    width: '100%',
    // width: { sm: `calc(100% - ${240}px)` },
    // ml: { sm: `${240}px` },
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.2) !important",
    //background: "#141414",
    background: 'linear-gradient( #0E1117 0%, #171C26 100%)',
    transition: "all 0.3s ease",
  };

  useEffect(() => {
    if (!!searchResultsCxt.mostRecentSearchResults) {
      setSearchResultsList(searchResultsCxt.mostRecentSearchResults);
    }
  }, [searchResultsCxt.mostRecentSearchResults]);

  const handleContextSwitch = () => {
    sidebarCxt.setCollapsable(true)
  };

  // Profile Menu Event Handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Setting Event Handlers
  const handleOpenSettings = () => {
    setSettingsList(true);
  };

  const handleCloseSettings = () => {
    setSettingsList(false);
  };

  const onClickSettings = () => {
    handleOpenSettings();
  };


  // Document Event Handlers
  const handleOpenDocumentList = () => {
    setRenderDocumentList(true);
  };

  const handleCloseDocumentList = () => {
    setRenderDocumentList(false);
  };

  const onClickMyDocuments = () => {
    handleOpenDocumentList();
  };

  // Release Timeline Event Handlers
  const handleOpenReleaseTimeline = () => {
    setRenderReleaseTimeline(true);
  };

  const handleCloseReleaseTimeline = () => {
    setRenderReleaseTimeline(false);
  };

  const onClickReleaseTimeline = () => {
    handleOpenReleaseTimeline();
  };

  const onClickDocumentation = () => {
    try {
      window.open(DOCUMENTATION_URL, "_blank");
    } catch (error) {
      console.error("Error opening link. The link maybe incorrect or broken");
    }
  };

  const onClickContactUs = () => {
    const subject = "Inquiry on RiskGPT";
    const body = "Hello Team,\n\nI have a question...";

    const mailtoLink = `mailto:${TEAM_EMAIL}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    try {
      window.open(mailtoLink);
    } catch (error) {
      console.error("Error opening mail window.");
    }
  };

  const containerStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    overflow: "hidden",
    position: "relative",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    color: "#888888",
  };

  const handleOpenAnalytics = () => {
    setAnalyticsDialogOpen(true);
  };

  const handleCloseAnalytics = () => {
    setAnalyticsDialogOpen(false);
  };

  return (
    <BaseAppBar customStyles={AppBarStyle} id={"app-navbar"}>
      <Toolbar sx={{
        width: '100%'
      }}>
        <Box sx={{
          flex: '0', width: '100%', display: 'contents',
          // justifyContent: 'space-between'
        }}>
          <Box sx={{ flexGrow: '1', display: 'inline-flex', width: '60%' }}>
            {
              sidebarCxt.sidebarWidth === 0 ?
                <ButtonIcon
                  size="large"
                  aria-label="Plugins"
                  aria-haspopup="true"
                  color="inherit"
                  handleSubmit={handleContextSwitch}
                >
                  <ReorderIcon fontSize="medium" />
                </ButtonIcon>
                : <></>
            }
            <SearchBar />
            <div
              className="searchResultsButton"
              data-testid={"search-results-button-id"}
            >
            </div>
          </Box>
          <Box sx={{ width: 'auto', justifyContent: 'flex-end', display: "inline-flex", alignItems: "center" }}>
            <div className="logoutButton" data-testid={"logout-button-id"}>
              <ButtonIcon
                size="large"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                handleSubmit={handleClick}
              >
                <div style={containerStyle}>
                  {profileImage == "none" ? (
                    <FaceIcon fontSize="large" sx={imageStyle} />
                  ) : (
                    <img src={profileImage} style={imageStyle} alt="profile" />
                  )}
                </div>
              </ButtonIcon>
            </div>
            {sidebarCxt.sidebarWidth !== 0 ?
              <Typography marginLeft={1} sx={{ color: darkTheme.palette.text.primary }}>Hi, {tokenCxt.firstName}
              </Typography> : <></>}
            <Menu
              // id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              data-testid="quick-actions-dropdown-id"
            >
              <MenuItem sx={MenuItemStyle} onClick={onClickSettings}>
                <span>
                  <SettingsIcon fontSize="small" />
                </span>
                <span style={DropdownIconStlye}>Settings</span>
              </MenuItem>

              <Divider />

              <MenuItem sx={MenuItemStyle} onClick={onClickMyDocuments}>
                <span>
                  <DescriptionIcon fontSize="small"></DescriptionIcon>{" "}
                </span>
                <span style={DropdownIconStlye}>My Resources</span>
              </MenuItem>
              <Divider />
              <MenuItem sx={MenuItemStyle} onClick={onClickDocumentation}>
                <span>
                  <DescriptionIcon fontSize="small"></DescriptionIcon>{" "}
                </span>
                <span style={DropdownIconStlye}>Documentation</span>
              </MenuItem>
              <MenuItem sx={MenuItemStyle} onClick={onClickTour}>
                <span>
                  <TravelExploreRoundedIcon fontSize="small"></TravelExploreRoundedIcon>
                </span>
                <span style={DropdownIconStlye}>Tour</span>
              </MenuItem>
              <MenuItem sx={MenuItemStyle} onClick={onClickReleaseTimeline}>
                <span>
                  <TimelineIcon fontSize="small"></TimelineIcon>
                </span>
                <span style={DropdownIconStlye}>Release Timeline</span>
              </MenuItem>
              <MenuItem sx={MenuItemStyle} onClick={onClickContactUs}>
                <span>
                  <ForumRoundedIcon fontSize="small" />
                </span>
                <span style={DropdownIconStlye}>Contact Us</span>
              </MenuItem>
              <Divider />
              <MenuItem sx={MenuItemStyle} onClick={handleOpenAnalytics}>
                <AnalyticsIcon fontSize="small" />
                <span style={DropdownIconStlye}>Analytics</span>
              </MenuItem>
            </Menu>

            <SettingsList
              open={renderSettingsList}
              handleOpen={handleOpenSettings}
              handleClose={handleCloseSettings}
              handleCloseMenu={handleClose}
            />
            <DocumentList
              open={renderDocumentList}
              handleOpen={handleOpenDocumentList}
              handleClose={handleCloseDocumentList}
            />
            <ReleaseTimeline
              open={renderReleaseTimeline}
              handleOpen={handleOpenReleaseTimeline}
              handleClose={handleCloseReleaseTimeline}
            />
            <AnalyticsDashboard
              isOpen={isAnalyticsDialogOpen}
              onClose={handleCloseAnalytics}
              dashboardUrl="http://10.246.27.233:3000/d/RR7A4igSz/openai-metrics-ln-openai?orgId=1&refresh=30s&from=now-90d&to=now&theme=dark"
            />
          </Box>

        </Box>


      </Toolbar>
    </BaseAppBar>
  );
};

export default NavBar;