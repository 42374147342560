import axios from "axios";

// Create an instance of axios to use for the API calls to the Flask server
export default axios.create({
  // baseURL: "https://jarvisbk.azurewebsites.net/api/",

  // baseURL: "http://127.0.0.1:8001/api/"
  // baseURL: "https://ln-chatgpt-bk.azurewebsites.net/api/"
  // baseURL: "https://ln-chatgpt-bk-dev.azurewebsites.net/api/"
  baseURL: process.env.REACT_APP_API_URL,

});