import React,{ createContext, useState } from "react";

const ThreadContext = createContext({
  threadID: '',
  messageID: '',
  messageSelected: false,
  threadList: [],
  setThreadList: () => {},
  threadselect: () => {},
  messageselect: () => {},
  setMessageSelected: () => {},
});

export const ThreadContextProvider = (props) => {
  const [threadID, setThreadID] = useState('');
  const [messageID, setMessageID] = useState('');
  const [messageSelected, setMessageSelected] = useState(false)
  const [threadList, setThreadList] = useState([]);

  const handleThreadSelection = (threadID) => {
    setThreadID(threadID);
  }

  const handleMessageSelection = (messageID) => {
    setMessageID(messageID);
  }

  const handleMessageSelcted = (messageBool) => {
    setMessageSelected(messageBool)
  }

  const contextValue = {
    threadID,
    messageID,
    threadList,
    messageSelected,
    setThreadList,
    threadselect: handleThreadSelection,
    messageselect: handleMessageSelection,
    setMessageSelected: handleMessageSelcted
  }

  return <ThreadContext.Provider value={contextValue}>{props.children}</ThreadContext.Provider>
}

export default ThreadContext;