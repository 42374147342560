import { createTheme } from '@mui/material/styles';
import { red, yellow, blue, green } from '@mui/material/colors';

// #212121
const darkTheme = createTheme({
  typography: {
    //fontFamily: 'Open Sans',
    fontSize: 12
  },
  palette: {
    //mode: 'dark',
    primary: {
      main: '#3CE2B3', 
    },
    secondary: {
      main: '#C4F7A1', 
    },
    background: {
    //  default: '#0E0F11', 
    // paper: '#141519',
      default: '#0E1117',
      paper: '#171C26',
      // default: '#0E0F11',
      // paper: '#0F131A',
      tabPaper: '#1F2533',
    },
    text: {
      primary: '#E0E0E0', 
      secondary: '#B0BEC5',
    },
    error: {
      main: '#e57373', 
    },
    info: {
      main: '#29B6F6', 
    },
    success: {
      main: '#3CE2B3', 
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // backgroundColor: '#000000',
        //  color: '#000000', 
          outlineColor: '#ffffff',
          border: '1px solid rgba(42, 47, 56, 0.1)',
          "&.Mui-disabled": {
            background: "#37474F",
            color: "#78909C"
          }
        },
        outlined: {
          borderColor: '#D9D9E3', // Custom outline color
          color: '#D9D9E3', // Custom text color
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          outline: 'solid'
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "linear-gradient(175deg, #1F2027 0%, #1B1C22 100%)",
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          color: 'white'
        }
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'document_card' },
          style: {
            backgroundColor: '#353740',
          },
        },
        {
          props: { variant: 'selected_document_card' },
          style: {
            backgroundColor: '#09af00',
          },
        }
      ],
      styleOverrides: {
        root: {
          backgroundColor: '#444654', // Customize the background color for the card
        },
      },
    },
    MuiCustomCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#444654', // Customize the background color for the card
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
    MuiTextField: { //default label color should be whitish
      variants: [
        {
          props: { disabled: true },
          style: {
            outline: 'none',
            border: 'none',
            borderRadius: '10px',
            backgroundImage: 'linear-gradient(90deg, white 50%, transparent 50%), linear-gradient(90deg, white 50%, transparent 50%), linear-gradient(0deg, white 50%, transparent 50%), linear-gradient(0deg, white 50%, transparent 50%)',
            backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
            backgroundSize: '15px 4px, 15px 4px, 4px 15px, 4px 15px',
            animation: 'border-dance 4s infinite linear',
            "@keyframes border-dance": {
              "0%": {
                backgroundPosition: "left top, right bottom, left bottom, right   top",
              },
              "100%": {
                backgroundPosition: "left 15px top, right 15px bottom , left bottom 15px , right   top 15px",
              }
            }
          },
        },
        {
          props: { placeholder: "Search..." },
          style: {
            backgroundColor: "#1F1F1F",
          },
        }
      ],
      styleOverrides: {
        root: {
          backgroundColor: '#444654',
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0)",
          border: "none",
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              boxShadow: "0px 0px 1px 0.5px rgba(255, 255, 255, 1.0)",
              border: "none",
            },
            '&.Mui-focused fieldset': {
              boxShadow: "0px 0px 1px 0.5px rgba(255, 255, 255, 1.0)",
              border: "none",
            },
            borderRadius: "10px",
          },
          "&.Mui-disabled": {
            background: "#f3f3f3",
            color: "#dadada"
          }
        },

      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          border: '1px solid rgba(217, 217, 227, 0.5)',
        }
      }
    },
    MuiDrawer: {
      variants: [
        {
          props: { id: "sidebar-drawer-id" },
          style: {
            '& .MuiDrawer-paper': {
              width: 240,
              display: 'grid',
              background: 'linear-gradient(180deg, #1B1C22 0%, #1F2027 100%)',
              boxShadow: '2px 0 6px rgba(0, 0, 0, 0.1), 3px 0 8px rgba(0, 0, 0, 0.1 )',
              transition: 'all 0.3s ease',
              color: '#F7F7F8',
            },

          },
        },
      ],
      styleOverrides: {
        paper: {
          backgroundColor: '#202123',
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          border: "1px solid #D9D9E3",
          borderRadius: '10px',
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: '25vw',
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          border: "1px solid #D9D9E3",
          '&.Mui-selected': {
            '&:hover': {
              backgroundColor: "#FFFFFF",
            },
            backgroundColor: "#D9D9E3",
            color: "#202123"
          },
          borderRadius: '10px',
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          border: 'none',
          borderRadius: '10px',
          backgroundColor: '#262F40', 
          color: '#FFFFFF', 
          '&:hover': {
           // backgroundColor: '#1F2833', 
          },
          '& .MuiSelect-icon': {
            color: '#FFFFFF', 
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '10px',
          backgroundColor: '#262F40',
          color: '#FFFFFF',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#137C5E', 
            color: '#FFFFFF', 
          },
          '&:hover': {
            backgroundColor: '#1F2833', 
            color: '#FFFFFF', 
          },
        },
      },
    },
  
    MuiSlider: {  //overriding the slider color
      styleOverrides: {
        root: {
          '& .MuiSlider-track': {
            backgroundColor: '#3CE2B3',
          },
          '& .MuiSlider-thumb': {
            backgroundColor: '#465572',
          },
        },
      },
    },
    MuiSpeedDial: {
      styleOverrides: {
        fab: {
          backgroundColor: 'transparent', // Custom icon color
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#353740',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        standardSuccess: {
          backgroundColor: '#137C5E',
          color: '#FFF',
        },
        standardInfo: {
          backgroundColor: '#1976D2',
          color: '#FFF',
        },
        standardWarning: {
          backgroundColor: '#F57C00',
          color: '#FFF',
        },
        standardError: {
          backgroundColor: '#D32F2F',
          color: '#FFF',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "0px 0px 0px 0px !important",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        // Customize the scrollbar for the root container (e.g., body or a specific component)
        '::-webkit-scrollbar': {
          width: '8px', // Specify the width of the scrollbar
        },
        '::-webkit-scrollbar-track': {
          background: '#171C26', // Specify the background color of the scrollbar track
        },
        '::-webkit-scrollbar-thumb': {
          background: '#888', // Specify the color of the scrollbar thumb
          borderRadius: '4px', // Specify the border radius of the scrollbar thumb
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#1F2533',
          borderColor: '#3CE2B3',
          
          borderRadius: '20px',
          '&:hover': {
            backgroundColor: '#19A67E',
          },
        },
        deleteIcon: {
          color: '#FFFFFF',
        },
        
      },
    },
    // Override styles of hyperlink
    a: {
      color: 'green',
      textDecoration: 'none',
    },
   


  },
});
export default darkTheme;