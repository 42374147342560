import React, { useMemo, useState, useEffect, useContext } from 'react';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

//Contexts
import PreferenceContext from "../context/PreferenceContext";

export default function BaseAgGridTable({
  rows,
  columns,
  updateCurrentSelection,
  getRowStyle,
  onCellEditingStopped,
}) {
  const preferenceCxt = useContext(PreferenceContext);
  const preferredTheme = preferenceCxt.preferenceList.theme;

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = params => {
    setGridApi(params.api);
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true,
    filter: true,
    unSortIcon: true,     // always show unSortIcon
    stopEditingWhenGridLosesFocus: true, // stop editing when grid loses focus
  }), []);

  useEffect(() => {
    if (!gridApi) return;

    const handleSelectionChanged = () => {
      const selectedRows = gridApi.getSelectedRows();
      updateCurrentSelection(selectedRows);
    };

    gridApi.addEventListener('selectionChanged', handleSelectionChanged);

    return () => {
      gridApi.removeEventListener('selectionChanged', handleSelectionChanged);
    };
  }, [gridApi, updateCurrentSelection]);

  return (
    <div className={preferredTheme === 1 ? "ag-theme-quartz" : "ag-theme-quartz-dark"} style={{ boxSizing: "border-box", height: "50vh", width: "100%", overflow: 'auto' }}>
      <AgGridReact
        columnDefs={columns}
        rowData={rows}
        onGridReady={onGridReady}
        rowSelection="multiple"
        defaultColDef={defaultColDef}
        onCellEditingStopped={onCellEditingStopped}
        getRowStyle={getRowStyle}
        suppressRowClickSelection
      />
    </div>
  );
}
