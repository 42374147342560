// This component represents the quick actions custom component shown once user logs into the app

import React, { useState, useContext } from 'react';

// MUI icons
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';


// MUI components
import { Button, Divider, Table, TableBody, TableRow, TableCell, Typography, Grid, Box, TableContainer, Paper } from '@mui/material';

//base components
import CardButton from "../../base_components/CardButton";

//custom components
import Tour from '../Tour/Tour';
import SettingsList from '../SettingsList/SettingsList';
import Logo from '../Logo/Logo';

import PreferenceContext from '../../context/PreferenceContext';

//constants
import { DOCUMENTATION_URL, R_AND_D_URL, BEST_PRACTICES_URL, TEAM_EMAIL, DISCLAIMER_MSG } from '../../config/constants';
import BasePaper from '../../base_components/BasePaper';
import { MODEL_NUMERIC_TO_WORDS, EMBEDDING_MODEL_NUMERIC_TO_WORDS, CHUNKER_TYPE_NUMERIC_TO_WORDS, EMBEDDING_MODELS_NUMERIC_TO_READABLE_WORDS, CHUNKER_TYPE_NUMERIC_TO_READABLE_WORDS } from '../../config/constants';
import { AssignmentTurnedIn, Close, Done, Edit } from '@mui/icons-material';


const QuickActionsPanel = ({
  beginTour,
  onClickTour,
  handleBeginTourClose,
  setIsStagingThreadPreferenceDialogOpen,
  stagingThreadPreferences,
  id
}) => {

  const preferenceCxt = useContext(PreferenceContext);
  const preferredTheme = preferenceCxt.preferenceList.theme;

  //some custom styles that are re-used within the component
  const CustomIconStyle = { fontSize: 18 };
  const CenterTextStyle = { display: 'flex', justifyContent: 'center' };
  // Set background color to match the theme
  const CustomCardStyle = { width: "100%", height: "100%" };
  if (preferredTheme === 1) {
    CustomCardStyle.backgroundColor = '#F7F7F8';
    CustomCardStyle.color = '#202123';
  }
  else if (preferredTheme === 2) {
    CustomCardStyle.backgroundColor = '#171C26';
    CustomCardStyle.color = '#B0BEC5';
  }
  else if (preferredTheme === 3) {
    CustomCardStyle.backgroundColor = '#000000';
    CustomCardStyle.color = '#FFFFFF';
  }

  const borderColors = {
    1: '#C2C2C2',
    2: '#0B4736',

  };

  const borderBottomColor = borderColors[preferredTheme] || '#1B5E20';

  const CardButtonStyle = { border: 'none', };
  // dialog box and tour state and handlers
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  //event handlers
  const onClickDocumentation = () => {
    try {
      window.open(DOCUMENTATION_URL, "_blank");
    }
    catch (error) {
      console.error("Error opening link. The link may be incorrect or broken")
    }

  };

  const onClickBestPractices = () => {
    try {
      window.open(BEST_PRACTICES_URL, "_blank");
    }
    catch (error) {
      console.error("Error opening link. The link may be incorrect or broken")
    }

  };

  const onClickRAndD = () => {
    try {
      window.open(R_AND_D_URL, "_blank");
    }
    catch (error) {
      console.error("Error opening link. The link may be incorrect or broken")
    }

  };

  const onClickSettings = () => {
    handleOpenDialog();
  };

  const onClickContactUs = () => {
    const subject = 'Inquiry on RiskGPT';
    const body = 'Hello Team,\n\nI have a question...';

    const mailtoLink = `mailto:${TEAM_EMAIL}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    try {
      window.open(mailtoLink);
    }
    catch (error) {
      console.error("Error opening mail window.")
    }
  };

  return (
    <>
      <Grid container
        spacing={2}
        maxWidth="60%"
        marginLeft={"auto"}
        marginRight={"auto"}
        marginTop={"auto"}
        data-testid={id}
      >
        <Grid item xs={12}>
          <h1 style={CenterTextStyle}><Logo />RiskGPT</h1>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction="row" display="flex" justifyContent="space-between">
            {!!preferenceCxt.preferenceList.threadPreferenceFlag && <Grid item xs={12} md={12} lg={5}>
              <CardButton
                cardHeader="Thread Level Preferences"
                cardSubHeader="You currently have Thread Preference Control enabled, which means you can set preferences specific to the current thread.
                These are your current thread level preferences for your current thread. Click on the button above to modify them. You can modify these preferences at any time during the conversation, however it is recommended to set them at the beginning of the conversation."
                cardStyles={{ ...CustomCardStyle, height: "100%" }}
                cardBody={
                  <Box>
                    <TableContainer sx={{ overflow: 'overflow' }}>
                      <Table size="small">
                        <TableBody>
                          {[
                            { label: 'Model', value: MODEL_NUMERIC_TO_WORDS[stagingThreadPreferences.model] },
                            { label: 'Temperature', value: stagingThreadPreferences.temperature },
                            { label: 'Top P', value: stagingThreadPreferences.topP },
                            { label: 'Max Tokens', value: stagingThreadPreferences.maxTokens },
                            { label: 'Frequency Penalty', value: stagingThreadPreferences.frequencyPenalty },
                            { label: 'Presence Penalty', value: stagingThreadPreferences.presencePenalty },
                            { label: 'Stop Sequence', value: stagingThreadPreferences.stopSequence },
                            { label: 'Embedding Model', value: EMBEDDING_MODELS_NUMERIC_TO_READABLE_WORDS[stagingThreadPreferences.embeddingModel] },
                            { label: 'Chunker Type', value: CHUNKER_TYPE_NUMERIC_TO_READABLE_WORDS[stagingThreadPreferences.chunkerType] },
                            { label: 'Model Persona', value: !!stagingThreadPreferences.modelPersona ? <Done /> : <Close /> },
                            { label: 'User Background', value: !!stagingThreadPreferences.userBackground ? <Done /> : <Close /> }
                          ].map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: `1px solid ${borderBottomColor}` }}>
                              <TableCell sx={{ fontWeight: 'bold', borderBottom: `1px solid ${borderBottomColor}` }}>{row.label}</TableCell>
                              <TableCell sx={{ borderBottom: `1px solid ${borderBottomColor}` }}>{row.value}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                }
                buttonStyles={{ ...CardButtonStyle, height: "100%" }}
                onClick={() => {
                  setIsStagingThreadPreferenceDialogOpen(true);
                }}
              >
                <Edit sx={CustomIconStyle} />
              </CardButton>
            </Grid>}
            <Grid item md={12} lg={!!preferenceCxt.preferenceList.threadPreferenceFlag ? 7 : 12}>
              <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                  <div className="documentationQAPButton">
                    <CardButton
                      cardHeader="Documentation"
                      cardStyles={CustomCardStyle}
                      buttonStyles={CardButtonStyle}
                      onClick={onClickDocumentation}
                    >
                      <OpenInNewRoundedIcon sx={CustomIconStyle} />
                    </CardButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <div className="documentationQAPButton">
                    <CardButton
                      cardHeader="Best Practices"
                      cardStyles={CustomCardStyle}
                      buttonStyles={CardButtonStyle}
                      onClick={onClickBestPractices}
                    >
                      <OpenInNewRoundedIcon sx={CustomIconStyle} />
                    </CardButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <div className="documentationQAPButton">
                    <CardButton
                      cardHeader="R&D Blog"
                      cardStyles={CustomCardStyle}
                      buttonStyles={CardButtonStyle}
                      onClick={onClickRAndD}
                    >
                      <OpenInNewRoundedIcon sx={CustomIconStyle} />
                    </CardButton>
                  </div>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <div className="tourQAPButton">
                    <CardButton
                      cardHeader="Tour"
                      cardStyles={CustomCardStyle}
                      buttonStyles={CardButtonStyle}
                      onClick={() => onClickTour()}
                    >
                      <TravelExploreRoundedIcon sx={CustomIconStyle} />
                    </CardButton>
                  </div>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <div className="settingsQAPButton">
                    <CardButton
                      cardHeader="Settings"
                      cardStyles={CustomCardStyle}
                      buttonStyles={CardButtonStyle}
                      onClick={onClickSettings}
                    >
                      <SettingsRoundedIcon sx={CustomIconStyle} />
                    </CardButton>
                  </div>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <div className="contactUsQAPButton">
                    <CardButton
                      cardHeader="Contact Us"
                      cardStyles={CustomCardStyle}
                      buttonStyles={CardButtonStyle}
                      onClick={onClickContactUs}
                    >
                      <ForumRoundedIcon sx={CustomIconStyle} />
                    </CardButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="disclaimerQAPButton">
                    <CardButton
                      cardHeader="Disclaimer"
                      cardBody={DISCLAIMER_MSG}
                      cardStyles={CustomCardStyle}
                      buttonStyles={CardButtonStyle}
                    >
                      <ReportGmailerrorredRoundedIcon sx={CustomIconStyle} />
                    </CardButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>



      </Grid>

      <SettingsList open={open} handleOpen={handleOpenDialog} handleClose={handleCloseDialog} />
      {beginTour && <Tour handleBeginTourClose={() => handleBeginTourClose()} />}
    </>
  );
};

export default QuickActionsPanel;