import React, { useContext, useEffect, useState } from 'react';

import PreferenceContext from '../../context/PreferenceContext';
import ThreadContext from '../../context/ThreadContext';
import SidebarContext from '../../context/SidebarContext';
import TokenContext from '../../context/TokenContext';
import { AlertContext } from '../../context/AlertContext';
import { OperationalModeContext } from '../../context/OperationalModeContext';

import { Box, Chip, ListItemIcon, ListItemText, Menu, MenuItem, Switch, CircularProgress } from '@mui/material';
import ModeSelectorChip from '../ModeSelectorChip/ModeSelectorChip';

import Info from '@mui/icons-material/Info';
import { CheckCircle, Cancel } from '@mui/icons-material';
import ThreadPreferenceContext from '../../context/ThreadPreferenceContext';
import { MODEL_NUMERIC_TO_WORDS } from '../../config/constants';

const MessageWindowNavBar = ({
  ThemeBackgroundColor,
  ThemeColor,
  preferredTheme,
  modelLabel,
  setIsThreadPreferenceDialogOpen,
  handleOpenDocumentList,
  setIsStagingThreadPreferenceDialogOpen
}) => {
  const preferenceCxt = useContext(PreferenceContext);
  const threadCxt = useContext(ThreadContext);
  const sidebarCxt = useContext(SidebarContext)
  const tokenCxt = useContext(TokenContext);
  const { showAlert } = useContext(AlertContext);
  const {
    mode,
    details,
    changeMode,
    selectedUserDocuments,
    clearSelectedUserDocuments,
    selectedInternalDocumentSources,
    addSelectedInternalDocumentSource,
    removeSelectedInternalDocumentSource,
    clearSelectedInternalDocumentSources,
    saveDocuments,
    addSelectedUserDocument
  } = useContext(OperationalModeContext);
  const threadPreferenceCxt = useContext(ThreadPreferenceContext);

  const [modeDetailsAnchorEl, setModeDetailsAnchorEl] = useState(null);
  const [preferenceDetailsAnchorEl, setPreferenceDetailsAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDetailsLabel = (mode, details) => {
    switch (mode) {
      case "User Uploaded Doc Q&A":
        return `Documents Active: ${selectedUserDocuments.length}`;
      case "Plugins":
        return `Plugins Active: ${details.number}`;
      case "Jarvis":
        return `Sources Active: ${selectedInternalDocumentSources.length}`;
      case "General":
        return `No Active Documents`;
      default:
        return `Details: ${details.number}`; // Fallback label
    }
  };

  const handleModeDetailsClick = (event) => {
    setModeDetailsAnchorEl(event.currentTarget);
  };

  const closeModeDetailsMenu = () => {
    setModeDetailsAnchorEl(null);
  };

  const handleGeneralModeClick = async () => {
    clearSelectedUserDocuments();
    clearSelectedInternalDocumentSources();
    const response = await saveDocuments("General", []);
    if (!!response) {
      showAlert("General mode activated. All document sources are now inactive and the responses will be based on the model only", "info");
    } else {
      showAlert("Error in saving general mode", "error");
    }
    closeModeDetailsMenu();
  }

  const handleMenuItemClick = async (item) => {
    if (selectedInternalDocumentSources.includes(item)) {
      removeSelectedInternalDocumentSource(item);
      const response = await saveDocuments("Jarvis", selectedInternalDocumentSources.filter((source) => source !== item));
      if (!!response) {
        showAlert(`Document source ${item} is now inactive`, "info");
      } else {
        showAlert("Error in saving document source", "error");
      }
    } else {
      addSelectedInternalDocumentSource(item);
      const response = await saveDocuments("Jarvis", [...selectedInternalDocumentSources, item]);
      if (!!response) {
        showAlert(`Document source ${item} is now active. IMPORTANT: Please ensure that the embedding model is set to 'Text Embedding ADA v2' model for this mode. Other embedding models are not currently supported for this mode. You can set the embedding model in the 'Settings' tab under 'RAG Prederences' tab.`, "info", 15000);
      } else {
        showAlert("Error in saving document source", "error");
      }
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      backgroundColor: ThemeBackgroundColor[preferredTheme],
      color: ThemeColor[preferredTheme],
      padding: '10px 15px'
    }}
    >
      <Box>
        <Chip
          label={`Model: ${modelLabel}`}
          variant="outlined"
          disabled={(!threadCxt.threadID )|| (!!threadPreferenceCxt.preferenceList.threadPreferenceFlag) ? false : true}
          size="medium"
          sx={{ marginRight: "10px" }}
          icon={<Info color="primary" />}
          onClick={
            (event) => {
              if (!!threadCxt.threadID) {
                if (Object.keys(threadPreferenceCxt.preferenceList).includes("threadPreferenceFlag") && !!threadPreferenceCxt.preferenceList.threadPreferenceFlag) {
                  setIsThreadPreferenceDialogOpen(true);
                } else {
                  setPreferenceDetailsAnchorEl(event.currentTarget);
                }
              }
              else if (Object.keys(preferenceCxt.preferenceList).includes("threadPreferenceFlag") && !!preferenceCxt.preferenceList.threadPreferenceFlag) {
                setIsStagingThreadPreferenceDialogOpen(true);
              }
            }
          }
        />

        <Chip
          label={!threadCxt.threadID ? "Thread Preferences Control" : !!threadPreferenceCxt.preferenceList.threadPreferenceFlag ? "Thread Preferences Enabled" : "Thread Preferences Disabled"}
          variant="outlined"
          disabled={(!threadCxt.threadID )|| (!!threadPreferenceCxt.preferenceList.threadPreferenceFlag) ? false : true}
          size="medium"
          icon={
            !threadCxt.threadID ? <Switch
              checked={(!!preferenceCxt.preferenceList.threadPreferenceFlag ? true : false)}
              onChange={async () => {
                setLoading(true);
                const newPreferences = { ...preferenceCxt.preferenceList };
                newPreferences.threadPreferenceFlag = !newPreferences.threadPreferenceFlag;
                const response = await preferenceCxt.savePreferences(newPreferences, tokenCxt.token);
                if (!!response) {
                  preferenceCxt.updatePreferences(newPreferences);
                  if (newPreferences.threadPreferenceFlag) {
                    showAlert(
                        `Thread Preference Control is now enabled for the new chat conversation. You can now set preferences for specifically for this thread by clicking on the Model chip or the THREAD LEVEL PREFERENCES button.`, 
                        "info", 15000
                    );
                } else {
                    showAlert(
                        `Thread Preference Control is now disabled for the new chat conversation. Your global preferences will be used.`,
                        "info"
                    );
                }
                setLoading(false);
                }
                else showAlert("Error in saving thread preference control", "error");
              }}
              disabled={!!threadCxt.threadID}
            ></Switch>
              : !!threadPreferenceCxt.preferenceList.threadPreferenceFlag ? <CheckCircle color="primary" /> : <Cancel color="primary" />}
        ></Chip>
        {loading && <CircularProgress size={12} sx={{ marginLeft: '10px' }} />} 
      </Box>
      <ModeSelectorChip
        handleOnClick={handleModeDetailsClick}
        subLabel={getDetailsLabel(mode, details)}
        handleOnClose={closeModeDetailsMenu}
        modeDetailsAnchorEl={modeDetailsAnchorEl}
        handleGeneralModeClick={handleGeneralModeClick}
        selectedInternalDocumentSources={selectedInternalDocumentSources}
        handleMenuItemClick={handleMenuItemClick}
        handleOpenDocumentList={() => { handleOpenDocumentList(); closeModeDetailsMenu(); }}
      />
      <Menu
        anchorEl={preferenceDetailsAnchorEl}
        open={!!preferenceDetailsAnchorEl}
        onClose={() => { setPreferenceDetailsAnchorEl(null) }}
        sx={{
          maxWidth: 700,
          overflowX: "auto"
        }}
      >
        {!!threadPreferenceCxt.preferenceList && Object.keys(threadPreferenceCxt.preferenceList).map(
          (key, index) => {
            if (key == "selectedDocuments" || key == "documentMode") return;
            let value = threadPreferenceCxt.preferenceList[key];
            if (key == "model") value = MODEL_NUMERIC_TO_WORDS[value];
            return (
              <MenuItem
                key={index}
              >
                <ListItemIcon fontSize="small"></ListItemIcon>
                <ListItemText>{key} : {value}</ListItemText>
              </MenuItem>
            )
          }
        )}
      </Menu>
    </Box>
  )
}

export default MessageWindowNavBar;