import React, { useState } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  List,
  IconButton,
  styled,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Divider
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

const StyledListItem = styled(List)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: "bold",
}));

const EntitiesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root": {
    backgroundColor: theme.palette.action.hover,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .MuiAccordionDetails-root": {
    padding: theme.spacing(2),
  },
}));

const DocumentInfo = ({ open, onClose, document }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  if (!document) return null;

  const {
    createdAt,
    embedding_model,
    title,
    text,
    num_chunks,
    chunk_token_sizes,
    text_per_chunk,
    entities,
    embedding_cost,
  } = document.document;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 4,
          ml: 4,
          height: '50px'
        }}>
          <Typography variant="h5" component="div" sx={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>Document Information</Typography>
          <IconButton edge="end" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box p={3}>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <HeaderTypography variant="overline">Created At</HeaderTypography>
              <Typography variant="body1">
                {new Date(createdAt).toLocaleString()} [UTC]
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <HeaderTypography variant="overline">Embedding Model</HeaderTypography>
              <Typography variant="body1">{embedding_model}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <HeaderTypography variant="overline">Embedding Cost</HeaderTypography>
              <Typography variant="body1">
                {embedding_cost ? `$${Number(embedding_cost).toFixed(6)}` : "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <HeaderTypography variant="overline">Title</HeaderTypography>
              <Typography variant="body1">{title}</Typography>
            </Grid>
          </Grid>

          <HeaderTypography variant="overline">
            Extracted Entities
          </HeaderTypography>
          <EntitiesContainer>
            {entities && entities.length > 0 ? (
              entities.map((entity, idx) => (
                <Chip
                  key={idx}
                  label={`${entity.text} (${entity.label})`}
                  variant="outlined"
                />
              ))
            ) : (
              <Typography variant="body2">No entities extracted.</Typography>
            )}
          </EntitiesContainer>

          <Box my={2}>
            <Typography
              variant="h6"
              onClick={() => setExpanded(!expanded)}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Text of the document{" "}
              {expanded ? <ExpandMoreIcon /> : <ExpandMoreIcon />}
            </Typography>
            {expanded && (
              <Typography
                variant="body2"
                mt={1}
                style={{
                  whiteSpace: "pre-wrap",
                  border: "1px solid #e0e0e0",
                  borderRadius: 4,
                  padding: "8px",
                  boxShadow: theme.shadows[1],
                }}
              >
                {text}
              </Typography>
            )}
          </Box>

          <HeaderTypography variant="overline" mt={2}>Number of chunks</HeaderTypography>
          <Typography variant="body1">{num_chunks}</Typography>
          <HeaderTypography variant="overline">
            Details for Each Chunk
          </HeaderTypography>
          <Divider />

          <List mt={2}>
            {text_per_chunk?.map((text, idx) => (
              <StyledAccordion key={idx}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    Chunk {idx + 1} - Token Size: {chunk_token_sizes[idx]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
                    {text}
                  </Typography>
                </AccordionDetails>
              </StyledAccordion>
            ))}
          </List>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentInfo;
