import flask from "./flask";

// Get analytics data
const getAnalyticsData = async (token) => {
    try {
        const response = await flask.get('/analytics',
            {
                headers: { 'Authorization': `Bearer ${token}` }
            }
        );
        return {
            status: response.status,
            data: response.data
        };
    } catch (e) {
        return {
            status: e.response ? e.response.status : 500,
            error: e.response ? e.response.statusText : "Internal Server Error",
            data: "",
        };
    }
};

export default getAnalyticsData;
