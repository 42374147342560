import flask from "./flask";
import { parseMessages } from "./utils";


// Edit a user's message prompt
const putMessage = async (messageID, content, token, mode, selectedUserDocuments, selectedInternalDocumentSources) => {
  let payload = {
    "message_id": messageID,
    "content": content,
    "mode": mode,
    "selected_user_documents": selectedUserDocuments,
    "selected_internal_document_sources": selectedInternalDocumentSources,
  }
  try {
    const response = await flask.put(`message` , payload, {
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    });

    const messages = parseMessages(response.data)
    return {
      status: response.status,
      data: messages,
    };
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default putMessage;