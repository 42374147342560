import React, { useState, useEffect, useRef, useContext } from "react";

// MUI component
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import Grid from "@mui/material/Grid";

//assets
import botImage from "../../assets/just-bot.svg";

// base components
import BasePaper from "../../base_components/BasePaper";
import ButtonIcon from "../../base_components/ButtonIcon";
import BaseTextField from "../../base_components/BaseTextField";

// custom components
import CodeBlock from "../CodeBlock/CodeBlock";

// context
import ThreadContext from "../../context/ThreadContext";

// themes
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import PreferenceContext from "../../context/PreferenceContext";
import DOMPurify from "dompurify";
import TypingContext from "../../context/TypingContext";
import { Box } from "@mui/material";

const Message = ({
  sender,
  content,
  messageID,
  siblings,
  timestamp,
  handleEdit,
  handleGetHistoricalMessages,
  isGeneratingResponse,
}) => {
  const threadCxt = useContext(ThreadContext);
  const preferencesCxt = useContext(PreferenceContext);
  const typingCxt = useContext(TypingContext);
  const [message, setMessage] = useState(false);
  const textAreaRef = useRef(null)
  const [formattedmessage, setFormattedMessage] = useState([]);
  setTimeout(() => setMessage(true), 1000);
  const paperBackGroundColor = {
    1:
      sender === "model"
        ? lightTheme.palette.background.default
        : lightTheme.palette.background.paper,
    2:
      sender === "model"
        ? darkTheme.palette.background.default
        : darkTheme.palette.background.paper,
  };
  const messagePaperStyle = {
    width: '100%',
    padding: '1%',
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: [preferencesCxt.preferenceList.theme],
    filter: message ? "none" : "invert(0.2)",
    transition: "all 1s ease",
    WebkitTransition: "all 1s ease",
    MozTransition: "all 1s ease",
  };
  const mainPaperStyle = {
    padding: '1%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: paperBackGroundColor[preferencesCxt.preferenceList.theme],
  };
  const senderIdentifierStyle = {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%", // Rounded borders for a circle shape
    backgroundColor: "#19A67F",
    color: "#ffffff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", // Shadow for depth
    overflow: "hidden", // Ensure the image fits inside the circle
    objectFit: "cover", // Ensure the image covers the entire space
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  const customButtonStyle = { width: "20px", height: "20px" };
  const centerDivElementsStyle = { textAlign: "left" };
  const timestampFontColor = {
    1: "rgba(68, 70, 84, 0.7)",
    2: "rgba(217,217,227, 0.7)",
  };
  const timestampStyling = {
    fontSize: "12px",
    fontStyle: "italic",
    fontFamily: '"Courier New", monospace',
    color: timestampFontColor[preferencesCxt.preferenceList.theme],
    float: "right",
    paddingRight: "2%",
  };
  const iconColor = {
    1: "rgba(32,33,35, 0.7)",
    2: "rgba(217,217,227, 0.7)",
  };
  const iconStyle = {
    color: iconColor[preferencesCxt.preferenceList.theme],
    width: "17px",
    height: "17px",
  };
  const arrowsButtonIconStyle = { width: "6px", height: "6px" };
  const profileImage = sessionStorage.getItem("profileImage");

  //state variables
  const [copyIcon, setCopyIcon] = useState(
    <ContentPasteRoundedIcon style={iconStyle} />
  );
  const [isEditing, setIsEditing] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(
    siblings.findIndex((item) => item === messageID)
  );
  const [textfieldValue, setTextfieldValue] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  // custom button style -- order is intended since isHovered variable is used here
  const onHoverButtonStyle = {
    visibility: isHovered ? "visible" : "hidden",
    opacity: isHovered ? 1 : 0,
    transition: "visibility 0s linear 0.3s, opacity 0.3s",
  };

  useEffect(() => {
    let isSubscribed = true;
    const setIndex = async () => {
      const targetIndex =
        !!messageID &&
        (await siblings.findIndex((message) => message === messageID));
      setCurrentMessageIndex(targetIndex);
    };
    !!siblings && siblings.length > 1 && setIndex();
    setMessage(false);
    return () => (isSubscribed = false);
  }, [siblings]);

  useEffect(() => {
    setMessage(false);
  }, [threadCxt.messageID]);

  useEffect(() => {
    if (messageID === typingCxt.messageID)
      typeMessage(content)
  }, [typingCxt.messageID]);

  useEffect(() => {
    textAreaRef.current.scrollIntoView();
  }, [formattedmessage]);

  const getInitials = (name) => {
    if (typeof name !== "string") {
      return "U";
    }
    return name.slice(0, 2).toUpperCase();
  };

  //handlers
  const handleEditClick = () => {
    setTextfieldValue(content); //set new message with current message and allow user to make edits to this message
    setIsEditing(true);
  };

  const handleEditClose = () => {
    setIsEditing(false);
  };

  const handleTextFieldChange = (event) => {
    let msg = event.target.value;
    setTextfieldValue(msg);
  };

  const handleEditButtonSubmit = async () => {
    setIsEditing(false);
    await handleEdit(messageID, textfieldValue);
  };

  const handleCopyIconClick = () => {
    navigator.clipboard.writeText(content);
    showMessage(
      <ContentPasteRoundedIcon style={iconStyle} />,
      <DoneRoundedIcon style={iconStyle} />
    );
  };

  const handleLeftArrowClick = async () => {
    if (currentMessageIndex > 0) {
      await handleGetHistoricalMessages(
        siblings[currentMessageIndex - 1],
        messageID
      );
    }
  };

  const handleRightArrowClick = async () => {
    if (currentMessageIndex < siblings.length - 1) {
      await handleGetHistoricalMessages(
        siblings[currentMessageIndex + 1],
        messageID
      );
    }
  };

  const showMessage = (originalComponent, newComponent) => {
    setCopyIcon(newComponent);

    setTimeout(() => {
      setCopyIcon(originalComponent);
    }, 2000);
  };

  const message_formatting = (value) => {
    const specialTextRegex =
      /(The above information was derived from these sources:.*?)(?=The above information was derived from these sources:|$)/gs;

    // Determine the style based on the theme preference
    const themeStyle = preferencesCxt.preferenceList.theme === 2
      ? 'background: linear-gradient(135deg, #868A98, #717584); color: black;'
      : 'background: linear-gradient(135deg, #f0f1f6, #d3d6dd); color: black;';

    const styledPart = value.replace(
      specialTextRegex,
      `<div style="${themeStyle} padding-left: 20px; padding-top: 30px; font-size: 12px; border-radius: 15px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);">$1</div>`
    );

    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      if ('target' in node && !['_self', '_parent', '_top'].includes(node.target.toLowerCase())) {
        node.setAttribute('target', '_blank');
      }
    });
    return DOMPurify.sanitize(styledPart)
      .replace(/`</g, '`&lt;').replace(/>`/g, '&gt;`')
      .replace(/`([^`]*)`/g, '`<b>$1</b>`')
      .replace(
        /\n/g,
        "<br>"
      )
      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
  }
  
  const typeMessage = async (message) => {
    const codeIdentifierRegex = /```([\s\S]*?)```/g;
    const output = message.split(codeIdentifierRegex);
    for (let i = 0; i < output.length; i++) {
      await resultCreation(output[i], i)
    }
    typingCxt.finished()
  }

  // const resultCreation = async (value, index) => {
  //   return new Promise((resolve, reject) => {
  //     let isCode = false
  //     let language = ''
  //     let i = 0;
  //     let stringResponse = ''
  //     let result = ''
  //     if (index % 2 === 0) {
  //       stringResponse = message_formatting(value)
  //     }
  //     else {
  //       const languageIndex = value.indexOf("\n");
  //       language =
  //         value.substring(0, languageIndex) === ""
  //           ? "markdown"
  //           : value.substring(0, languageIndex);
  //       stringResponse = value.substring(languageIndex + 1, value.length);
  //       isCode = true
  //     }

  //     const intervalId = setInterval(() => {
  //       const blinking = i < stringResponse.length ? '█' : ''

  //       if (isCode === true)
  //         result = <CodeBlock key={index} language={language} code={stringResponse.slice(0, i) + blinking} />
  //       else {
  //         result = <div
  //           key={index}
  //           dangerouslySetInnerHTML={{ __html: stringResponse.slice(0, i) + blinking }}
  //         />
  //       }
  //       setFormattedMessage(formattedmessage => {
  //         const update = [...formattedmessage]
  //         update[index] = result
  //         return update
  //       })

  //       i++;

  //       if (i > stringResponse.length) {
  //         clearInterval(intervalId);
  //         resolve(true)
  //       }
  //     }, 1);
  //   })
  // }

  // Removing blinking effect for now - should be handled via streaming response
  const resultCreation = async (value, index) => {
    return new Promise((resolve, reject) => {
      let isCode = false;
      let language = '';
      let stringResponse = '';
      let result = '';
  
      if (index % 2 === 0) {
        stringResponse = message_formatting(value);
      } else {
        const languageIndex = value.indexOf("\n");
        language = languageIndex === -1 || value.substring(0, languageIndex) === ""
          ? "markdown"
          : value.substring(0, languageIndex);
        stringResponse = value.substring(languageIndex + 1, value.length);
        isCode = true;
      }
  
      if (isCode) {
        result = <CodeBlock key={index} language={language} code={stringResponse} />;
      } else {
        result = (
          <div
            key={index}
            dangerouslySetInnerHTML={{ __html: stringResponse }}
          />
        );
      }
  
      setFormattedMessage(formattedmessage => {
        const update = [...formattedmessage];
        update[index] = result;
        return update;
      });
  
      resolve(true);
    });
  };

  const insertCodeBlocks = (inputMessage) => {
    const codeIdentifierRegex = /```([\s\S]*?)```/g;
    const parts = inputMessage.split(codeIdentifierRegex);
    const output = parts.map((part, index) => {
      if (index % 2 === 0) {
        const stringResponse = message_formatting(part)
        return (
          <div
            key={index}
            dangerouslySetInnerHTML={{ __html: stringResponse }}
          />
        )
      }
      else {
        const languageIndex = part.indexOf("\n");
        const language =
          part.substring(0, languageIndex) === ""
            ? "markdown"
            : part.substring(0, languageIndex);
        const code = part.substring(languageIndex + 1, part.length);

        return <CodeBlock key={index} language={language} code={code} />;
      }
    })
    return output
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box data-testid={"message-paper-id"} sx={
      messageID === threadCxt.messageID ?
        messagePaperStyle : mainPaperStyle
    }>

      <Box sx={{ width: 'auto' }}>
        {/* Sender Identifier */}
        <BasePaper customStyles={senderIdentifierStyle}>
          {sender === "model" ? (
            <img src={botImage} style={{ width: "30px" }} alt="AI" />
          ) : profileImage !== "none" ? (
            <img
              src={profileImage}
              style={imageStyle}
              alt={getInitials(sender)}
            />
          ) : (
            getInitials(sender)
          )}
        </BasePaper>
      </Box>
      <Box sx={{ width: '80%' }}>
        {/* Message */}
        {isEditing && !isGeneratingResponse ? (
          <BaseTextField
            // autoFocus={true}
            value={textfieldValue}
            handleOnChange={handleTextFieldChange}
            size="small"
            isFullWidth={true}
            isMultiline={true}
            InputProps={{
              autoFocus: true,
              startAdornment: (
                <ButtonIcon handleSubmit={handleEditClose}>
                  {<CloseRoundedIcon style={iconStyle} />}
                </ButtonIcon>
              ),
              endAdornment: (
                <ButtonIcon handleSubmit={handleEditButtonSubmit}>
                  {<SendRoundedIcon style={iconStyle} />}
                </ButtonIcon>
              ),
            }}
          />
        ) : (
          <>
            <div>
              {content === '█' ? <div style={{
                color: preferencesCxt.preferenceList.theme === 1 ? 'black' : 'white',
                animation: "blink 0.50s linear infinite",
              }}
              >
                <style>
                  {`
                    @keyframes blink {
                      0% {
                        color: transparent;
                      }
                      33% {
                        color: transparent;
                      }
                      66% {
                        color: ${preferencesCxt.preferenceList.theme === 1 ? 'black' : 'white'},
                      }
                      100% {
                        color: ${preferencesCxt.preferenceList.theme === 1 ? 'black' : 'white'},
                      }
                    }
                  `}
                </style>
                █</div> : messageID === typingCxt.messageID ? formattedmessage : insertCodeBlocks(content)}
              {/* Button icons to display*/}
              {/* Sibling Identifier (if any) */}
              {siblings && siblings.length > 1 ? (
                <div style={centerDivElementsStyle}>
                  {sender === "model" ? (
                    <div style={{ ...centerDivElementsStyle }}>
                      <ButtonIcon
                        customStyles={{ ...customButtonStyle }}
                        handleSubmit={handleCopyIconClick}
                      >
                        {copyIcon}
                      </ButtonIcon>
                      <div style={timestampStyling}>{timestamp}</div>
                    </div>
                  ) : (
                    <div style={{ ...centerDivElementsStyle }}>
                      <ButtonIcon
                        customStyles={arrowsButtonIconStyle}
                        disabled={currentMessageIndex === 0} // disable left arrow when index=0
                        handleSubmit={handleLeftArrowClick}
                      >
                        <KeyboardArrowLeftRoundedIcon style={iconStyle} />
                      </ButtonIcon>
                      <span style={{ fontSize: "9pt" }}>
                        {currentMessageIndex + 1} / {siblings.length}
                      </span>
                      <ButtonIcon
                        customStyles={arrowsButtonIconStyle}
                        disabled={currentMessageIndex === siblings.length - 1} // disable right arrow when length of msg array reached
                        handleSubmit={handleRightArrowClick}
                      >
                        <KeyboardArrowRightRoundedIcon style={iconStyle} />
                      </ButtonIcon>
                      <ButtonIcon
                        customStyles={{ ...customButtonStyle }}
                        handleSubmit={handleEditClick}
                      >
                        <EditRoundedIcon style={iconStyle} />
                      </ButtonIcon>
                      <div style={timestampStyling}>{timestamp}</div>
                    </div>
                  )}

                </div>
              ) : (
                sender === "model" ? (
                  <div style={{ ...centerDivElementsStyle }}>
                    <ButtonIcon
                      customStyles={{ ...customButtonStyle }}
                      handleSubmit={handleCopyIconClick}
                    >
                      {copyIcon}
                    </ButtonIcon>
                    <div style={timestampStyling}>{timestamp}</div>
                  </div>
                ) : (
                  <div style={{ ...centerDivElementsStyle }}>
                    <ButtonIcon
                      customStyles={{ ...customButtonStyle }}
                      handleSubmit={handleEditClick}
                    >
                      <EditRoundedIcon style={iconStyle} />
                    </ButtonIcon>
                    <div style={timestampStyling}>{timestamp}</div>
                  </div>
                )
              )}
            </div>

          </>
        )}
        <div ref={textAreaRef} />
      </Box>
    </Box>
  );
};

export default Message;