import React,{ createContext, useState, useEffect, useContext } from "react";
import { MODEL_WORDS_TO_NUMERIC, SETTINGSLIST_DEFAULTS, THEME_WORDS_TO_NUMERIC, THEME_NUMERIC_TO_WORDS, MODEL_NUMERIC_TO_WORDS, EMBEDDING_MODEL_NUMERIC_TO_WORDS, CHUNKER_TYPE_NUMERIC_TO_WORDS, EMBEDDING_MODEL_WORDS_TO_NUMERIC, CHUNKER_TYPE_WORDS_TO_NUMERIC } from "../config/constants";
import postSettings from "../api/postSettings";
import getThreadPreferences from "../api/getThreadPreferences";
import putThreadPreferences from "../api/putThreadPreferences";
import ThreadContext from "./ThreadContext";
import TokenContext from "./TokenContext";
import { OperationalModeContext } from "./OperationalModeContext";

const ThreadPreferenceContext = createContext({
    preferenceList: {},
    updatePreferences: (preferences) => { },
    savePreferences: (newPreferenceList, token) => { },
    getPreferences: (token) => {}
})
export const ThreadPreferenceContextProvider = (props) => {
    const initialPreferences = {
        model: 1,
        stop: "",
        modelPersona: "",
        userBackground: "",
        topP: SETTINGSLIST_DEFAULTS.topP,
        temperature: SETTINGSLIST_DEFAULTS.temperature,
        frequencyPenalty: SETTINGSLIST_DEFAULTS.frequencyPenalty,
        presencePenalty: SETTINGSLIST_DEFAULTS.presencePenalty,
        maxTokens: SETTINGSLIST_DEFAULTS.maxTokensValue,
        threadPreferenceFlag: SETTINGSLIST_DEFAULTS.threadPreferenceFlag,
        selectedDocuments: "",
        // embeddingModel: SETTINGSLIST_DEFAULTS.embeddingModel,
        // chunkerType: SETTINGSLIST_DEFAULTS.chunkerType,

    }

    const threadCxt = useContext(ThreadContext);
    const tokenCxt = useContext(TokenContext);
    const { fetchSelectedDocuments } = useContext(OperationalModeContext);

    const [preferenceList, setPreferenceList] = useState(initialPreferences);

    useEffect(()=>{
        const preFetchThreadPreferences = async () => {
            getPreferences(tokenCxt.token).then((fetchedPreferences) => {
                if (!!fetchedPreferences) {
                    const response = updatePreferences(convertFetchedThreadPreferences(fetchedPreferences));

                    if (!response) {
                        console.error("Error updating preferences");
                    }else{
                        fetchSelectedDocuments(response);
                    }
                }
            }).catch((error)=>{
                console.error("Error fetching thread preferences: ", error);
            });
        }

        !!threadCxt.threadID && preFetchThreadPreferences();
    }, [threadCxt.threadID])

    const getPreferences = async (token) => {
        if (!threadCxt.threadID) {
            console.log("No thread ID found");
            return false;
        }
        try {
            const response = await getThreadPreferences(token, threadCxt.threadID);

            if (response.status === 200) {
                return response.data;
            }
            return {};
        }catch(error) {
            console.error("Error fetching preferences from db: ", error)
            return false;
        }
    }

    const updatePreferences = (fetchedPreferences) => {
        if (!threadCxt.threadID) {
            console.log("No thread ID found");
            return;
        }
        Object.keys(fetchedPreferences).forEach((key) => {
            if (fetchedPreferences[key] === NaN || fetchedPreferences[key] === undefined) {
                fetchedPreferences[key] = initialPreferences[key]
            }
        })
        const updatedPreferences = { ...preferenceList, ...fetchedPreferences }
        setPreferenceList((prev) =>({...prev, ...updatedPreferences}));

        return updatedPreferences
    }

    const savePreferences = async (newPreferenceList, token) => {
        if (!threadCxt.threadID) {
            console.log("No thread ID found");
            return false;
        }
        try {
            const preferencePayload = {...newPreferenceList};
            preferencePayload["theme"] = typeof preferencePayload["theme"] == "number" ? THEME_NUMERIC_TO_WORDS[preferencePayload["theme"]] : preferencePayload["theme"];
            preferencePayload["model"] = typeof preferencePayload["model"] == "number" ? MODEL_NUMERIC_TO_WORDS[preferencePayload["model"]] : preferencePayload["model"];
            // preferencePayload["embeddingModel"] = typeof preferencePayload["embeddingModel"] == "number" ? EMBEDDING_MODEL_NUMERIC_TO_WORDS[preferencePayload["embeddingModel"]] : preferencePayload["embeddingModel"];
            // preferencePayload["chunkerType"] = typeof preferencePayload["chunkerType"] == "number" ? CHUNKER_TYPE_NUMERIC_TO_WORDS[preferencePayload["chunkerType"]] : preferencePayload["chunkerType"];
            const response = await putThreadPreferences(token, threadCxt.threadID, preferencePayload);
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            console.log(error);
            console.error("Error saving preferences to db")
        }
        return false;
    }

    const convertFetchedThreadPreferences = (fetchedPreferences) => {
        if (fetchedPreferences && Object.keys(fetchedPreferences).length > 0) {
          fetchedPreferences["model"] =
            typeof fetchedPreferences["model"] == "number"
              ? fetchedPreferences["model"]
              : MODEL_WORDS_TO_NUMERIC[fetchedPreferences["model"]];
          fetchedPreferences["threadPreferenceFlag"] = fetchedPreferences["threadPreferenceFlag"] == "1" ? true : (fetchedPreferences["threadPreferenceFlag"] == "0" ? false : false);
            fetchedPreferences["theme"] =
                typeof fetchedPreferences["theme"] == "number"
                ? fetchedPreferences["theme"]
                : THEME_WORDS_TO_NUMERIC[fetchedPreferences["theme"]];
            // fetchedPreferences["embeddingModel"] =
            //     typeof fetchedPreferences["embeddingModel"] == "number"
            //     ? fetchedPreferences["embeddingModel"]
            //     : EMBEDDING_MODEL_WORDS_TO_NUMERIC[fetchedPreferences["embeddingModel"]];
            // fetchedPreferences["chunkerType"] =
            //     typeof fetchedPreferences["chunkerType"] == "number"
            //     ? fetchedPreferences["chunkerType"]
            //     : CHUNKER_TYPE_WORDS_TO_NUMERIC[fetchedPreferences["chunkerType"]];
          Object.keys(fetchedPreferences).forEach((key) => {
            if (typeof fetchedPreferences[key] != "number" && key != "stop" && key != "modelPersona" && key != "userBackground" && key != "selectedDocuments" && key != "documentMode") {
              fetchedPreferences[key] = Number(fetchedPreferences[key]);
            }
          });
          return fetchedPreferences;
        }
      }

    const contextValue = {
        preferenceList,
        updatePreferences,
        savePreferences,
        getPreferences
    }

    return <ThreadPreferenceContext.Provider value={contextValue}>{props.children}</ThreadPreferenceContext.Provider>
}

export default ThreadPreferenceContext;