import * as React from 'react';

// MUI components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


/**
  * Base Alert component.
  *
  * @param {string} severity: The severity of the alert. This defines the color and icon used. Possible values are "error", "warning", "info", or "success". Defaults to "success"
  * @param {JSX.Element} action: Adds an action element (e.g., a button) to the alert.
  * @param {JSX.Element} children: Any child React elements to be rendered within the button
  * @param {JSX.Element} icon: Overrides the default icon for the alert.
  * @param {string} variant: Determines the visual variant of the alert. Possible values are "standard" or "filled".
  * @param {integer} elevation: Sets the elevation level of the alert component. Default is 0.
  * @param {string|JSX.Element} closeText: Sets the text or element for the close button of the alert.
  * @param {string} color: Specifies a custom color for the alert using a valid CSS color value. Possible values: 'error', 'info', 'success', 'warning'
  * @param {string} role: Sets the ARIA role of the alert element.
  * @param {string} closeText: Override the default label for the close popup icon button.
  * @param {object} customStyles: Custom styles applied to the alert component using the sx prop.
  * @param {function} handleOnClose: Specifies the callback function to be executed when the alert is closed.
  * @returns {JSX.Element} The rendered Alert component.
*/

export default function BaseAlert({ severity="success",
                                    action,
                                    icon,
                                    variant,
                                    elevation,
                                    closeText="Close",
                                    color,
                                    role='alert',
                                    customStyles,
                                    handleOnClose, 
                                    children,
                                    alertTitle
                                    }) {

  return (
    <Alert
      severity={severity}
      action={action}
      icon={icon}
      variant={variant}
      elevation={elevation}
      closeText={closeText}
      color={color}
      role={role}
      sx={customStyles}
      onClose={handleOnClose}
    >
      <AlertTitle>{alertTitle}</AlertTitle>
      {children}
    </Alert>
  );
}