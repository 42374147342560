import flask from "./flask";
function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const parseMessages = (raw_messages) => {
  const messages = raw_messages.map(message => {
    if (message.sender === "14cf5291-f99d-11ed-869b-da824ad4ff2e") {
      let content = JSON.parse(message.content)
      while (isJson(content["message"]["content"])) {
        content = JSON.parse(content["message"]["content"])
      }
      content = content["message"]["content"]
      return {
        messageID: message.message_id,
        sender: "ChatBot",
        content: content,
        thread_name: message.thread_name,
        created_at: message.created_at,
        threadID: message.thread_id
      }
    }
    else {
      return {
        messageID: message.message_id,
        sender: "You",
        content: message.content,
        thread_name: message.thread_name,
        created_at: message.created_at,
        threadID: message.thread_id
      }
    }
  })
  return messages;
}

const getSearchResults = async (payload, token) => {
  try {
    // payload: object type consisting of search query and option(current thread/all threads)
    // search/message3/current_thread
    const response = await flask.get(`search/${payload.text}/${payload.options}/${!!payload.thread_id ? payload.thread_id : 'None'}`,{
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });
    const messages = parseMessages(response.data)
    return {
      status: response.status, 
      data: messages
    };
  }
  catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      code: "",
    };
  }
};

export default getSearchResults;