import React, { useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Collapse,
    Paper,
    Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const MetricCard = ({ title, value, details }) => {
    const [open, setOpen] = useState(false);

    const handleExpandClick = () => {
        setOpen(!open);
    };

    return (
        <Card
            sx={{
                background:
                    "linear-gradient(to right, rgba(63, 81, 181, 1),rgba(33, 150, 243, 1))",
                color: "white",
                borderRadius: "10px",
                boxShadow: "0 5px 15px rgba(0,0,0,0.6), 0 0 20px rgba(0,0,0,0.2)",
                width: "200px", // Adjust the width as needed
                height: "90px", 
            }}
        >
            <CardContent sx={{ padding: "16px" }}>
                <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mt: 0.5 }}
                >
                    {title}
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box >

                        <Typography
                            variant="h4"
                            sx={{ fontWeight: "600" }}
                        >
                            {value}
                        </Typography>
                    </Box>

                    {details && (
                        <IconButton
                            aria-label="expand"
                            sx={{ color: "white", transition: "0.5s", mt: 1.5 }}
                            onClick={handleExpandClick}
                        >
                            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                </Box>
            </CardContent>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <CardContent sx={{ padding: "16px", paddingTop: "0px" }}>
                    <Paper
                        elevation={0}
                        sx={{ p: 2, bgcolor: "grey.800", color: "white", mt: 2 }}
                    >
                        <Typography
                            paragraph
                            variant="body2"
                            sx={{
                                fontWeight: "400",

                                fontSize: "0.7rem",
                            }}
                            component="div"
                        >
                            {details}
                        </Typography>
                    </Paper>
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default MetricCard;
