import * as React from 'react';
import Drawer from '@mui/material/Drawer';
/**
  * Base drawer component.
  * @param {string} anchorValue: Side from which the drawer will appear.
  * @param {node} children: The content of the component.
  * @param {object} classes: Override or extend the styles applied to the component.
  * @param {integer} elevation: The elevation of the drawer.
  * @param {boolean} hideBackdrop: If true, the backdrop is not rendered.
  * @param {object} ModalProps: Props applied to the Modal element.
  * @param {func} handleOnClose: Callback fired when the component requests to be closed.
  * @param {boolean} isOpen: If true, the component is shown.
  * @param {object} PaperProps: Props applied to the Paper element.
  * @param {object} SlideProps: Props applied to the Slide element.
  * @param {Array<func|object|bool>|func|object} customStyles: The system prop that allows defining system overrides as well as additional CSS styles.
  * @param {integer} transitionDuration: The duration for the transition, in milliseconds. You may specify a single timeout for all transitions, or individually with an object.
  * @param {string} variant	: The variant to use.
  * @returns {JSX.Element} The rendered drawer component.
*/

export default function BaseDrawer({    anchorValue = 'left',
                                        children,
                                        classes,
                                        elevation = 16,
                                        hideBackdrop = false,
                                        id,
                                        handleOnClose,
                                        isOpen = false,
                                        PaperProps = {},
                                        SlideProps,
                                        customStyles = {},
                                        transitionDuration,
                                        variant='temporary',
                                    })
    {
    return (
      <Drawer
        anchor={anchorValue}
        classes = {classes}
        data-testid={id}
        elevation = {elevation}
        hideBackdrop = {hideBackdrop}
        id={id}
        onClose= {handleOnClose}
        open={isOpen}
        PaperProps={PaperProps}
        SlideProps={SlideProps}
        sx={customStyles}
        transitionDuration={transitionDuration}
        variant = {variant}
        >
          {children}
        </Drawer>
    );
  }