import React, { useState, useEffect, useContext, Fragment } from "react";
import { Dialog, IconButton, Slide, Typography, Box, useTheme, Grid, Paper, Tabs, Tab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';import MetricCard from './MetricCard';
import TokenContext from "../../context/TokenContext";
import getAnalyticsData from "../../api/getAnalyticsData";
import KpiCard from "./KpiCard";
import Toolbar from '@mui/material/Toolbar';
import UserAnalyticsDashboard from "../AnalyticsDashboard/UserAnalyticsDashboard";
import OverallAnalyticsDashboard from "./OverallAnalyticsDashboard";

import HierarchyUsageMetricDashboard from "../HierarchyUsageMetricDashboard/HierarchyUsageMetricDashboard";
import getHierarchyUsageMetrics from "../../api/getHierarchyUsageMetrics";


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);


const AnalyticsDashboard = ({ isOpen, onClose }) => {
    const theme = useTheme();
    const { token } = useContext(TokenContext);
    const [analyticsData, setAnalyticsData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [hierarchyData, setHierarchyData] = useState([]);
    
    // Handle tab change
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };
    
    // Get total users
    const totalUsers = (data) => {
        if (!data || data.length === 0) {
            return 0;
        }
        const mostRecentData = data[data.length - 1];
        return parseInt(mostRecentData.cumulative_user_count, 10);
    };

    useEffect(() => {
        let isCancelled = false;

        const fetchData = async () => {
            if (!isOpen || !token) {
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const response = await getAnalyticsData(token);

                if (!isCancelled) {
                    if (response.status === 200) {
                        setAnalyticsData(response.data);
                        setHierarchyData(response.data["hierarchy_data"])
                    } else {
                        setError("Failed to fetch data");
                    }
                }
                
            } catch (e) {
                if (!isCancelled) {
                    setError(e.message || "An error occurred while fetching data");
                }
            } finally {
                if (!isCancelled) {
                    setLoading(false);
                }
            }
        };
        fetchData();
        return () => {
            isCancelled = true;
        };
    }, [isOpen, token]);

    // Styles
    const styles = {
        paper: (theme) => ({
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            paddingTop: theme.spacing(2),
        }),
        iconButton: (theme) => ({
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(2),
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '50%',
            boxShadow: theme.shadows[5],
            zIndex: 2
        }),
        box: (theme) => ({
            marginTop: theme.spacing(4),
            width: '100%',
            textAlign: 'center',
            padding: theme.spacing(4)
        }),
        header: {
            fontWeight: 600,
            marginTop: theme.spacing(4)
        },
        subtitle: {
            marginBottom: theme.spacing(2)
        }
       
    };
    
    return (
        <Dialog
            fullScreen
            open={isOpen}
            onClose={onClose}
            TransitionComponent={Transition}
            data-testid={"analytics-id"}
            sx={{marginTop: "10px"}}
        >
            <IconButton
                edge="start"
                onClick={onClose}
                aria-label="close"
                style={styles.iconButton(theme)}
            >
                <CloseIcon />
            </IconButton>
            <Toolbar style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                
                <div>
                    <Typography variant="h5" gutterBottom style={styles.header}>
                        RiskGPT Usage Metrics
                    </Typography>
                </div>
                <div>
                    <Typography variant="body1" style={styles.subtitle}>
                        This dashboard provides an overview of RiskGPT user engagement and usage patterns.
                    </Typography>
                </div>
            </Toolbar>
            <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor={theme.palette.text.primary}
                >
                    <Tab label="Overall Analysis" />
                    <Tab label="User Specific Analysis" />
                    <Tab label="Hierarchy Usage Metrics" />
            </Tabs>

            <div>
                {loading && <div>Loading...</div>}
                {error && <div>Error: {error}</div>}
                {/* Overall analytics dashboard */}
                {!loading && !error && analyticsData && tabValue === 0 && (
                    <>
                        <Grid container spacing={2} style={{ padding: theme.spacing(2) }}>
                            <Grid item xs={12} sm={6} md={3}>
                                <KpiCard title="Users" value={(analyticsData ? totalUsers(analyticsData.running_sum_data) : 0).toString()} />
                            </Grid>
                            <Grid item xs={6} md={3} style={{ marginLeft: '-100px' }}>
                                <KpiCard title="User Queries" value={(analyticsData ? analyticsData.total_user_query_count[0].total_user_query_count : 0).toString()} />
                            </Grid>

                        </Grid>
                        <OverallAnalyticsDashboard analyticsData={analyticsData}/>
                    </>
                )}
            </div>
            {/** User specific analytics dashboard*/}
            {tabValue === 1 && !loading && !error && (<UserAnalyticsDashboard analyticsData={analyticsData}/>)}

            {tabValue === 2 && !loading && !error && (<HierarchyUsageMetricDashboard data={hierarchyData} />)}
        </Dialog>
    );
};
export default AnalyticsDashboard;
