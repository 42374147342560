import flask from "./flask";

const deleteDocument = async (token, doc_name) => {
  try {
    const response = await flask.delete(`document/${doc_name}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return response;
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default deleteDocument;
