import flask from "./flask";

// Get releases from the backend
const getReleases = async (token) => {
  try {
    const response = await flask.get('release', {
      "headers": {
        "Authorization": `Bearer ${token}`
      }
    });

    return {"status": response.status, "data": response.data};
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default getReleases;