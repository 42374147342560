import { useMemo, useEffect, useContext, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useTheme } from '@emotion/react';


const HierarchyUsageMetricDashboard = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "first_name",
        header: "First Name",
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "query_count",
        header: "Total Queries By Org",
        filterFn: "between",
        columnFilterModeOptions: ['between', 'lessThan', 'greaterThan'],
      },
      {
        accessorKey: "self_query_count",
        header: "Individual Queries",
        filterFn: "between",
        columnFilterModeOptions: ['between', 'lessThan', 'greaterThan'],
      },
      {
        accessorKey: "user_count",
        header: "Total Users By Org",
        filterFn: "between",
        columnFilterModeOptions: ['between', 'lessThan', 'greaterThan'],
      }
    ],
    [],
  );

  // const [data, setData] = useState([]);

  const theme = useTheme();

  // useEffect(() => {
  //   const fetchData = async () => {
  //       const response = await getHierarchyUsageMetrics(tokenCxt.token);

  //       if (response.status === 200) {
  //         setData(response.data);
  //       } else {
  //         console.log("Failed to fetch data")
  //       }
  //   }

  //   fetchData();
  // }, [])

  const table = useMaterialReactTable({
    columns,
    data,
    displayColumnDefOptions:{
      "mrt-row-expand": {
        enableResizing: true,
      }
    },
    enableExpandAll: true, //hide expand all double arrow in column header
    enableExpanding: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    initialState: {
      density: "compact",
      expanded: false,
      pagination: {pageIndex: 0, pageSize: 15}
    },
    filterFromLeafRows: true,
    muiToolbarAlertBannerChipsPros: {color: theme.palette.primary.main},
    muiTableContainerProps: {sx: {height: "65vh" , backgroundColor: theme.palette.background.paper}},
    positionToolbarAlertBanner: "top",
    enableFacetedValues: true,
    enableFilterMatchHighlighting: true,
    enableColumnPinning: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    paginationDisplayMode: "pages",
    paginateExpandedRows: true,
    muiTableHeadCellProps: ({column}) => ({
      sx: {
        "& .MuiTableSortLabel-root svg": {
          color: column.getIsSorted() ? theme.palette.text.secondary: undefined
        },
        color: theme.palette.text.primary
      }
    }),
    padding: "40px",
    enableStickyHeader: true,
    enableStickyFooter: true
  });

  return <MaterialReactTable table={table} />;
};

export default HierarchyUsageMetricDashboard;
