import React from "react";
import { Box, Grid, Typography, MenuItem } from "@mui/material";
import BaseSelect from "../../base_components/BaseSelect";
import BaseTooltip from "../../base_components/BaseTooltip";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const SettingChunkerTypeDropdown = ({ name, value, onChange, tooltipContent }) => {
    const dropdownStyling = { width: '100%' };
    const CustomIconStyle = { fontSize: 18, marginLeft: 1 };

    return (
        <Box sx={{ maxWidth: "90%", p: 2, mx: "auto" }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} lg={3}>
                    <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
                        Chunker Type
                        <BaseTooltip
                            title={
                                <ul>
                                    {tooltipContent.map((tooltip, index) => (
                                        <li key={index}>{tooltip}</li>
                                    ))}
                                </ul>
                            }
                            isArrow
                        >
                            <HelpOutlineRoundedIcon sx={CustomIconStyle} />
                        </BaseTooltip>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <BaseSelect
                        value={value}
                        label="Chunker_Type"
                        handleOnChange={onChange}
                        customStyles={dropdownStyling}
                        name={name}
                    >
                        <MenuItem value={1}>Code Chunker</MenuItem>
                        <MenuItem value={2}>JSON Chunker</MenuItem>
                        <MenuItem value={3}>Semantic Chunker</MenuItem>
                        <MenuItem value={4}>Text Chunker</MenuItem>
                    </BaseSelect>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettingChunkerTypeDropdown;
