import React from "react";
import { Box, Grid, Typography, MenuItem } from "@mui/material";
import BaseSelect from "../../base_components/BaseSelect";

const SettingEmbeddingModelDropdown = ({ name, value, onChange }) => {
    const dropdownStyling = { width: '100%' };

    return (
        <Box sx={{ maxWidth: "90%", p: 2, mx: "auto" }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} lg={3}>
                    <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
                        Embedding Model
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <BaseSelect
                        value={value}
                        label="Embedding_Model"
                        handleOnChange={onChange}
                        customStyles={dropdownStyling}
                        name={name}
                    >
                        <MenuItem value={1}>Text Embedding ADA v2</MenuItem>
                        <MenuItem value={2}>Text Embedding v3 Small</MenuItem>
                        <MenuItem value={3}>Text Embedding v3 Medium</MenuItem>
                        <MenuItem value={4}>Text Embedding v3 Large</MenuItem>
                    </BaseSelect>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettingEmbeddingModelDropdown;
