import React, { useState, useContext, useRef, useEffect } from "react";

// Base Components
import BaseTextField from "../../base_components/BaseTextField";
import BaseTooltip from "../../base_components/BaseTooltip";
import ButtonIcon from "../../base_components/ButtonIcon";

import YoutubeSearchedForRoundedIcon from "@mui/icons-material/YoutubeSearchedForRounded";
// Custom Components
import SearchResultsList from "../SearchResultsList/SearchResultsList";

// Mui Components
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// Mui Icons
import SearchIcon from "@mui/icons-material/Search";
import RotateRightIcon from "@mui/icons-material/RotateRight";

// Constants
import { SEARCH_OPTIONS } from "../../config/constants";

//contexts
import { AlertContext } from "../../context/AlertContext";
import SearchResultContext from "../../context/SearchResultContext";
import TokenContext from "../../context/TokenContext";
import ThreadContext from "../../context/ThreadContext";
import PreferenceContext from "../../context/PreferenceContext";

//api
import getSearchResults from "../../api/getSearchResults";
import shadows from "@mui/material/styles/shadows";

const SearchBar = () => {
  //State variables
  const [searchQueryText, setSearchQueryText] = useState("");
  const [lastSearchQueryText, setLastSearchQueryText] = useState("");
  const [searchOption, setSearchOption] = useState("all_threads"); //defaults search to current thread
  const [openSearchResultsModal, setOpenSearchResultsModal] = useState(false);
  const [searchResultsList, setSearchResultsList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [viewMostRecentSearchResults, setViewMostRecentSearchResults] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const searchResultsCxt = useContext(SearchResultContext);
  const preferenceCxt = useContext(PreferenceContext);
  const preferredTheme = preferenceCxt.preferenceList.theme;
  //Custom styles
  const IconStyle = { color: "#FFFFFF" };
  const CustomToggleButtonStyle = {
    height: 30,
    width: "auto",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "linear-gradient(to right, #12c2e9, #c471ed, #f64f59)",
    color: "#fff",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#202123",
    },
  };


  const SearchIconStyle = {
    marginRight: "5px",
    color: "#D9D9E3",
    transition: "color 0.3s ease",
  };

  const searchBarStyle = {
    width: isExpanded ? "90%" : "50%",
    transition: "width 0.25s ease-out",
    "&:hover $SearchIconStyle": {
      color: "#fff",
    },
    "&.Mui-focused $SearchIconStyle": {
      color: "#fff",
    },
  };

  const customSearchBarTextFieldStyle = {
    backgroundColor:  "#1E2934",
    shadows: "none",
  };

  const tokenCxt = useContext(TokenContext);
  const threadCxt = useContext(ThreadContext);
  const searchBarRef = useRef(null);
  useEffect(() => {
    //if user clicks outside the search bar component, then the search bar will shrink to original size
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // if there are updates to the thread list, then we need to check if any threads were deleted and if the search results list belonged to any deleted threads
    // P.S. this useEffect fires if a new thread was added or and existing one was deleted

    const threadList = threadCxt.threadList;

    if (searchResultsList.length > 0) {
      const idsInThreadList = threadList.map(thread => thread.thread_id);
      const searchIDsToRemove = searchResultsList.filter(searchResult => !idsInThreadList.includes(searchResult.threadID)).map(searchResult => searchResult.threadID);
      const updatedSearchResultsList = searchResultsList.filter(searchResult => !searchIDsToRemove.includes(searchResult.threadID));
      setSearchResultsList(updatedSearchResultsList);
      searchResultsCxt.setSearchResult(updatedSearchResultsList);
    }
  }, [threadCxt.threadList]);

  //Handlers
  const handleOnTextChange = (e) => {
    setSearchQueryText(e.target.value);
  };

  const handleOnEnter = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {

      // These characters are not whitelisted on the backend so using them individually will produce inaccurate search results. They don't make sense to be used individually anyway.
      const regex = /^[\+!@#$%^&*+:;'",/?\\\[\]|=`~]$/;
      if (regex.test(searchQueryText.trim())) {
        showAlert(
          "Sorry, these characters are on a timeout from being used solo: + ! @ # $ % ^ & * + : ; ' \" , / ? \ [ ] | = ` ~",
          "error",
          7000
        );
        console.error("Entering these characters individually is not allowed.: + ! @ # $ % ^ & * + : ; ' \" , / ? \ [ ] | = ` ~ ");
        return;
      }

      //post search query to backend
      let payload = {
        //encode values like # to %23 so API request does not error out, and double encode values like / and \
        text: encodeURIComponent(searchQueryText)?.replace('%2F', '%252F').replace('%5C', '%255C'),
        options: searchOption,
        thread_id: threadCxt.threadID,
      };
      setLastSearchQueryText(searchQueryText);
      setViewMostRecentSearchResults(false);
      try {
        const response = await getSearchResults(payload, tokenCxt.token); //wait until API call is completed to proceed
        if (response && response.status === 200) {
          setSearchResultsList(response.data);
          searchResultsCxt.setSearchResult(response.data);
          setOpenSearchResultsModal(true);
        }
      } catch (error) {
        showAlert(
          "Error while fetching search results from the backend",
          "error"
        );
        console.error("Error while fetching search results from the backend");
      }
    }
  };

  const handleToggleChange = (e) => {
    setSearchOption(e.target.value);
  };

  const handleSearchResultsModalClose = () => {
    setOpenSearchResultsModal(false);
  };

  const inputStyles = {
    color: "#E5E5E5", // Set the desired color
  };

  const handleOnClick = () => {
    setIsExpanded(true);
  };
  const iconAdornment = isExpanded
    ? <div className="searchToggleButtons">
      <ToggleButtonGroup
        value={searchOption}
        exclusive
        onChange={handleToggleChange}
      >
        <ToggleButton
          value={"current_thread"}
          sx={CustomToggleButtonStyle}
        >
          Current
        </ToggleButton>
        <ToggleButton
          value={"all_threads"}
          sx={CustomToggleButtonStyle}
        >
          All
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
    : <></>;
  return (
    <>
      <div style={searchBarStyle} ref={searchBarRef} className="searchBar">
        <BaseTextField
          placeholder={"Search..."}
          value={searchQueryText}
          handleOnChange={handleOnTextChange}
          handleOnKeyDown={handleOnEnter}
          handleOnClick={handleOnClick}
          isFullWidth={true}
          InputProps={{
            startAdornment: <SearchIcon sx={SearchIconStyle} />,
            endAdornment: iconAdornment,
            sx: inputStyles,
          }}
          size="small"
          id="search-bar-id"
          customStyles={customSearchBarTextFieldStyle}
        />

      </div>
      <ButtonIcon
        size="large"
        aria-label="searchresults"
        color="inherit"
        handleSubmit={() => {
          setSearchQueryText(lastSearchQueryText)
          setOpenSearchResultsModal(true);
          setViewMostRecentSearchResults(true);
        }}
      >
        <BaseTooltip title="View Most Recent Search Result" isArrow>
          <YoutubeSearchedForRoundedIcon sx={IconStyle} />
        </BaseTooltip>
      </ButtonIcon>
      <SearchResultsList
        open={openSearchResultsModal}
        handleClose={handleSearchResultsModalClose}
        searchQueryText={searchQueryText}
        searchResultsList={searchResultsList}
        viewMostRecentSearchResults={viewMostRecentSearchResults}
      />
    </>
  );
};
export default SearchBar;
