import React, { useContext, useState, useEffect } from "react";

// MUI Icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

// MUI Components
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

// base components
import BaseDialog from "../../base_components/BaseDialog";
import BaseTooltip from "../../base_components/BaseTooltip";
import ButtonIcon from "../../base_components/ButtonIcon";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import GitHubIcon from '@mui/icons-material/GitHub';


// contexts
import { AlertContext } from "../../context/AlertContext";
import PreferenceContext from "../../context/PreferenceContext";
import TokenContext from "../../context/TokenContext";
import ThreadContext from "../../context/ThreadContext";

// themes
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import highContrastTheme from "../../themes/highContrastTheme";

import UploadFile from "./UploadFile";
import UploadText from "./UploadText";
import UploadJira from "./UploadJira";
import UploadConfluence from "./UploadConfluence";
import UploadGithub from "./UploadGithub";

import { ReactComponent as JiraIcon } from '../../assets/jira.svg';
import { ReactComponent as ConfluenceIcon } from '../../assets/confluence.svg';
import { ReactComponent as JiraDarkIcon } from '../../assets/jira_dark.svg';
import { ReactComponent as ConfluenceDarkIcon } from '../../assets/confluence_dark.svg';

import { CHUNKER_TYPE_NUMERIC_TO_READABLE_WORDS, EMBEDDING_MODELS_NUMERIC_TO_READABLE_WORDS } from "../../config/constants";

// TODO:
// 1. add drop zone for file upload to tabs 1, 3
// 2. data validation --DONE
// 3. Add toggle buttons to tab 3 and 2nd toggle contents there. --DONE
// 4. add dialog actions i.e., Save/Upload buttons --DONE
// 5. Handle button click on dialog actions based on the tab selected -- DONE
// 6. Handle theme-ing

const UploadResources = ({ open, close, stagingThreadPreferences }) => {
  // styling
  const preferenceCxt = useContext(PreferenceContext);
  const preferredTheme = preferenceCxt.preferenceList.theme;
  const threadCxt = useContext(ThreadContext);

  useEffect(() => {
  }, [threadCxt, stagingThreadPreferences, preferenceCxt]);

  // stagingThreadPreferences
  const chunkerType =  !!threadCxt.threadID ? CHUNKER_TYPE_NUMERIC_TO_READABLE_WORDS[preferenceCxt.preferenceList.chunkerType] : CHUNKER_TYPE_NUMERIC_TO_READABLE_WORDS[stagingThreadPreferences.chunkerType];
  const embeddingModel =  !!threadCxt.threadID ? EMBEDDING_MODELS_NUMERIC_TO_READABLE_WORDS[preferenceCxt.preferenceList.embeddingModel] : EMBEDDING_MODELS_NUMERIC_TO_READABLE_WORDS[stagingThreadPreferences.embeddingModel];

  const backgroundColor = {
    1: lightTheme.palette.background.paper,
    2: darkTheme.palette.background.paper,
    3: highContrastTheme.palette.background.paper,
  };

  const TabPaperStyle = {
    p: 1,
    m: 0,
    backgroundColor: backgroundColor[preferredTheme],
    width: "100%",
  };

  const TextFieldStyle = { marginBottom: "5px", marginTop: "15px" };

  const ToggleBackgroundColor = {
    1: lightTheme.palette.primary.main,
    2: darkTheme.palette.background.paper,
    3: highContrastTheme.palette.background.paper,
  };

  const hexToRgb = (hex) => {
    let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  };
  let opacity = 0.9;
  let rgb = hexToRgb(ToggleBackgroundColor[preferredTheme]);
  let rgbaColor = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;

  const CustomToggleButtonStyle = {
    height: 30,
    width: "auto",
    borderRadius: "5px",
    border: "none",
    backgroundColor: ToggleBackgroundColor[preferredTheme],
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: rgbaColor,
      border: "none",
    },
  };

  const getTabStyles = (theme) => {
    switch (theme) {
      case 2:
        return {
          color: '#fff',
          '&.Mui-selected': {
            color: '#3CE2B3'
          },
        };
      case 1:
      default:
        return {
          color: '#000',
          '&.Mui-selected': {
            color: '#3CE2B3'
          }
        };
    }
  };

  // state variables
  const { showAlert } = useContext(AlertContext);
  const [value, setValue] = useState(1);
  const [newDocumentTitle, setNewDocumentTitle] = useState("");
  const [
    newDocumentTitleValidationResult,
    setNewDocumentTitleValidationResult,
  ] = useState(null);
  const [newDocumentBody, setNewDocumentBody] = useState("");
  const [newDocumentBodyValidationResult, setNewDocumentBodyValidationResult] =
    useState(null);
  const [gitHubUploadType, setGithubUploadType] = useState("link");
  const [repositoryURL, setRepositoryURL] = useState("");
  const [repositoryURLValidationResult, setRepositoryURLValidationResult] =
    useState(null);
  const [branchName, setBranchName] = useState("main");
  const [branchNameValidationResult, setBranchNameValidationResult] =
    useState(null);
  const [personalAccessToken, setPersonalAccessToken] = useState("");
  const [repofolders, setRepoFolders] = useState("");
  const [filetypes, setFileTypes] = useState("");
  const [patValidationResult, setPatValidationResult] = useState(null);
  const [websiteURL, setWebsiteURL] = useState("");
  const [websiteURLValidationResult, setWebsiteURLValidationResult] =
    useState(null);

  const CloseModalButton = ({ onClick }) => {
    return (
      <ButtonIcon handleSubmit={onClick}>
        <BaseTooltip title="Close Modal" isArrow>
          <CloseRoundedIcon />
        </BaseTooltip>
      </ButtonIcon>
    );
  };

  // ************************************** Upload Resources Dialog **************************************
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // content to be shown in the header of the dialog
  const dialogHeader = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 4,
        ml: 4,
        width: '100%',
        height: '50px'
      }}
    >
      <Typography variant="h5" component="div" sx={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
        Upload Resources
      </Typography>
      <CloseModalButton onClick={close} />
    </Box>
  );

  // content for each of the four tabs
  const tab1Content = (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={0}>
      <UploadFile currentChunkerType={chunkerType} currentEmbeddingModel={embeddingModel} />
    </Box>
  );

  const tab2Content = (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={0}>
      < UploadText currentChunkerType={chunkerType} currentEmbeddingModel={embeddingModel} />
    </Box>
  );

  // const tab3Content = (

  //   <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={0}>
  //     < UploadJira currentChunkerType={chunkerType} currentEmbeddingModel={embeddingModel} />
  //   </Box>
  // );

  // const tab4Content = (
  //   <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={0}>
  //     < UploadConfluence currentChunkerType={chunkerType} currentEmbeddingModel={embeddingModel} />
  //   </Box>
  // );

  // const tab5Content = (
  //   <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={0}>
  //     <UploadGithub />
  //   </Box>
  // );


  // content to be shown in the body of the dialog
  const dialogContent = (
    <>
      <Box sx={{ zIndex: 1, }}>
        <Box sx={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: 'background.paper', borderBottom: '1px solid', borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} centered sx={{ borderBottom: 1, borderColor: 'divider' }} >
            <Tab
              value={1}
              icon={<AttachFileIcon />}
              label="File Upload"
              sx={getTabStyles(preferredTheme)}
            />
            <Tab
              value={2}
              icon={<TextFieldsIcon />}
              label="Text Upload"
              sx={getTabStyles(preferredTheme)}
            />
            {/* <Tab
              value={3}
              icon={preferredTheme === 2 ? <JiraIcon /> : <JiraDarkIcon />}
              label="JIRA"
              sx={getTabStyles(preferredTheme)}
            />
            <Tab
              value={4}
              icon={preferredTheme === 2 ? <ConfluenceIcon /> : <ConfluenceDarkIcon />}
              label="Confluence"
              sx={getTabStyles(preferredTheme)}
            /> */}
            {/* <Tab
              value={5}
              icon={<GitHubIcon />}
              label="GitHub"
              sx={getTabStyles(preferredTheme)}

            /> */}
          </Tabs>
        </Box>
        <Box sx={{ p: 4 }}>
          {value === 1 && tab1Content}
          {value === 2 && tab2Content}
          {/* {value === 3 && tab3Content}
          {value === 4 && tab4Content} */}
          {/* {value === 5 && tab5Content} */}
        </Box>
      </Box>
    </>
  );


  return (
    <>
      <BaseDialog
        open={open}
        handleOnClose={close}
        dialogTitle={dialogHeader}
        dialogTitleProps={{
          sx: { display: "flex", justifyContent: "center" },
        }}
        dialogContentBody={dialogContent}
        dialogActionsProps={{
          sx: { display: "flex", justifyContent: "center", mb: 2 },
        }}
        isFullWidth
        maxWidth="lg"
        PaperProps={{ style: { minHeight: "80vh", backgroundColor: backgroundColor[preferredTheme] } }}
        id={"upload-resources-dialog-id"}
      ></BaseDialog>
    </>
  );
};

export default UploadResources;
