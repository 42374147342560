import React, { useState, useContext, useEffect } from "react";

// Base Components
import BaseDrawer from "../../base_components/BaseDrawer";

// Mui Components
import Box from '@mui/material/Box';
// Mui Icons
import CopyrightRoundedIcon from '@mui/icons-material/CopyrightRounded'
import SidebarContext from "../../context/SidebarContext";
// Custom Components
import ThreadList from "../ThreadList/ThreadList"
import PreferenceContext from "../../context/PreferenceContext";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import highContrastTheme from "../../themes/highContrastTheme";

const SideBar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [drawerWidth, setDrawerWidth] = useState()
  const sidebarCxt = useContext(SidebarContext)

  const preferenceCxt = useContext(PreferenceContext);
  const preferredTheme = preferenceCxt.preferenceList.theme;

  const backgroundColor = {
    1: lightTheme.palette.background.paper,
    2: darkTheme.palette.background.paper,
    3: highContrastTheme.palette.background.paper,
  };

  useEffect(() => {
    if (sidebarCxt.collapsable === true)
      setSidebarOpen(true)
    else
      setSidebarOpen(false)
  }, [sidebarCxt.collapsable]);

  useEffect(() => {
    setDrawerWidth(sidebarCxt.sidebarWidth)
  }, [sidebarCxt.sidebarWidth])

  // const drawerWidth = sidebarCxt.drawerWidth;
  const TitleBoxStyle = {
    marginLeft: '10%',
    marginBottom: '10%',
    alignSelf: 'self-end'
  }

  const BaseDrawerGridStyle = {
    '& .MuiDrawer-paper': {
      width: `${drawerWidth}px`,
      display: 'grid',
      background: 'linear-gradient(180deg, #1B1C22 0%, #1F2027 100%)',
      boxShadow: '2px 0 6px rgba(0, 0, 0, 0.1), 3px 0 8px rgba(0, 0, 0, 0.1 )',
      transition: 'all 0.3s ease',
      color: '#F7F7F8',
    },
  };
  const TemporaryDrawerGridStyle = {
    '& .MuiDrawer-paper': {
      width: `${240}px`,
      display: 'grid',
      background: 'linear-gradient(135deg, #0E1117 0%, #171C26 100%)',
      boxShadow: '2px 0 6px rgba(0, 0, 0, 0.1), 3px 0 8px rgba(0, 0, 0, 0.1 )',
      transition: 'all 0.3s ease',
      color: '#F7F7F8',
    },
  };
  const ClosingDivStyle = {
    color: 'grey',
    fontSize: '0.75rem',
    display: 'flex',
    flexDirection: 'column',
  }
  const threadListMarginStyle = {
    marginLeft: "5px",
    marginRight: "5px",
    overflow: 'hidden'
  };
  const handleClose = () => {
    sidebarCxt.setCollapsable(false)
  }

  const version = process.env.REACT_APP_VERSION || '3.6.4';

  return (
    <BaseDrawer
      isOpen={sidebarOpen}
      customStyles={TemporaryDrawerGridStyle}
      variant={drawerWidth === 0 ? "temporary" : "permanent"}
      anchorValue="left"
      id={"sidebar-drawer-id"}
      handleOnClose={handleClose}
    >
      <div style={threadListMarginStyle}>
        <ThreadList />
      </div>
      <Box sx={TitleBoxStyle} xs={{ display: "sticky" }}>
        <div style={ClosingDivStyle}>
          <span>{process.env.REACT_APP_ENVIRONMENT} v. {version}</span>
          <span>Innovation Engineering Team</span>
          <span><CopyrightRoundedIcon sx={{ fontSize: "0.7rem", color: "grey" }} /> LexisNexis Risk Solutions</span>
        </div>
      </Box>
    </BaseDrawer>
  )
};

export default SideBar;
