function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const extractFollowupQuestions = (content) => {

  if (content.includes("****")) {
    const splitArray = content.split("****").map(question => question.trim());
    const mainText = splitArray[0];
    let followUpQuestions = splitArray[1]
      .trim()
      .split(/(?:\d+\.|\-)\s+/)
      .filter(Boolean);

    followUpQuestions = followUpQuestions.length > 3 ? followUpQuestions.slice(-3) : followUpQuestions;

    return {
      mainText,
      followUpQuestions,
    };
  }
  else if (content.toLowerCase().includes("follow-up questions")) {

    // Calculates the first and last index of where the phrase "follow-up questions" occurs
    const followUpIndex = content.toLowerCase().indexOf("follow-up questions");
    const leftCarriageReturnIndex = content.lastIndexOf('\n', followUpIndex);
    const rightCarriageReturnIndex = content.indexOf('\n', followUpIndex);

    // beginning port of the text until the follow-up questions
    const introductoryText = content.substring(0, leftCarriageReturnIndex + 1).trim();

    // end portion of the text after the follow-up questions
    let followUpQsEndIndex = content.indexOf('\n\n', rightCarriageReturnIndex);
    followUpQsEndIndex = followUpQsEndIndex === -1 ? content.length : followUpQsEndIndex; // if the text ends with the follow-up questions
    const trailingText = content.substring(followUpQsEndIndex, content.length).trim();

    // text without the follow-up questions
    const mainText = introductoryText + trailingText;

    // follow-up questions text only
    const followUpQuestionsText = content.substring(rightCarriageReturnIndex, followUpQsEndIndex).trim()

    // split the follow-up questions text into an array of follow-up questions
    const followUpQuestions = followUpQuestionsText
        .trim()
        .split(/(?:\d+\.|\-)\s+/)
        .filter(Boolean);

    return {
      mainText,
      followUpQuestions,
    };
  }

  return null;
};

const  checkAndParseJSON = (jsonString) => {
  try {
    const parsedJSON = JSON.parse(jsonString);
    return parsedJSON["message"]["content"];
  } catch (error) {
    return null;
  }
}

const parseMessages = (raw_messages) => {
    const messages = raw_messages.map(message => {
      const parsedContent = checkAndParseJSON(message.content);
      const messageContent = message.is_a_response === true ? extractFollowupQuestions(parsedContent === null? message.content:parsedContent) : null;
      return {
          messageID: message.message_id,
          sender: message.is_a_response === true? "model" : `${message.user.first_name} ${message.user.last_name}` ,
          content: messageContent === null? (parsedContent === null ? message.content : parsedContent ): messageContent["mainText"],
          followupQuestions: messageContent === null? null: messageContent["followUpQuestions"], 
          siblings: message.siblings,
          parentID: message.parent_id,
          isAnEdit: message.is_an_edit,
          timestamp: convertUTCDateToLocalDate(new Date(message.created_at)).toLocaleString(),
          threadID: message.thread_id
        }
    })
    return messages;
}

function camelCase(str) {
  return str.replace(/_([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}

const convertUTCDateToLocalDate = (date) => {
  const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

  return newDate;
};

const parseToCamelCase = (raw_input) => {
  const new_result = {};
  Object.keys(raw_input).forEach((key) => {
    new_result[camelCase(key)] = raw_input[key];
  });

  return new_result;
};

export { parseMessages, parseToCamelCase };
