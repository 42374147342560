import React,{ createContext, useState } from "react";

const TypingContext = createContext({
    messageID: "",
    hasTyped: false,
    finished: () => {},
    typeMessage: (messageID) => {}
});

export const TypingContextProvider = (props) =>{
    const [messageID, setMessageID] = useState("");
    const [hasTyped, setHasTyped] = useState(false);
    
    const messageIsFinishedTyping = () => {
        setMessageID(null);
        setHasTyped(false);
    }
    
    const messageIsTyping = (messageID) => {
        setMessageID(messageID)
        setHasTyped(true);
    }

    const contextValue = {
        messageID,
        hasTyped,
        finished: messageIsFinishedTyping,
        typeMessage: messageIsTyping
    }

  return <TypingContext.Provider value={contextValue}>{props.children}</TypingContext.Provider>
}

export default TypingContext;