import flask from "./flask";
const postSettings = async (payload, token) => {
  try {
    // payload: object type consisting of preference updates like { 'temperature': 0.5 }
    const response = await flask.post(`preferences`, payload,
      {
        headers: { 'Authorization': `Bearer ${token}` }
      }
    );
    return {
      status: response.status,
      response: response.data,
    };
  }
  catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      code: "",
    };
  }
};

export default postSettings;