import React from "react";
import { Paper, Typography, Box, Chip, Grid } from "@mui/material";

const MetricCard = ({ title, details, chipLabel, selectedTimeline, onTimelineChange }) => {
    const timelineOptions = [
        { label: '3 Months', value: 90 },
        { label: '6 Months', value: 180 },
        { label: '1 Year', value: 365 },
      ];
    return (

        <Paper
            sx={{
                p: 3,
                boxShadow: "0 5px 10px rgba(0,0,0,0.7), 0 0 15px rgba(0,0,0,0.4)",
                borderRadius: 2,
                background: "radial-gradient(circle at top left, #1B1E25, #13151A)",
                
                
            }}
            raised={true}
        >

            {/* <Typography
                variant="h6"
                sx={{ fontWeight: "400", mt: 0.5, color: "white", mb: 4, }}
            >
                {title}
            </Typography> */}
            {title && (
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginRight: "30px" }}>
                <Chip
                    sx={{
                        mt: 0.5,
                        mb: 4,
                        padding: "1rem 2rem",
                        background: "linear-gradient(to right, #0A192F, #16324C)",

                        border: 0,
                        boxShadow: 2,
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        color: "white",
                    }}

                    label={title}
                />
                <div>
                    {/*Adding timeline dropdown here*/}
                    <label style={{ color: "white" }}>Select Timeline: </label>
                    <select
                        value={selectedTimeline}
                        onChange={(e) => {e.preventDefault(); onTimelineChange(parseInt(e.target.value))}}
                    >
                        {timelineOptions.map(option => (
                                                <option key={option.value} value={option.value}>
                                                {option.label}
                                                </option>
                                                ))}
                
                    </select>
                </div>
            </div>
            )}
            <Typography variant="body2" sx={{ mb: 2, fontFamily: "Open Sans", color: "white" }}>
                {details}
            </Typography>
        </Paper>

    );
};

export default MetricCard;
