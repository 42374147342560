import * as React from 'react';

// MUI components
import Button from '@mui/material/Button';

/**
  * Base button component.
  *
  * @param {string} color: allowed values: "default", "inherit", "primary", "secondary"
  * @param {string} variant: "text", "outlined", "contained"
  * @param {string} size: "small", "medium", "large"
  * @param {boolean} disabled: true/false
  * @param {JSX.Element} startIcon: an Icon component or any other valid React element
  * @param {JSX.Element} endIcon: an Icon component or any other valid React element
  * @param {string} buttonLabel: any string value
  * @param {Object} customStyles: any object with valid CSS attributes
  * @param {string} isFullWidth: true/false
  * @param {Function} handleOnClick: valid handler
  * @param {string} href: link to a website
  * @param {string} component: element type
  * @param {string} target: target window or frame 
  * @param {string} rel: attribute noopener noreferrer is added for security 
  * @param {JSX.Element} children: any child React elements to be rendered within the button
  * @returns {JSX.Element} The rendered button component.
*/

export default function BaseButton({  color = "primary",
                                      variant = "contained",
                                      size = "medium",
                                      disabled = false,
                                      startIcon = <></>,
                                      endIcon = <></>,
                                      buttonLabel = "default-button-label",
                                      customStyles = {},
                                      isFullWidth = false,
                                      handleOnClick,
                                      component="",
                                      href = "",
                                      target = "",
                                      rel = "noopener noreferrer",
                                      id,
                                      children = "Default" }) {

  return (
    <Button
      aria-label={buttonLabel}
      color={color}
      variant={variant}
      size={size}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={customStyles}
      fullWidth={isFullWidth}
      onClick={handleOnClick}
      component={component}
      href={href}
      target={target}
      rel={rel}
      id={id}
      data-testid={id}
    >
      {children}
    </Button>
  );
}