// This component lists and displays user preferences and allows them to be modified.

import React, { useState, useContext, useEffect } from 'react';

// MUI components
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import ManageHistoryRoundedIcon from '@mui/icons-material/ManageHistoryRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material';

import { TabContext, TabPanel, TabList } from '@mui/lab';

//base components
import BaseDialog from '../../base_components/BaseDialog';
import BaseTooltip from '../../base_components/BaseTooltip';
import BaseSlider from '../../base_components/BaseSlider';
import BaseTextField from '../../base_components/BaseTextField';
import BaseSelect from '../../base_components/BaseSelect';
import ButtonIcon from '../../base_components/ButtonIcon';

//contexts
import { AlertContext } from '../../context/AlertContext';
import TokenContext from '../../context/TokenContext';
import PreferenceContext from '../../context/PreferenceContext';

//constants
import {
  CHATGPT_MAX_TOKENS, CHATGPT_LARGE_MAX_TOKENS, GPT4_MAX_TOKENS, GPT4_OMNI_MAX_TOKENS,
  TEMPERATURE_TOOLTIP, TOPP_TOOLTIP, MAX_TOKENS_TOOLTIP, FREQUENCY_PENALTY_TOOLTIP, PRESENCE_PENALTY_TOOLTIP, STOP_TOOLTIP,
  SETTINGSLIST_DEFAULTS, CHUNKER_TYPE_WORDS_TO_NUMERIC, EMBEDDING_MODEL_WORDS_TO_NUMERIC,
  MODEL_WORDS_TO_NUMERIC, THEME_WORDS_TO_NUMERIC, MODEL_PERSONA_TOOLTIP, USER_BACKGROUND_TOOLTIP, CHUNKER_TYPE_TOOLTIP,
  USER_BACKGROUND_PLACEHOLDER, MODEL_PERSONA_PLACEHOLDER, PREFERENCE_CONTROL_TOOLTIP, DEFAULT_SETTINGS
} from '../../config/constants';

import { Switch, Tabs, ToggleButton, Tab } from '@mui/material';
import {CircularProgress} from '@mui/material';

import SettingThemeDropdown from '../SettingThemeDropdown/SettingThemeDropdown';
import SettingSlider from '../SettingSlider/SettingSlider';
import SettingTextBox from '../SettingTextBox/SettingTextBox';
import SettingModelDropdown from '../SettingModelDropdown/SettingModelDropdown';
import SettingChunkerTypeDropdown from '../SettingChunkerTypeDropdown/SettingChunkerTypeDropdown';
import SettingEmbeddingModelDropdown from '../SettingEmbeddingModelDropdown/SettingEmbeddingModelDropdown';
import SettingPreferenceControlToggle from '../SettingPreferenceControlToggle/SettingPreferenceControlToggle';

//some custom styles that are re-used across components

const SaveButton = ({ onClick, loading }) => {
  return (
    <Box width={'100%'} display="flex" alignItems="center" justifyContent="center" height="50px" marginBottom="15px" gap={5}>
       <Button variant='contained' onClick={onClick} ><span> {loading ? <CircularProgress size={16} color="inherit" /> : 'Save'}</span></Button>
    </Box>
  );
};

const ResetSettingsButton = ({ onClick }) => {
  return (
    <ButtonIcon handleSubmit={onClick}>
      <BaseTooltip
        title="Reset all settings back to default"
        isArrow
      >
        <ManageHistoryRoundedIcon />
      </BaseTooltip>
    </ButtonIcon>
  );
};

const CloseSettingsButton = ({ onClick }) => {
  return (
    <ButtonIcon handleSubmit={onClick}>
      <BaseTooltip
        title="Close Settings Box"
        isArrow
      >
        <CloseRoundedIcon />
      </BaseTooltip>
    </ButtonIcon>
  );
};


const SettingsList = ({ open, handleOpen, handleClose, handleCloseMenu}) => {
  const DEFAULT_SETTINGS = {
    theme: SETTINGSLIST_DEFAULTS.themeValue,
    model: SETTINGSLIST_DEFAULTS.modelNameValue,
    maxTokens: SETTINGSLIST_DEFAULTS.maxTokensValue,
    temperature: SETTINGSLIST_DEFAULTS.temperature,
    topP: SETTINGSLIST_DEFAULTS.topP,
    frequencyPenalty: SETTINGSLIST_DEFAULTS.frequencyPenalty,
    presencePenalty: SETTINGSLIST_DEFAULTS.presencePenalty,
    stop: "",
    modelPersona: "",
    userBackground: "",
    threadPreferenceFlag: SETTINGSLIST_DEFAULTS.threadPreferenceFlag,
    embeddingModel: SETTINGSLIST_DEFAULTS.embeddingModel,
    chunkerType: SETTINGSLIST_DEFAULTS.chunkerType

  }

  const { showAlert } = useContext(AlertContext);
  const tokenCxt = useContext(TokenContext);
  const preferenceCxt = useContext(PreferenceContext);
  const theme = useTheme();

  const [upperTokenLimit, setUpperTokenLimit] = useState(CHATGPT_MAX_TOKENS);
  const [tabIndex, setTabIndex] = useState(0);

  const [formInputValues, setFormInputValues] = useState(DEFAULT_SETTINGS)
  const [disableTopPSlider, setDisableTopPSlider] = useState(false);
  const [disableTemperatureSlider, setDisableTemperatureSlider] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) {
      return () => {
        isMounted = false;
      };
    }
    const loadPreferences = () => {
      setFormInputValues(() => ({ ...DEFAULT_SETTINGS, ...preferenceCxt.preferenceList }));
    }
    loadPreferences();

  }, [preferenceCxt.preferenceList.threadPreferenceFlag])

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    setFormInputValues(formInputValues => ({ ...formInputValues, [name]: value }));
    if (name === "model") {
      switch (value) {
        case 1:
          setFormInputValues(formInputValues => ({ ...formInputValues, maxTokens: CHATGPT_MAX_TOKENS }));
          setUpperTokenLimit(CHATGPT_MAX_TOKENS);
          break;
        case 2:
          setFormInputValues(formInputValues => ({ ...formInputValues, maxTokens: CHATGPT_LARGE_MAX_TOKENS }));
          setUpperTokenLimit(CHATGPT_LARGE_MAX_TOKENS);
          break;
        case 3:
          setFormInputValues(formInputValues => ({ ...formInputValues, maxTokens: GPT4_MAX_TOKENS }));
          setUpperTokenLimit(GPT4_MAX_TOKENS);
          break;
        case 4:
          setFormInputValues(formInputValues => ({ ...formInputValues, maxTokens: GPT4_OMNI_MAX_TOKENS }));
          setUpperTokenLimit(GPT4_OMNI_MAX_TOKENS);
          break;
      }
    }
  }

  const handleSaveButtonClick = async () => {
    setLoading(true);
    let preferences_payload = formInputValues;
    let response = await preferenceCxt.savePreferences(preferences_payload, tokenCxt.token);

    if (!!response) {
      const updatedPayload = preferences_payload;
      const newPreferences = await preferenceCxt.updatePreferences(updatedPayload);
      
      setFormInputValues(newPreferences);
      showAlert("Preferences successfully saved.", "success");
      handleClose();
      handleCloseMenu();
    }
    else {
      showAlert("Preferences were not saved. If this error persists, contact Innovation Engineering Team.", "error");
      console.error('Error while making API call to backend to save settings');
    }
    setLoading(false);
  };

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  }

  const handleDialogClose = () => {
    handleClose();
    handleCloseMenu();
    setTabIndex(0);
  }

  // handler that resets all settings to default 
  const resetAllSettings = () => {
    setFormInputValues(DEFAULT_SETTINGS);
    setUpperTokenLimit(DEFAULT_SETTINGS.maxTokens);
  };

  // content to be shown in the header of the dialog
  const dialogHeader = (
    <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" p={2}>
      <ResetSettingsButton onClick={resetAllSettings} />
      <Typography id="modal-modal-title" variant="h5" component="h2" align="center" fontWeight="bold" sx={{ flexGrow: 1 }}>
        Settings
      </Typography>
      <CloseSettingsButton onClick={handleDialogClose} />
    </Box>
  );

  const dialogContent =
    <Box sx={{ p: 3, display: "flex", height: '100%', }}>
      <TabContext value={tabIndex}>
        <Box sx={{ width: '270px', borderRight: 1, borderColor: "divider", }}>
          <TabList
            orientation="vertical"
            indicatorColor="primary"
            variant="scrollable"
            onChange={handleTabChange}
            aria-label="Setting Tabs"
            sx={{
              '.MuiTab-root': {
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                padding: 3,
                borderBottom: 1,
                borderColor: 'divider',
                textAlign: 'left',
                '&.Mui-selected': {
                  backgroundColor: theme.palette.background.default,
                },
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                  cursor: 'pointer',
                }
              }
            }}
          >
            <Tab label={<Typography variant="body" color={theme.palette.text.primary} value="0">General</Typography>} />
            <Tab label={<Typography variant="body" color={theme.palette.text.primary} value="1">Model Parameters</Typography>} />
            <Tab label={<Typography variant="body" color={theme.palette.text.primary} value="2">RAG Preferences</Typography>} />
            <Tab label={<Typography variant="body" color={theme.palette.text.primary} value="3">Custom Instructions</Typography>} />
            <Tab label={<Typography variant="body" color={theme.palette.text.primary} value="4">Thread Preference Control</Typography>} />
          </TabList>
        </Box>
        <Box sx={{ width: '100%', p: 3, }}>
          <TabPanel value={0}>
            <SettingThemeDropdown value={formInputValues.theme} onChange={handleInputChange} />
          </TabPanel>
          <TabPanel value={1}>
            <SettingModelDropdown
              name="model"
              value={formInputValues.model}
              onChange={handleInputChange}
            />
            <SettingSlider
              value={formInputValues.temperature}
              onChange={handleInputChange}
              title="Temperature"
              name="temperature"
              isDisabled={disableTemperatureSlider}
              tooltipContent={TEMPERATURE_TOOLTIP}
            />
            <SettingSlider
              value={formInputValues.topP}
              onChange={handleInputChange}
              title="Top p"
              name="topP"
              isDisabled={disableTopPSlider}
              tooltipContent={TOPP_TOOLTIP}
            />
            <SettingSlider
              value={formInputValues.maxTokens}
              onChange={handleInputChange}
              title="Max Tokens"
              name="maxTokens"
              min={100}
              max={upperTokenLimit}
              step={1}
              tooltipContent={MAX_TOKENS_TOOLTIP}
            />
            <SettingSlider
              value={formInputValues.frequencyPenalty}
              onChange={handleInputChange}
              title="Frequency Penalty"
              name="frequencyPenalty"
              min={-2}
              max={2}
              step={0.1}
              tooltipContent={FREQUENCY_PENALTY_TOOLTIP}
            />
            <SettingSlider
              value={formInputValues.presencePenalty}
              onChange={handleInputChange}
              title="Presence Penalty"
              name="presencePenalty"
              min={-2}
              max={2}
              step={0.1}
              tooltipContent={PRESENCE_PENALTY_TOOLTIP}
            />
            <SettingTextBox
              value={formInputValues.stop}
              onChange={handleInputChange}
              title="Stop"
              name="stop"
              tooltipContent={STOP_TOOLTIP}
              minRows={1}
              placeholder="Example: ['</code>', 'test']"
            />
          </TabPanel>

          <TabPanel value={2}>
            <SettingEmbeddingModelDropdown
              name="embeddingModel"
              value={formInputValues.embeddingModel}
              onChange={handleInputChange}
            />
            <SettingChunkerTypeDropdown
              name="chunkerType"
              value={formInputValues.chunkerType}
              onChange={handleInputChange}
              tooltipContent={CHUNKER_TYPE_TOOLTIP}
            />
          </TabPanel>

          <TabPanel value={3}>
            <SettingTextBox
              value={formInputValues.userBackground}
              onChange={handleInputChange}
              title="User Background"
              name="userBackground"
              tooltipContent={USER_BACKGROUND_TOOLTIP}
              minRows={4}
              placeholder={USER_BACKGROUND_PLACEHOLDER}
            />
            <SettingTextBox
              value={formInputValues.modelPersona}
              onChange={handleInputChange}
              title="Model Persona"
              name="modelPersona"
              tooltipContent={MODEL_PERSONA_TOOLTIP}
              minRows={4}
              placeholder={MODEL_PERSONA_PLACEHOLDER}
            />
          </TabPanel>
          <TabPanel value={4}>
            <SettingPreferenceControlToggle label="Thread Preference Control" tooltip={PREFERENCE_CONTROL_TOOLTIP} value={formInputValues.threadPreferenceFlag} onChange={handleInputChange} name="threadPreferenceFlag" />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>;


  // content to be shown in the actions section of the dialog  
  const dialogActions =
    <SaveButton onClick={handleSaveButtonClick} loading={loading} />;

  return (
    <BaseDialog
      open={open}
      handleOnClose={handleDialogClose}
      dialogTitleProps={{ id: "alert-dialog-title"}}
      dialogTitle={dialogHeader}
      dialogContentProps={{ id: "alert-dialog-description" }}
      dialogContentBody={dialogContent}
      dialogActions={dialogActions}
      id={"settings-list-dialog-id"}
      customStyles={{
        ".MuiPaper-root": {
          maxWidth: "100%",
          maxHeight: "100%",
          width: "70vw",
          height: "70vh",
        }
      }}
    >
    </BaseDialog>
  );
};

export default SettingsList;