import flask from "./flask";

// Delete user's selected thread
const deleteDocumentMetadata = async (token,documentId) => {
  try {
    const response = await flask.delete(`metadata/${documentId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );
    return response;
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default deleteDocumentMetadata;