import React, { useContext, useEffect } from 'react';

//custom components
import Message from '../Message/Message';

//MUI components
import Container from '@mui/material/Container';

//contexts
import TokenContext from '../../context/TokenContext';

const MessageList = ({
  messageList,
  dummyDivRef,
  handleGetHistoricalMessages,
  handleEdit,
  isGeneratingResponse,
  promptPlaceholder,
  responsePlaceholder,
  selectedSearchMsgId,
  id
}) => {

  const mainContainerStyle = {
    // padding: "15px",
    height: "102%",   // height > 100% so the last msg does not show behind the message prompt
    display: 'contents'
  };
  const tokenCxt = useContext(TokenContext);

  // using a list of refs to scroll to the selected search message
  const messageRefs = messageList.reduce((accumulator, message) => {
    accumulator[message.messageID] = React.createRef();
    return accumulator;
  }, {});

  useEffect(() => {
    if (selectedSearchMsgId && messageRefs[selectedSearchMsgId]) {
      messageRefs[selectedSearchMsgId].current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedSearchMsgId, messageRefs[selectedSearchMsgId]]);

  return (
    <>
      <Container
        id={id}
        data-testid={'message-list-header-id'}
        style={mainContainerStyle}
      >
        {
          messageList.map((message, index) => {
            return (
              <div key={index} ref={messageRefs[message.messageID]}>
                <Message
                  
                  key={index}
                  sender={message.sender}
                  content={message.content}
                  messageID={message.messageID}
                  siblings={message.siblings}
                  timestamp={message.timestamp}
                  handleEdit={handleEdit}
                  parentID={message.parentID}
                  handleGetHistoricalMessages={handleGetHistoricalMessages}
                  isGeneratingResponse={isGeneratingResponse}
                />
              </div>
            );
          })
        }

        {
          isGeneratingResponse ?
            (<>
              <Message
                sender={tokenCxt.firstName}
                content={promptPlaceholder}
                siblings={[]}
                handleEdit={handleEdit}
              />
              <Message
                sender="model"
                content={responsePlaceholder}
                siblings={[]}
                handleEdit={handleEdit}
              />
            </>)
            : ""
        }

        {/* Using this div to default the scrolling of the messagelist component by default to the bottom.
          // Avoids complicated scroll calculation logic this way. */}
        <div ref={dummyDivRef}></div>
      </Container>
    </>
  );

};

export default MessageList;