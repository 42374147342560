import { createTheme } from '@mui/material/styles';
import { red, yellow, blue, green } from '@mui/material/colors';


const lightTheme = createTheme({
  typography: {
    //fontFamily: 'Open Sans',
    fontSize: 12
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#03203A',
    },
    secondary: {
      main: '#D9D9E3',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F7F7F8',
    },
    text: {
      primary: '#000000',
    },
    appbar: {
      svgIcon: '#FFFFFF'
    }
  },
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          // backgroundColor: '#D9D9E3',
          // backgroundColor: '', // Customize the background color for the card
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          outline: 'solid',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          color: 'black'
        }
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'document_card' },
          style: {
            backgroundColor: '#D9D9E3',
          },
        },
        {
          props: { variant: 'selected_document_card' },
          style: {
            backgroundColor: '#28cc5d',
          },
        }
      ],
      styleOverrides: {
        root: {
          backgroundColor: '#F7F7F8',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          border: '1px solid rgba(217, 217, 227, 0.5)',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#202123',
        },
      },
    },
    MuiSpeedDial: {
      styleOverrides: {
        fab: {
          backgroundColor: '#F7F7F8', // Custom icon color
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#F7F7F8',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        standardSuccess: {
          backgroundColor: green[200],
          color: green[900],
        },
        standardInfo: {
          backgroundColor: blue[200],
          color: blue[900],
        },
        standardWarning: {
          backgroundColor: yellow[200],
          color: yellow[900],
        },
        standardError: {
          backgroundColor: red[200],
          color: red[800],
        },
      },
    },
    MuiTextField: { //default label color should be whitish
      variants: [
        {
          props: { disabled: true },
          style: {
            outline: 'none',
            border: 'none',
            backgroundImage: 'linear-gradient(90deg, black 50%, transparent 50%), linear-gradient(90deg, black 50%, transparent 50%), linear-gradient(0deg, black 50%, transparent 50%), linear-gradient(0deg, black 50%, transparent 50%)',
            backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
            backgroundSize: '15px 4px, 15px 4px, 4px 15px, 4px 15px',
            animation: 'border-dance 4s infinite linear',
            "@keyframes border-dance": {
              "0%": {
                backgroundPosition: "left top, right bottom, left bottom, right   top",
              },
              "100%": {
                backgroundPosition: "left 15px top, right 15px bottom , left bottom 15px , right   top 15px",
              }
            }
          },
        },
        {
          props: { placeholder: "Search..." },
          style: {
            backgroundColor: "#363738",
          },
        },
      ],
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          border: "none",
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              boxShadow: "0px 0px 1px 0.5px rgba(0, 0, 0, 1.0)",
              border: "none",
            },
            '&.Mui-focused fieldset': {
              boxShadow: "0px 0px 1px 0.5px rgba(0, 0, 0, 1.0)",
              border: "none",
            },
            borderRadius: "10px",
          },
        },

      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          border: '1px solid rgba(217, 217, 227, 0.5)',
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "linear-gradient(175deg, #1F2027 0%, #1B1C22 100%)",
        }
      }
    },
    MuiDrawer: {
      variants: [
        {
          props: { id: "sidebar-drawer-id" },
          style: {
            '& .MuiDrawer-paper': {
              width: 240,
              display: 'grid',
              background: 'linear-gradient(180deg, #1B1C22 0%, #1F2027 100%)',
              boxShadow: '2px 0 6px rgba(0, 0, 0, 0.1), 3px 0 8px rgba(0, 0, 0, 0.1 )',
              transition: 'all 0.3s ease',
              color: '#F7F7F8',
            },

          },
        },
      ],
      styleOverrides: {
        paper: {
          backgroundColor: '#202123',
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          border: "1px solid #D9D9E3",
          borderRadius: '10px',
        },
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: '25vw',
          backgroundColor: '#FFFFFF'
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          border: "1px solid #D9D9E3",
          '&.Mui-selected': {
            '&:hover': {
              backgroundColor: "#FFFFFF",
            },
            backgroundColor: "#D9D9E3",
            color: "#202123"
          },
          borderRadius: '10px',
        }
      }
    },
    // MuiAppBar: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#202123',
    //       backgroundImage: 'none',
    //       boxShadow: 'none',
    //     }
    //   }
    // },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "0px 0px 0px 0px !important",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        // Customize the scrollbar for the root container (e.g., body or a specific component)
        '::-webkit-scrollbar': {
          width: '8px', // Specify the width of the scrollbar
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Specify the background color of the scrollbar track
        },
        '::-webkit-scrollbar-thumb': {
          background: '#888', // Specify the color of the scrollbar thumb
          borderRadius: '4px', // Specify the border radius of the scrollbar thumb
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          border: 'none',
          borderRadius: '10px',
          backgroundColor: '#FFFFFF', 
          color: '#262F40', 
          '&:hover': {
            backgroundColor: '#F0F0F0',
          },
          '& .MuiSelect-icon': {
            color: '#262F40', 
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '10px',
          backgroundColor: '#FFFFFF',
          color: '#262F40', 
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#D9D9E3', 
            color: '#262F40', 
          },
          '&:hover': {
            backgroundColor: '#F0F0F0', 
            color: '#262F40', 
          },
        },
      },
    },
  
    
  },
});
export default lightTheme;