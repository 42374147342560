import React, { useState, useContext, useEffect } from "react";

import {
    CHATGPT_MAX_TOKENS, CHATGPT_LARGE_MAX_TOKENS, GPT4_LARGE_MAX_TOKENS
    , GPT4_MAX_TOKENS, SETTINGSLIST_DEFAULTS
    , THEME_NUMERIC_TO_WORDS, MODEL_NUMERIC_TO_WORDS, DEFAULT_SETTINGS
} from "../../config/constants";

import { AlertContext } from "../../context/AlertContext";

import { Box, Button, Typography, Divider } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ButtonIcon from "../../base_components/ButtonIcon";
import SettingsIcon from '@mui/icons-material/Settings';

import BaseDialog from "../../base_components/BaseDialog";
import BaseTooltip from "../../base_components/BaseTooltip";
import PreferenceDialogFormContent from "../PreferenceDialogFormContent/PreferenceDialogFormContent";
import PreferenceContext from "../../context/PreferenceContext";

const StagingThreadPreferenceDialog = ({open, handleClose, setStagingThreadPreferences, stagingThreadPreferences, isStagingThreadPreferenceDisabled}) =>{
    const [formInputValues, setFormInputValues] = useState({...stagingThreadPreferences});
    const [disableTopPSlider, setDisableTopPSlider] = useState(false);
    const [disableTemperatureSlider, setDisableTemperatureSlider] = useState(false);
    const [upperTokenLimit, setUpperTokenLimit] = useState(CHATGPT_MAX_TOKENS);

    const preferenceCxt = useContext(PreferenceContext);
    const { showAlert } = useContext(AlertContext);

    useEffect(()=>{
        setFormInputValues({...stagingThreadPreferences});
    }, [stagingThreadPreferences])

    const handleSaveStagingThreadPreferences = async () => {
        const preferencePayload = {...formInputValues};
        
        const updatedPreferences = {...preferenceCxt.preferenceList, ...preferencePayload}
        setStagingThreadPreferences(updatedPreferences);
        showAlert("Your thread level preferences have been saved successfully! These preferences will be applied to the current thread only.", "success");
        handleClose();
    }

    const handleInputChange = ({ target }) => {
        const { name, value } = target;
        setFormInputValues(formInputValues => ({ ...formInputValues, [name]: value }));
        if (name === "model") {
            switch (value) {
                case 1:
                    setFormInputValues(formInputValues => ({ ...formInputValues, maxTokens: CHATGPT_MAX_TOKENS }));
                    setUpperTokenLimit(CHATGPT_MAX_TOKENS);
                    break;
                case 2:
                    setFormInputValues(formInputValues => ({ ...formInputValues, maxTokens: CHATGPT_LARGE_MAX_TOKENS }));
                    setUpperTokenLimit(CHATGPT_LARGE_MAX_TOKENS);
                    break;
                case 3:
                    setFormInputValues(formInputValues => ({ ...formInputValues, maxTokens: GPT4_MAX_TOKENS }));
                    setUpperTokenLimit(GPT4_MAX_TOKENS);
                    break;
                case 4:
                    setFormInputValues(formInputValues => ({ ...formInputValues, maxTokens: GPT4_LARGE_MAX_TOKENS }));
                    setUpperTokenLimit(GPT4_LARGE_MAX_TOKENS);
                    break;
            }
        }
    }

    const dialogHeader =
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="50px"
            gap={2.5}
            padding="0 16px"
            borderRadius="8px"
        >
            <Typography
                id="modal-modal-title"
                variant="h5"
                align="center"
                fontWeight="bold"
                sx={{ flexGrow: 1 }}
            >
                Thread Level Preferences
            </Typography>
            <ButtonIcon handleSubmit={handleClose}>
                <BaseTooltip title="Close Settings Box" arrow>
                    <CloseRoundedIcon />
                </BaseTooltip>
            </ButtonIcon>
        </Box>;

    const dialogAction =
        <Box width={'100%'} display="flex" alignItems="center" justifyContent="center" height="50px" marginBottom="15px" gap={5}>
            <Button variant='contained' onClick={!isStagingThreadPreferenceDisabled && handleSaveStagingThreadPreferences || handleClose} ><span>Save</span></Button>
        </Box>

    const dialogContent = <>
        {!isStagingThreadPreferenceDisabled &&
            <Box marginBottom={2} sx={{ width: "100%" }}>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    Please note that these are the preferences for the current conversation only. Changing these preferences will not affect the default preferences and will only be applied to this conversation.
                    If you want to change your default preferences, please go to the <strong>Settings</strong> page.

                </Typography>
            </Box>}
        <PreferenceDialogFormContent
            formInputValues={formInputValues}
            handleInputChange={handleInputChange}
            disableTopPSlider={disableTopPSlider}
            disableTemperatureSlider={disableTemperatureSlider}
            upperTokenLimit={upperTokenLimit}
            disabled={isStagingThreadPreferenceDisabled}
        />
    </>

    return (
        <>
            <BaseDialog
                open={open}
                handleOnClose={handleClose}
                dialogTitleProps={{ id: "alert-dialog-title" }}
                dialogTitle={dialogHeader}
                dialogContentProps={{ id: "alert-dialog-description" }}
                dialogContentBody={dialogContent}
                dialogActions={dialogAction}
                isFullWidth
                id={"thread-preference-dialog-id"}
                maxWidth="md"

            >
            </BaseDialog>
        </>
    );
}

export default StagingThreadPreferenceDialog;