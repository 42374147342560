import flask from "./flask";

const getDocumentList = async (token) => {
  try {
    const response = await flask.get(`document/list`,
      { headers: { 'Authorization': `Bearer ${token}` }}
    ); 
    return response;
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default getDocumentList;