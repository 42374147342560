import BaseTextField from '../base_components/BaseTextField';
import React,{  useContext } from 'react';
const LoginTextField = ({id}) => { 
    const expanded_id = ( id === 'riskid')  ? 'Risk ID' 
                                            : 'Risk Password';
    const type = id === 'riskpassword' ? 'password' : ''
    return (
        <BaseTextField
            margin="normal"
            required={true}
            fullWidth={true}
            name={id}
            placeholder={"Enter your " + expanded_id}
            type={type}
            id={id} 
            autoComplete="true"
            customStyles = {{maxWidth: '90%'}}
            />
        )
};

export default LoginTextField;