import * as React from 'react';

// MUI components
import Tooltip from '@mui/material/Tooltip';

/**
  * Base Tooltip component.
  * @param {string|JSX.Element} title: Specifies the content of the tooltip.
  * @param {boolean} isArrow: Controls whether to show an arrow pointing to the target element. Defaults to false.
  * @param {boolean} isHoverListenerDisabled: Disables the hover listener. Defaults to false.
  * @param {boolean} isFocusListenerDisabled: Disables the focus listener. Defaults to false.
  * @param {boolean} isTouchListenerDisabled: Disables the touch listener. Defaults to false.
  * @param {integer} enterDelay: The number of milliseconds to wait before showing the tooltip. Defaults to 100.
  * @param {integer} enterNextDelay: The number of milliseconds to wait before showing the tooltip when one was already recently opened. Defaults to 400.
  * @param {integer} enterTouchDelay: The number of milliseconds a user must touch the element before showing the tooltip. Defaults to 700.
  * @param {integer} leaveDelay: The number of milliseconds to wait before hiding the tooltip. Defaults to 0.
  * @param {integer} leaveTouchDelay: The number of milliseconds after the user stops touching an element before hiding the tooltip. Defaults to 1500.
  * @param {string} placement: Tooltip placement. Defaults to 'bottom'. Possible values are: 'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 
  *                             'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top'
  * @param {Object} PopperProps: Props applied to the Popper element. Defaults to {}.
  * @param {JSX.Element} TransitionComponent: The component used for the transition.
  * @param {Object} TransitionProps: Props applied to the transition element. 
  * @param {boolean} followCursor: If true, the tooltip follow the cursor over the wrapped element. Defaults to false.
  * @param {string} id: This prop is used to help implement the accessibility logic. If you don't provide this prop. It falls back to a randomly generated id.
  * @param {Function} handleOnClose: Props applied to the transition element.
  * @param {Function} handleOnOpen: Props applied to the transition element.
  * @param {boolean} open: If true, the component is shown.
  * @param {Object} customStyles: any object with valid CSS attributes
  * @param {JSX.Element} children: any child React elements to be rendered within the button
  * @returns {JSX.Element} The rendered Tooltip component.
*/

export default function BaseTooltip({ title,
                                      isArrow=false,
                                      isHoverListenerDisabled=false,
                                      isFocusListenerDisabled=false,
                                      isTouchListenerDisabled=false,
                                      enterDelay=100,
                                      enterNextDelay=400,
                                      enterTouchDelay=700,
                                      leaveDelay=0,
                                      leaveTouchDelay=1500,
                                      placement='bottom',
                                      PopperProps={},
                                      TransitionComponent,
                                      TransitionProps,
                                      followCursor=false,
                                      id,
                                      handleOnClose,
                                      handleOnOpen,
                                      open,
                                      customStyles,
                                      children, 
                                    }) {

  return (
    <Tooltip
      title={title}
      arrow={isArrow}
      disableHoverListener={isHoverListenerDisabled}
      disableFocusListener={isFocusListenerDisabled}
      disableTouchListener={isTouchListenerDisabled}
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
      enterTouchDelay={enterTouchDelay}
      leaveDelay={leaveDelay}
      leaveTouchDelay={leaveTouchDelay}
      placement={placement}
      PopperProps={PopperProps}
      TransitionComponent={TransitionComponent}
      TransitionProps={TransitionProps}
      followCursor={followCursor}
      id={id}
      onClose={handleOnClose}
      onOpen={handleOnOpen}
      open={open}
      sx={customStyles}
    >
      {children}
    </Tooltip>
  );
}