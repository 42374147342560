import flask from "./flask";

const getDocument = async (token, doc_name) => {
  try {
    
    const response = await flask.get(`document/${doc_name}`,
      { headers: { 'Authorization': `Bearer ${token}` }}
    ); 
    return response;
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default getDocument;