import React, { useState, useContext, useEffect } from "react";

import {
    CHATGPT_MAX_TOKENS, CHATGPT_LARGE_MAX_TOKENS, GPT4_LARGE_MAX_TOKENS, GPT4_MAX_TOKENS,
    SETTINGSLIST_DEFAULTS, THEME_NUMERIC_TO_WORDS, MODEL_NUMERIC_TO_WORDS,
    EMBEDDING_MODEL_NUMERIC_TO_WORDS, CHUNKER_TYPE_NUMERIC_TO_WORDS
} from "../../config/constants";
import putThreadPreferences from "../../api/putThreadPreferences";


import TokenContext from "../../context/TokenContext";
import ThreadContext from "../../context/ThreadContext";
import { AlertContext } from "../../context/AlertContext";
import ThreadPreferenceContext from "../../context/ThreadPreferenceContext";

import { Box, Button, Typography, Divider } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ButtonIcon from "../../base_components/ButtonIcon";
import SettingsIcon from '@mui/icons-material/Settings';

import BaseDialog from "../../base_components/BaseDialog";
import BaseTooltip from "../../base_components/BaseTooltip";
import { Info } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import PreferenceDialogFormContent from "../PreferenceDialogFormContent/PreferenceDialogFormContent";

const ThreadPreferenceDialog = ({ open, handleClose }) => {
    const DEFAULT_SETTINGS = {
        theme: SETTINGSLIST_DEFAULTS.themeValue,
        model: SETTINGSLIST_DEFAULTS.modelNameValue,
        maxTokens: SETTINGSLIST_DEFAULTS.maxTokensValue,
        temperature: SETTINGSLIST_DEFAULTS.temperature,
        topP: SETTINGSLIST_DEFAULTS.topP,
        frequencyPenalty: SETTINGSLIST_DEFAULTS.frequencyPenalty,
        presencePenalty: SETTINGSLIST_DEFAULTS.presencePenalty,
        stop: "",
        modelPersona: "",
        userBackground: "",
        threadPreferenceFlag: SETTINGSLIST_DEFAULTS.threadPreferenceFlag,
        selectedDocuments: "",
        documentMode: SETTINGSLIST_DEFAULTS.documentMode,
        chunkerType: SETTINGSLIST_DEFAULTS.chunkerType,
        embeddingModel: SETTINGSLIST_DEFAULTS.embeddingModel,
    }
    const [formInputValues, setFormInputValues] = useState(DEFAULT_SETTINGS);
    const [disableTopPSlider, setDisableTopPSlider] = useState(false);
    const [disableTemperatureSlider, setDisableTemperatureSlider] = useState(false);
    const [upperTokenLimit, setUpperTokenLimit] = useState(CHATGPT_MAX_TOKENS);
    const [actionConfirmDialogOpen, setActionConfirmDialogOpen] = useState(false);

    const tokenCxt = useContext(TokenContext);
    const threadCxt = useContext(ThreadContext);
    const { showAlert } = useContext(AlertContext);
    const threadPreferenceCxt = useContext(ThreadPreferenceContext);
    const theme = useTheme();

    useEffect(() => {
        let isMounted = true;
        if (!isMounted) {
            return () => {
                isMounted = false;
            };
        }

        const preferences = threadPreferenceCxt.preferenceList;
        !!preferences && setFormInputValues(() => ({ ...DEFAULT_SETTINGS, ...preferences }));
    }, [threadPreferenceCxt.preferenceList])

    const handleSaveThreadPreferences = async () => {
        const preferencePayload = { ...formInputValues };
        preferencePayload["theme"] = typeof preferencePayload["theme"] == "number" ? THEME_NUMERIC_TO_WORDS[preferencePayload["theme"]] : preferencePayload["theme"];
        preferencePayload["model"] = typeof preferencePayload["model"] == "number" ? MODEL_NUMERIC_TO_WORDS[preferencePayload["model"]] : preferencePayload["model"];
        preferencePayload["chunkerType"] = typeof preferencePayload["chunkerType"] == "number" ? CHUNKER_TYPE_NUMERIC_TO_WORDS[preferencePayload["chunkerType"]] : preferencePayload["chunkerType"];
        preferencePayload["embeddingModel"] = typeof preferencePayload["embeddingModel"] == "number" ? EMBEDDING_MODEL_NUMERIC_TO_WORDS[preferencePayload["embeddingModel"]] : preferencePayload["embeddingModel"];

        const response = await putThreadPreferences(tokenCxt.token, threadCxt.threadID, preferencePayload);

        if (response.status !== 200) {
            showAlert("Failed to save thread preferences", "error");
            return;
        }

        showAlert("Thread preferences saved successfully", "success");
        const preferences = threadPreferenceCxt.preferenceList;
        threadPreferenceCxt.updatePreferences({ ...preferences, ...formInputValues });
        handleClose();
        handleCloseActionConfirmDialog();
    }

    const handleInputChange = ({ target }) => {
        const { name, value } = target;
        setFormInputValues(formInputValues => ({ ...formInputValues, [name]: value }));
        if (name === "model") {
            switch (value) {
                case 1:
                    setFormInputValues(formInputValues => ({ ...formInputValues, maxTokens: CHATGPT_MAX_TOKENS }));
                    setUpperTokenLimit(CHATGPT_MAX_TOKENS);
                    break;
                case 2:
                    setFormInputValues(formInputValues => ({ ...formInputValues, maxTokens: CHATGPT_LARGE_MAX_TOKENS }));
                    setUpperTokenLimit(CHATGPT_LARGE_MAX_TOKENS);
                    break;
                case 3:
                    setFormInputValues(formInputValues => ({ ...formInputValues, maxTokens: GPT4_MAX_TOKENS }));
                    setUpperTokenLimit(GPT4_MAX_TOKENS);
                    break;
                case 4:
                    setFormInputValues(formInputValues => ({ ...formInputValues, maxTokens: GPT4_LARGE_MAX_TOKENS }));
                    setUpperTokenLimit(GPT4_LARGE_MAX_TOKENS);
                    break;
            }
        }
    }

    const handleOpenActionConfirmDialog = () => {
        setActionConfirmDialogOpen(true);
    }

    const handleCloseActionConfirmDialog = () => {
        setActionConfirmDialogOpen(false);
    }

    const dialogHeader =
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="50px"
            gap={2.5}
            padding="0 16px"
            borderRadius="8px"
        >
            <Typography
                id="modal-modal-title"
                variant="h5"
                align="center"
                fontWeight="bold"
                sx={{ flexGrow: 1 }}
            >
                Thread Level Preferences
            </Typography>
            <ButtonIcon handleSubmit={handleClose}>
                <BaseTooltip title="Close Settings Box" arrow>
                    <CloseRoundedIcon />
                </BaseTooltip>
            </ButtonIcon>
        </Box>;


    const dialogAction =
        <Box width={'100%'} display="flex" alignItems="center" justifyContent="center" height="50px" marginBottom="15px" gap={5}>
            <Button variant='contained' onClick={handleOpenActionConfirmDialog} ><span>Save</span></Button>
        </Box>

    const dialogContent = <>
        <Box marginBottom={2} sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Use this dialog to customize settings for this conversation only. These changes override your default settings and apply to all future messages in this thread.

                <p><strong>Caution:</strong></p>
                Changing settings mid-conversation can lead to inconsistent or unexpected AI responses. To avoid confusion, we recommend keeping preferences consistent throughout a conversation.
                To change your default settings, visit the <strong>Settings</strong> page.
            </Typography>
        </Box>
        <PreferenceDialogFormContent
            formInputValues={formInputValues}
            handleInputChange={handleInputChange}
            disableTopPSlider={disableTopPSlider}
            disableTemperatureSlider={disableTemperatureSlider}
            upperTokenLimit={upperTokenLimit}
        />
    </>

    return (
        <>
            <BaseDialog
                open={open}
                handleOnClose={handleClose}
                dialogTitleProps={{ id: "alert-dialog-title" }}
                dialogTitle={dialogHeader}
                dialogContentProps={{ id: "alert-dialog-description" }}
                dialogContentBody={dialogContent}
                dialogActions={dialogAction}
                isFullWidth
                id={"thread-preference-dialog-id"}
                maxWidth="md"
            >
            </BaseDialog>
            <BaseDialog
                open={actionConfirmDialogOpen}
                handleOnClose={handleClose}
                isFullWidth

                dialogTitleProps={{ id: "alert-dialog-title" }}
                dialogTitle={<Box paddingTop={2}><Typography variant="h5" fontWeight="bold">Confirm Action</Typography></Box>}
                dialogContentProps={{ id: "alert-dialog-description" }}
                dialogContentBody={
                    <>
                        <Typography variant="h6" sx={{ marginBottom: 1, marginTop: 1 }}>
                            Are you sure you want to save the thread preferences?
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 1 }}>
                            Note: Changing thread preferences will override the previously saved preferences for this thread. The changes will be applied to all future messages in this thread. Drastic changes in preferences can lead to inconsistent or unexpected model responses.
                            <br /><br />
                            <span style={{ fontWeight: "bold" }}>Best practice</span> is to avoid changing preferences mid-conversation to maintain consistency in model responses. 
                        </Typography>
                    </>
                }
                dialogActions={
                    <Box width={'100%'} display="flex" alignItems="center" justifyContent="flex-end" height="50px" marginBottom="15px" gap={5} padding={2}>
                        <Button variant='contained' onClick={handleSaveThreadPreferences} ><span style={{ fontWeight: "bold" }}>Yes, I understand the risks. Save preferences</span></Button>
                        <Button variant='contained' onClick={handleCloseActionConfirmDialog} ><span style={{ fontWeight: "bold" }}>Cancel</span></Button>
                    </Box>
                }
                id={"thread-preference-confirm-dialog-id"}
            />
        </>
    );
}

export default ThreadPreferenceDialog;
