import React,{ createContext, useState } from "react";

const SidebarContext = createContext({
  sidebarWidth: '',
  collapsable: false,
  handleSidebarChange: ()=>{},
  setSidebarWidth: ()=>{},
  setCollapsable: ()=>{},
});

export const SidebarContextProvider = (props) => {
  const [sidebarWidth, setSidebarWidth] = useState(240);
  const [collapsable, setCollapsable] = useState(false);

  const handleSidebarChange = (width) => {
    setSidebarWidth(width);
  }

  const contextValue = {
    sidebarWidth,
    collapsable,
    handleSidebarChange,
    setSidebarWidth,
    setCollapsable,
  }

  return <SidebarContext.Provider value={contextValue}>{props.children}</SidebarContext.Provider>
}

export default SidebarContext;