// This component represents button as a Card with a header, icon and body
import * as React from 'react';

// MUI components

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

export default function CardButton({  cardStyles={},
                                      cardHeader="Default Header",
                                      cardSubHeader="",
                                      cardBody="",
                                      disabled=false,
                                      onClick,
                                      children,
                                      buttonStyles={},
                                      fullWidth
                                    }) {
  return (
    <Button 
      size="small" 
      disabled={disabled}
      onClick={onClick}
      style={buttonStyles}
      fullWidth
    >
      <Card sx={cardStyles}>
        <CardContent>
          <h3>{cardHeader} {children}</h3>
          { cardSubHeader != "" ? <Typography variant="caption">{cardSubHeader}</Typography> : <></> }
          { cardBody != "" ? <h4>{cardBody}</h4> : <></> }
        </CardContent>
      </Card>
      
    </Button>
  );

}
