import * as React from 'react';

// MUI components
import AppBar from '@mui/material/AppBar';
/**
  * Base app bar component.
  *
  * @param {node} children: The content of the component.
  * @param {object} classes: Override or extend the styles applied to the component.
  * @param {string} color: The color of the component. It supports both default and custom theme colors
  * @param {boolean} enableColorOnDark: If true, the color prop is applied in dark mode.
  * @param {string} position: The positioning type. The behavior of the different options is described in the MDN web docs. 
    Note: sticky is not universally supported and will fall back to static when unavailable.
  * @param {Array<func|object|bool>|func|object} sx: The system prop that allows defining system overrides as well as additional CSS styles. 
  * @returns {JSX.Element} The rendered app bar component.
*/

export default function BaseAppBar({children,
                                    classes,
                                    color = 'primary',
                                    enableColorOnDark = false,
                                    position = 'fixed',
                                    customStyles={},
                                    id
                                    }) {
  return (
    <AppBar
      classes={classes}
      color={color}
      enableColorOnDark={enableColorOnDark}
      position={position}
      data-testid={id}
      id={id}
      sx={customStyles}
    >
      {children}
    </AppBar>
  );
}