import flask from "./flask";
import { parseMessages } from "./utils";

const postDocumentMetadata = async (payload, token, username, handleOnUploadProgress) => {

    try {

    const response = await flask.post(`metadata`, 
              payload, 
              {
                headers: {
                  'Content-Type': 'application/json',
                  "Authorization": `Bearer ${token}`
                },
              }
            );
        
            return {
              status: response.status,
            //   data: messages,
            };
          } 
          catch (e) {
            console.log(e);
            return {
              status: e.response.status,
              error: e.response.statusText,
              data: "",
            };
          }
        
  
};

export default postDocumentMetadata;