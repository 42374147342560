import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AlertProvider } from "./context/AlertContext";
import { TokenContextProvider } from "./context/TokenContext";
import { ThreadContextProvider } from "./context/ThreadContext";
import { PreferenceContextProvider } from "./context/PreferenceContext";
import { SearchResultContextProvider } from "./context/SearchResultContext";
import { OperationalModeContextProvider } from "./context/OperationalModeContext";
import { TypingContextProvider } from "./context/TypingContext"
import { SidebarContextProvider } from "./context/SidebarContext";
import { ThreadPreferenceContextProvider } from "./context/ThreadPreferenceContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <TokenContextProvider>
      <PreferenceContextProvider>
        <AlertProvider>
          <SearchResultContextProvider>
            <ThreadContextProvider>
              <OperationalModeContextProvider>
                <ThreadPreferenceContextProvider>
                  <SidebarContextProvider>
                    <TypingContextProvider>
                      <App />
                    </TypingContextProvider>
                  </SidebarContextProvider>
                </ThreadPreferenceContextProvider>
              </OperationalModeContextProvider>
            </ThreadContextProvider>
          </SearchResultContextProvider>
        </AlertProvider>
      </PreferenceContextProvider>
    </TokenContextProvider>
  </React.StrictMode>
);