import React, { useState, useContext, useEffect } from "react";

// Custom components
import NavBar from "../../custom_components/NavBar/NavBar"
import SideBar from "../../custom_components/SideBar/Sidebar";
import MessageWindow from "../../custom_components/MessageWindow/MessageWindow";

// MUI components
import Box from "@mui/material/Box";

// MUI icons
import ThreadContext from "../../context/ThreadContext";
import SidebarContext from "../../context/SidebarContext"; 
const MainPage = () => {
  const SidebarCxt = useContext(SidebarContext)
  //custom styles
  const NavBarHeight = "65px";
  const NavBarStyle = { height: `${NavBarHeight}` };
  const BoxStyle = { marginLeft: `${SidebarCxt.sidebarWidth}px` };

  //state variables
  const [beginTour, setBeginTour] = useState(false);
  const [renderDocumentList, setRenderDocumentList] = useState(false);

  const threadCxt = useContext(ThreadContext);

  const onClickTour = () => {
    threadCxt.threadselect(null);
    setBeginTour(true);
  };

  //handlers
  const handleBeginTourClose = () => {
    setBeginTour(false);
  };

  return (
    <>
      <div data-testid={"navbar"} style={NavBarStyle}>
        <NavBar 
          beginTour={beginTour}
          onClickTour={onClickTour}
          handleBeginTourClose={handleBeginTourClose}
          renderDocumentList={renderDocumentList}
          setRenderDocumentList={setRenderDocumentList}
        />
      </div>

      <SideBar />

      <Box data-testid={"message-window"} style = {BoxStyle}>
        <MessageWindow
          beginTour={beginTour}
          onClickTour={onClickTour}
          handleBeginTourClose={handleBeginTourClose}
          handleOpenDocumentList={()=>setRenderDocumentList(true)}
        />
      </Box>
    </>
  )
};

export default MainPage;