import TextField from "@mui/material/TextField";
import React from 'react';

/**
  * Base Slider component.
  * @param {string} autoComplete: This prop helps users to fill forms faster, especially on mobile devices. The name can be confusing, as it's more like an autofill.
  * @param {boolean} isAutoFocus: If true, the input element is focused during the first mount.
  * @param {object} classes: Override or extend the styles applied to the component.
  * @param {string} color: The color of the component. 
  * @param {any} defaultValue: The default value. Use when the component is not controlled.
  * @param {boolean} isDisabled: If true, the component is disabled.
  * @param {boolean} isError: If true, the label is displayed in an error state.
  * @param {object} FormHelperTextProps: Props applied to the FormHelperText element.
  * @param {boolean} isFullWidth: If true, the input will take up the full width of its container.
  * @param {node} helperText: The helper text content.
  * @param {string} id: The id of the input element. Use this prop to make label and helperText accessible for screen readers.
  * @param {object} InputLabelProps: Props applied to the InputLabel element. Pointer events like onClick are enabled if and only if shrink is true.
  * @param {object} inputProps: Attributes applied to the input element.
  * @param {object} InputProps: Props applied to the Input element. It will be a FilledInput, OutlinedInput or Input component depending on the variant prop value.
  * @param {ref} inputRef: Pass a ref to the input element.
  * @param {node} label: The label content.
  * @param {string} margin: If dense or normal, will adjust vertical spacing of this and contained components.
  * @param {integer|string} maxRows: Maximum number of rows to display when multiline option is set to true.
  * @param {integer|string} minRows: Minimum number of rows to display when multiline option is set to true.
  * @param {boolean} isMultiline: If true, a textarea element is rendered instead of an input.
  * @param {string} name: Name attribute of the input element.
  * @param {Function} handleOnChange: Callback fired when the value is changed.
  * @param {Function} handleOnKeyDown: Callback fired when the value is submitted using the enter button.
  * @param {string} placeholder: The short hint displayed in the input before the user enters a value.
  * @param {isRequired} required: If true, the label is displayed as required and the input element is required.
  * @param {integer|string} rows: Number of rows to display when multiline option is set to true.
  * @param {boolean} isSelect: Render a Select element while passing the Input element to Select as input parameter. If this option is set you must pass the options of the select as children.
  * @param {object} SelectProps: Props applied to the Select element.
  * @param {string} size: The size of the component.
  * @param {Array<func|object|bool>|func|object} customStyles: The system prop that allows defining system overrides as well as additional CSS styles.
  * @param {string} type: Type of the input element. It should be a valid HTML5 input type.
  * @param {any} value: The value of the input element, required for a controlled component.
  * @param {string} variant: The variant to use.
  * @returns {JSX.Element} The rendered text field component.
*/
export default function BaseTextField({ autoComplete,
                                        isAutoFocus = false,
                                        classes,
                                        color = "primary",
                                        defaultValue,
                                        isDisabled,
                                        isError = false,
                                        FormHelperTextProps,
                                        isFullWidth = false,
                                        helperText,
                                        id,
                                        InputLabelProps,
                                        inputProps,
                                        InputProps,
                                        inputRef,
                                        label,
                                        margin = "none",
                                        maxRows,
                                        minRows,
                                        isMultiline = false,
                                        name,
                                        handleOnChange,
                                        handleOnKeyDown,
                                        handleOnClick,
                                        placeholder,
                                        isRequired = false,
                                        rows,
                                        isSelect = false,
                                        SelectProps,
                                        size,
                                        customStyles,
                                        type,
                                        value,
                                        variant = "outlined",
                                      }) {
  return (
    <TextField
      autoComplete={autoComplete}
      autoFocus={isAutoFocus}
      classes={classes}
      color={color}
      data-testid={id}
      defaultValue={defaultValue}
      disabled={isDisabled}
      error={isError}
      FormHelperTextProps={FormHelperTextProps}
      fullWidth={isFullWidth}
      helperText={helperText}
      id={id}
      InputLabelProps={InputLabelProps}
      inputProps={inputProps}
      InputProps={InputProps}
      inputRef={inputRef}
      label={label}
      margin={margin}
      maxRows={maxRows}
      minRows={minRows}
      multiline={isMultiline}
      name={name}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      onClick={handleOnClick}
      placeholder={placeholder}
      required={isRequired}
      rows={rows}
      select={isSelect}
      SelectProps={SelectProps}
      size={size}
      sx={customStyles}
      type={type}
      value={value}
      variant={variant}
      
    />
  )
};
