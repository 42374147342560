import React from "react";
import { Box, Grid, Typography, MenuItem } from "@mui/material";
import BaseSelect from "../../base_components/BaseSelect";

const SettingModelDropdown = ({ name, value, onChange }) => {
    const dropdownStyling = { width: '100%' };

    return (
        <Box sx={{ maxWidth: "90%", p: 2, mx: "auto" }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} lg={3}>
                    <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
                        Model Name
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <BaseSelect
                        value={value}
                        label="Model_Name"
                        handleOnChange={onChange}
                        customStyles={dropdownStyling}
                        name={name}
                    >
                        <MenuItem value={1}>GPT 3.5 Turbo</MenuItem>
                        <MenuItem value={2}>GPT 3.5 Turbo Large</MenuItem>
                        <MenuItem value={3}>GPT-4</MenuItem>
                        <MenuItem value={4}>GPT-4 Omni</MenuItem>
                        {/* <MenuItem value={4}>GPT-4 Large</MenuItem> */} {/* GPT-4 Large is disabled for now to avoid high costs */}
                    </BaseSelect>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettingModelDropdown;
