import React, { useState } from "react";
import {Typography, useTheme, Grid } from '@mui/material';
import MetricCard from './MetricCard';
import Plot from 'react-plotly.js';

//This component is for the user specific analytics dashboard
const UserAnalyticsDashboard = ({ analyticsData }) => {
    const theme = useTheme();
    const [selectedTimelineGraph1, setSelectedTimelineGraph1] = useState(365);
    const [selectedTimelineGraph3, setSelectedTimelineGraph3] = useState(365);
    const [selectedTimelineGraph4, setSelectedTimelineGraph4] = useState(365);

    //This function is used to filter the query type data based on the selected timeline
    function queryDataFetchingFunction(data, selectedTimeline){        
        const endDate = new Date();
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - selectedTimeline);
        
        // Filter data based on the selected timeline
        const filteredData = data.filter(item => {
            const itemDate = new Date(item.year, item.month -1 ); 
            return itemDate >= startDate;
        });

        return filteredData;
    };

    
    
    //Filtering active user, cumulative data and daily volume data for the selected timeline
    function dataFetchingFunction (data, selectedTimeline) {
        const endDate = new Date(); // Current date
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - selectedTimeline);
    
        // Filter data based on the selected timeline
        const filteredData = data.filter(item => {
          const itemDate = new Date(item.date);
          return itemDate >= startDate && itemDate <= endDate;
        });
    
        return filteredData;
      }; 

    // Map user specific query type data for Plotly 
    function mapQueryTypeForPlotly(analyticsData) {
        const data = queryDataFetchingFunction(analyticsData, selectedTimelineGraph4);
        const sortedData = data.sort((a, b) => {
            const dateA = new Date(a.year, a.month - 1);
            const dateB = new Date(b.year, b.month - 1);
            return dateA - dateB;
        });
        const monthsYears = sortedData.map(item => `${item.month}/${item.year}`);
    
        const codeRelatedData = sortedData.map(item => parseFloat(item.percent_code_related));
        const nonCodeRelatedData = sortedData.map(item => parseFloat(item.percent_non_code_related));
        
        const trace1 = {
            x: monthsYears,
            y: codeRelatedData,
            type: 'bar',
            name: 'Code Related',
            marker: { color: '#26efb5' }
        };
    
        const trace2 = {
            x: monthsYears,
            y: nonCodeRelatedData,
            type: 'bar',
            name: 'Non-Code Related',
            marker: { color: '#2aa1d1' }
        };
    
        return [trace1, trace2];
    }
      
    // Map user specific message volume for Plotly 
    const mapDailyUserCountsDataForPlotly = (analyticsData) => {
        const data = dataFetchingFunction(analyticsData, selectedTimelineGraph3);
        return {
          x: data.map(item => new Date(item.date)),
          y: data.map(item => parseInt(item.message_volume, 10)),
          fill: 'tozeroy', // Fill area below the line
          type: 'bar',
        };
      };

      // Map cumulative message volume data for Plotly
      const mapCumulativeUserCountsDataForPlotly = (analyticsData) => {
        const data = dataFetchingFunction(analyticsData, selectedTimelineGraph1);
        return {
          x: data.map(item => new Date(item.date)),
          y: data.map(item => parseInt(item.cumulative_user_count, 10)),
          type: 'scatter',
          mode: 'lines',
          fill: 'tozeroy', // Fill area below the line
        };
      };
    
    const handleTimelineChangeGraph1 = (timeline) => {
        setSelectedTimelineGraph1(timeline);
      };
    
    const handleTimelineChangeGraph3 = (timeline) => {
        setSelectedTimelineGraph3(timeline);
      };

    const handleTimelineChangeGraph4 = (timeline) => {
        setSelectedTimelineGraph4(timeline);
      };
    
    //Style of the dashboard
    const styles = {
        paper: (theme) => ({
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            paddingTop: theme.spacing(2),
        }),
        iconButton: (theme) => ({
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(2),
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '50%',
            boxShadow: theme.shadows[5],
            zIndex: 2
        }),
        box: (theme) => ({
            marginTop: theme.spacing(4),
            width: '100%',
            textAlign: 'center',
            padding: theme.spacing(4)
        }),
        header: {
            fontWeight: 600
        },
        subtitle: {
            marginBottom: theme.spacing(2)
        }
    };
       
    //Layout for query type distribution graph
    const plotlyLayout = {
        legend: {
            x: 0,
            y: -0.5,  
            traceorder: 'normal',
            orientation: 'h',  
            font: {
                color: 'rgba(255, 255, 255, 1)',  // Text color of the legend
            },
            dataLabels: 'inside',  // Display data labels inside the bars
            textposition: 'inside',  // Set text position inside the bars
        },
        barmode: 'stack',
        xaxis: {
            title: {
                text: 'Timeline',
                font: {
                    size: 11,
                    color: 'rgba(255, 255, 255, 1)'
                }
            },
            type: 'category',
            showgrid: true, // Show x-axis grid lines
            gridcolor: 'rgba(200, 200, 200, 0.1)', // Semi-transparent gray grid lines
            gridwidth: 1,
            tickfont: {
                size: 10,
                color: 'rgba(255, 255, 255, 1)', // Set the color of the tick labels
              },
            linecolor: 'rgba(255, 255, 255,1)',
            linewidth: 2
        },
        yaxis: {
            title: {
                text: 'Percentage',
                font: {
                    size: 11,
                    color: 'rgba(255, 255, 255, 1)'
                }
            },
            showgrid: true, // Show x-axis grid lines
            gridcolor: 'rgba(200, 200, 200, 0.1)', // Semi-transparent gray grid lines
            gridwidth: 1,
            tickfont: {
                size: 10,
                color: 'rgba(255, 255, 255, 1)', // Set the color of the tick labels
              },
            linecolor: 'rgba(255, 255, 255,1)',
            linewidth: 2
        },
        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
    };
    
    const plotlyConfig = {
        responsive: true
    };

    //Layout for rest of the graphs
    const layout = {
        xaxis: {
            title: {
                text: 'Timeline',
                font: {
                    size: 11,
                    color: 'rgba(255, 255, 255, 1)'
                }
            },
            showgrid: true, // Show x-axis grid lines
            gridcolor: 'rgba(200, 200, 200, 0.1)', // Semi-transparent gray grid lines
            gridwidth: 1,
            tickfont: {
                size: 10,
                color: 'rgba(255, 255, 255, 1)', // Set the color of the tick labels
              },
            linecolor: 'rgba(255, 255, 255,1)',
            linewidth: 2
            
          },
          
        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
      };

    
    return (
        <div>
            <div style={{ marginLeft: '20px', marginTop: '20px', marginBottom: '10px' }}>
                <Typography variant="body1" style={styles.subtitle}>
                    This page delivers a comprehensive user-specific analysis.
                </Typography>
            </div>
            {analyticsData && (
                <>
                    <Grid container spacing={2} style={{ padding: theme.spacing(2) }}>
                        {/** Defining Graph1 for user specific daily message volume */}
                        <Grid item xs={12} md={6}>
                            <MetricCard
                                    title="Daily Message Volume"
                                    selectedTimeline={selectedTimelineGraph3}
                                    onTimelineChange={handleTimelineChangeGraph3}
                                    details={
                                        <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                                            <Plot data={[mapDailyUserCountsDataForPlotly(analyticsData.user_daily_volume_data)]} 
                                                  layout={{...layout, type: 'bar', 
                                                    yaxis: {
                                                        title: {
                                                            text: 'Daily Message Volume',
                                                            font: {
                                                                size: 11,
                                                                color: 'rgba(255, 255, 255, 1)'
                                                            }
                                                        },
                                                        showgrid: true,
                                                        gridcolor: 'rgba(200, 200, 200, 0.1)', 
                                                        gridwidth: 1,
                                                        tickfont: {
                                                            size: 10,
                                                            color: 'rgba(255, 255, 255, 1)', 
                                                        },
                                                        linecolor: 'rgba(255, 255, 255, 1)',
                                                        linewidth: 2
                                                    },}} 
                                                    style={{ height: '45vh', 
                                                             width: '55vw', 
                                                             marginLeft: '-50px' 
                                                          }} 
                                            />
                                        </div>
                                }
                            />
                        </Grid>
                        {/** Defining Graph2 for user specific cumulative message volume */}
                        <Grid item xs={12} md={6}>
                                <MetricCard
                                    title="Cumulative Message Volume Over Time"
                                    selectedTimeline={selectedTimelineGraph1}
                                    onTimelineChange={handleTimelineChangeGraph1}
                                    details={
                                        <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>  
                                            <Plot data={[mapCumulativeUserCountsDataForPlotly(analyticsData.user_running_sum_data)]} 
                                                  layout={{...layout, yaxis: {
                                                        title: {
                                                            text: 'Cumulative Message Volume',
                                                            font: {
                                                                size: 11,
                                                                color: 'rgba(255, 255, 255, 1)'
                                                            }
                                                        },
                                                        showgrid: true, 
                                                        gridcolor: 'rgba(200, 200, 200, 0.1)', 
                                                        gridwidth: 1,
                                                        tickfont: {
                                                            size: 10,
                                                            color: 'rgba(255, 255, 255, 1)', 
                                                        },
                                                        linecolor: 'rgba(255, 255, 255, 1)',
                                                        linewidth: 2},}} 
                                                    style={{ height: '45vh', 
                                                             width: '55vw', 
                                                             marginLeft: '-50px' 
                                                          }}
                                            />
                                        </div>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ padding: theme.spacing(2) }}>
                            {/** Defining Graph3 for user specific query type distribution */}
                            {/**<Grid item xs={12} md={6}>
                                <MetricCard
                                    title="Query Type Distribution (Code vs Non-Code)"
                                    selectedTimeline={selectedTimelineGraph4}
                                    onTimelineChange={handleTimelineChangeGraph4}
                                    details={
                                        <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                                            
                                            <Plot data={mapQueryTypeForPlotly(analyticsData.user_query_type_data).map(trace => ({
                                                        ...trace,
                                                        text: trace.y.map(value => value.toFixed(2)),  
                                                        textposition: 'inside',  
                                                        textfont: {
                                                        color: 'rgba(0, 0, 0, 1)',  
                                                        },}))} 
                                                        layout={plotlyLayout} 
                                                        config={plotlyConfig} 
                                                        style={{ height: '45vh', 
                                                                 width: '55vw', 
                                                                 marginLeft: '-50px' 
                                                              }} 
                                            />
                                        </div>
                                }
                                />
                                
                            </Grid>**/}
                        </Grid>         
                </>
        )}
    </div>
    );
};
export default UserAnalyticsDashboard;
