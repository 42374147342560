import flask from "./flask";
import { parseMessages } from "./utils";

const getMessagesByMessageID = async (messageID, token) => {
  try {
    const response = await flask.get(`message/${messageID}}`, {
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    });
    const messages = parseMessages(response.data);
    return {
      status: response.status,
      data: messages.reverse(),
    };
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default getMessagesByMessageID;