import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './msalConfig';
import axios from 'axios';

export const msalInstance = new PublicClientApplication(msalConfig);

export function getProfileImage(azureToken) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + azureToken },
        responseType: 'blob'
    };

    return axios('https://graph.microsoft.com/v1.0/me/photo/$value', requestOptions)
        .then(response => {
            const imgUrl = URL.createObjectURL(response.data);
            return imgUrl;
        })
        .catch(error => {
            return 'none';
        });
}

export function call_login() {
    msalInstance.loginRedirect().catch(err => {
        // TODO: Handle errors
    });
}

export function logout() {
    return msalInstance.logout();
}

export function acquireToken(scopes) {
    const account = msalInstance.getAllAccounts()[0];

    if(!account) throw new Error('Account missing. User must be authenticated.');

    const request = {
        scopes: scopes,
        account: account
    }

    return msalInstance.acquireTokenSilent(request).then(response => {
        return response.accessToken;
    }).catch(err => {
        console.error(err);
    });
}


export function handleResponse() {
    return msalInstance.handleRedirectPromise().then(response => {
        if(response !== null) {
            return response;
        } else {
            // No token was returned, user is not authenticated
            return null;
        }
    }).catch(err => {
        console.error(err);
    });
}