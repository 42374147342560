import * as React from 'react';

// MUI components
import IconButton from '@mui/material/IconButton';

export default function ButtonIcon({color='primary',  
									disabled=false, 
                                    buttonLabel="default-button-icon-label", 
                                    handleSubmit, 
									customStyles,
                                    children }) {

	return (
		<IconButton
			color={color}
			aria-label={buttonLabel}
      		disabled={disabled}
			sx={customStyles}
			onClick={handleSubmit}
		>
			{children}
		</IconButton>
	);
}