import React from "react";
import { Box, Grid, Typography, Switch } from "@mui/material";
import BaseTooltip from "../../base_components/BaseTooltip";
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

const SettingPreferenceControlToggle = ({ label, tooltip, name, value, onChange, disabled = false }) => {
    const CustomIconStyle = { fontSize: 18, marginLeft: 1 };

    return (
        <Box sx={{ maxWidth: "90%", p: 2, mx: "auto" }}>
            <Grid container spacing={10} alignItems="center">
                <Grid item xs={12} lg={3}>
                    <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
                        {label}
                        <BaseTooltip title={tooltip} isArrow>
                            <HelpOutlineRoundedIcon sx={CustomIconStyle} />
                        </BaseTooltip>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Switch
                        checked={!!value}
                        onChange={() => {
                            onChange({
                                target: {
                                    name: name,
                                    value: Math.abs(1 - (!!value ? value : 0))
                                }
                            });
                        }}
                        name={name}
                        size="medium"
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettingPreferenceControlToggle;
