import React from "react";
import { Box, Grid, Typography, MenuItem } from "@mui/material";
import BaseSelect from "../../base_components/BaseSelect";

const SettingThemeDropdown = ({ value, onChange, disabled = false }) => {
    const dropdownStyling = { width: '100%' };

    return (
        <Box sx={{ maxWidth: "90%", p: 2, mx: "auto" }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} lg={3}>
                    <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
                        Theme
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <BaseSelect
                        value={value}
                        label="Theme"
                        handleOnChange={onChange}
                        customStyles={dropdownStyling}
                        name="theme"
                        isDisabled={disabled}
                    >
                        <MenuItem value={1}>Light</MenuItem>
                        <MenuItem value={2}>Dark</MenuItem>
                        {/* <MenuItem value={3}>High Contrast</MenuItem> */}
                    </BaseSelect>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettingThemeDropdown;
