import * as React from 'react';

// MUI components
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
/**
  * Base dialog component.
  * @param {boolean} open: If true, the component is shown.
  * @param {string} aria-describedby: The id(s) of the element(s) that describe the dialog.
  * @param {string} aria-labelledby: The id(s) of the element(s) that label the dialog.
  * @param {JSX.Element} BackdropComponent: DEPRECATED - A backdrop component. This prop enables custom backdrop rendering.
  * @param {node} children: Dialog children, usually the included sub-components.
  * @param {Object} classes: Override or extend the styles applied to the component.
  * @param {boolean} disableEscapeKeyDown: If true, hitting escape will not fire the onClose callback.
  * @param {boolean} fullScreen: If true, the dialog is full-screen.
  * @param {boolean} fullWidth: If true, the dialog stretches to maxWidth. Notice that the dialog width grow is limited by the default margin.
  * @param {string} maxWidth: Determine the max-width of the dialog. The dialog width grows with the size of the screen. Set to false to disable maxWidth.
  * @param {Function} onBackdropClick: DEPRECATED - Callback fired when the backdrop is clicked.
  * @param {Function} onClose: Callback fired when the component requests to be closed.
  * @param {JSX.Eleement} PaperComponent: The component used to render the body of the dialog.
  * @param {object} PaperProps: Props applied to the Paper element.
  * @param {string} scroll: Determine the container for scrolling the dialog.
  * @param {Array<func|object|bool>|func|object} customStyles: The system prop that allows defining system overrides as well as additional CSS styles.
  * @param {JSX.element} TransitionComponent: The component used for the transition
  * @param {integer} transitionDuration: The duration for the transition, in milliseconds. You may specify a single timeout for all transitions, or individually with an object.
  * @param {object} TransitionProps: Props applied to the transition element. By default, the element is based on this Transition component.
  * @param {object} dialogTitleProps: The props used for the dialog Title.
  * @param {string} dialogTitle: The title used for the dialog itself.
  * @param {object} dialogContentProps: The props used for the dialog content compoenent.
  * @param {JSX.element} dialogContentBody: The subcomponents used within the dialog content component.
  * @param {object} dialogActionsProps: The props used for the dialog actions component.
  * @param {JSX.element} dialogActions: The subcomponents used within the dialog actions component.
  * @returns {JSX.Element} The rendered dialog component.
*/

export default function BaseDialog({open,
                                    ariadescribedby,
                                    arialabelledby,
                                    children,
                                    classes,
                                    id,
                                    isDisableEscapeKeyDown,
                                    isFullScreen,
                                    isFullWidth,
                                    maxWidth,
                                    handleOnClose,
                                    PaperComponent,
                                    PaperProps,
                                    scroll,
                                    customStyles = {},
                                    TransitionComponent,
                                    transitionDuration,
                                    TransitionProps,
                                    dialogTitleProps,
                                    dialogTitle,
                                    dialogContentProps,
                                    dialogContentBody,
                                    dialogActionsProps,
                                    dialogActions
                                    }) {

  return (
    <Dialog
        open = {open}
        aria-describedby = {ariadescribedby}
        aria-labelledby = {arialabelledby}
        data-testid={id}
        children = {children}
        classes = {classes}
        disableEscapeKeyDown = {isDisableEscapeKeyDown}
        fullScreen = {isFullScreen}
        fullWidth = {isFullWidth}
        id={id}
        maxWidth = {maxWidth}
        onClose = {handleOnClose}
        PaperComponent = {PaperComponent}
        PaperProps = {PaperProps}
        scroll = {scroll}
        sx= {customStyles}
        TransitionComponent = {TransitionComponent}
        transitionDuration = {transitionDuration}
        TransitionProps = {TransitionProps}
        >
        <DialogTitle {...dialogTitleProps}>{dialogTitle}</DialogTitle>
        <DialogContent {...dialogContentProps}>{dialogContentBody}</DialogContent>
        <DialogActions {...dialogActionsProps}>{dialogActions}</DialogActions>
    </Dialog>
  );
}