import React, { useContext } from 'react';

// MUI components
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// base components
import BasePaper from "../../base_components/BasePaper";

//custom components
import CodeBlock from "../CodeBlock/CodeBlock";
import MessageList from "../MessageList/MessageList";
import { textAlign } from '@mui/system';

//context
import ThreadContext from '../../context/ThreadContext';

//constants
const CHARACTER_LIMIT = 200;
const NUMBER_OF_CODE_LINES_TO_SHOW = 3;

const SearchResultItem = ({ 
  handleClose, 
  searchQueryText, 
  index, 
  content, 
  created_at, 
  messageID, 
  sender, 
  threadID, 
  language 
}) => {
  const threadCxt = useContext(ThreadContext)
    
  //custom styles
  const secondPaperStyle = {
    margin: "10px",
    padding: "10px",
    textTransform: "inherit"
  };
  const textOverflowStyle = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
  const timestampFontStyle = { fontSize: "8pt", fontStyle: "italic", float: "right" };

  const escapedSearchQueryText = searchQueryText?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

  const findLastTwoWords = (sentence) => {
    const words = sentence.trim().split(' ');
    let wordsSlice = words.slice(-2); // Get the last two words from the array
    wordsSlice.unshift("... "); // add ... to the beginning of the array and a space to the end of array
    wordsSlice.push(" ");

    return wordsSlice.join(' '); // stringify array and return
  };

  const renderText = (message, text) => {
    const fontWeightStyle = { fontWeight: "800" };
    const parts = message.split(new RegExp(escapedSearchQueryText, 'i'))
    let firstPart = parts.shift();
    if (firstPart.length > 50) {
      firstPart = findLastTwoWords(firstPart); //only include first two words if first part is lengthy
    }
    return (
      <>
        {firstPart}
        <strong style={fontWeightStyle}>{text}</strong>
        {
          parts.map((part, index) => {
            return <>
              {part}
              {
                index === parts.length - 1 ?
                  <></>
                  :
                  <strong
                    key={index}
                    style={fontWeightStyle}
                  >
                    {text}
                  </strong>
              }
            </>
          })
        }
      </>
    );
  };

  const findNewLine = (codeBlock, type) => {
    const searchTermIdx = codeBlock.indexOf(searchQueryText);

    switch (type) {
      case 1: //new line to the left of (or before) search term

        let countA = 0;
        let newlineToLeftIdx = -1;
        let isBeginning = false;

        //find first new line to the left of search term:
        newlineToLeftIdx = codeBlock.lastIndexOf("\n", searchTermIdx);
        while (countA < NUMBER_OF_CODE_LINES_TO_SHOW) {  //if no more new lines exist, default to showing beginning of codeblock
          if (newlineToLeftIdx === -1) {
            newlineToLeftIdx = 0;
            isBeginning = true;
            return { newlineToLeftIdx, isBeginning };
          }
          countA++;
          newlineToLeftIdx = codeBlock.lastIndexOf("\n", newlineToLeftIdx - 1);
        }
        return { newlineToLeftIdx, isBeginning };


      case 2://new line to the right of (or after) search term
        let countB = 0;
        let newlineToRightIdx = -1;
        let isEnd = false;

        //find first new line to the left of search term:
        newlineToRightIdx = codeBlock.indexOf("\n", searchTermIdx);
        while (countB < NUMBER_OF_CODE_LINES_TO_SHOW) {
          if (newlineToRightIdx === -1) {  //if no more new lines exist, default to showing end of codeblock
            newlineToRightIdx = codeBlock.length;
            isEnd = true;
            return { newlineToRightIdx, isEnd };
          }
          countB++;
          newlineToRightIdx = codeBlock.indexOf("\n", newlineToRightIdx + 1);
        }

        return { newlineToRightIdx, isEnd };

      default:
        console.error("Invalid type entered.");
        break;

    }
  };

  const renderCodeBlock = (language, codeBlock, searchTerm) => {
    //find all search terms in the code block 
    const regex = new RegExp(escapedSearchQueryText, "gi");
    const parts = codeBlock.split(regex);

    //return code block with word highlighted in yellow
    let formattedCode =
      <pre style={{ padding: "20px" }}>
        {
          parts.map((part, index) => {
            return (
              index !== parts.length - 1 ?
                <>
                  <span key={"part" + index}>{part}</span>
                  <span key={index} style={{ color: "yellow" }}>{searchTerm}</span>
                </>
                :
                <span key={"part" + index}>{part}</span>
            )
          })
        }
      </pre >;

    return (
      <CodeBlock key={index} language={language}
        showCopyIcon={false}
        customStyles={{ height: "200px", overflowY: "scroll" }}
        code={formattedCode} />
    );
  };

  const displayContent = (search_result) => {
    if (content.includes("<code>")) {
      //extract code block and only render one code block in a message which contains the search term
      const blocks = content.split(/<code>|<\/code>/);
      const codeBlocks = blocks.filter((_, index) => index % 2 !== 0);
      const codeBlock = codeBlocks.find(code => code.includes(escapedSearchQueryText));

      const messageBlock = blocks[0]; //line of text before code block

      return <>
        {messageBlock}
        {renderCodeBlock(language, codeBlock, searchQueryText)}
        <h3>...</h3>
      </>;
    }
    else {
      return renderText(content, searchQueryText);
    }

  };

  const handlePaperClick = (threadID, messageID) => {
    threadCxt.threadselect(threadID);
    threadCxt.messageselect(messageID)
    threadCxt.setMessageSelected(true)
    handleClose();
  };

  return (
    <BasePaper
      key={index}
      elevation={1}
      component={Button}
      handleClick={() => handlePaperClick(threadID, messageID)}
      customStyles={secondPaperStyle}
      id={"search-result-paper-id"}
    >
      <Grid container spacing={2} textAlign={"left"}>
        <Grid item xs={12}>
          <span>
            {sender}
          </span>
          <span style={timestampFontStyle}>
            {created_at}
          </span>
        </Grid>
        <Grid item xs={12} fontSize={14}>
          <div style={textOverflowStyle}
          >
            {displayContent(content)}
          </div>
        </Grid>
      </Grid>
    </BasePaper>
  );
};

export default SearchResultItem;