import React, { useState } from 'react';

//MUI icons
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// base components
import BaseDialog from '../../base_components/BaseDialog';
import BaseTooltip from '../../base_components/BaseTooltip';
import ButtonIcon from '../../base_components/ButtonIcon';

//custom components
import SearchResultItem from "../SearchResultItem/SearchResultItem";
import BasePaper from '../../base_components/BasePaper';
import language from 'highlight.js/lib/languages/1c';
import { Typography } from '@mui/material';

const CloseSettingsButton = ({ onClick }) => {
  return (
    <ButtonIcon handleSubmit={onClick}>
      <BaseTooltip
        title="Close Search Results"
        isArrow
      >
        <CloseRoundedIcon />
      </BaseTooltip>
    </ButtonIcon>
  );
};

const SearchResultsList = ({ 
  open = false, 
  handleClose, 
  searchQueryText, 
  searchResultsList = [],
  viewMostRecentSearchResults=false 
}) => {
  
  //custom styles
  const unique_threadID = open ? searchResultsList.map(search_result => search_result.threadID) : [];
  const unique_threadnames = open ? searchResultsList.map(search_result => search_result.thread_name) : [];
  const uniqueCategories = [...new Set(unique_threadID)];

  const firstPaperStyle = { display: 'grid', margin: "10px" };
  const threadNameStyle = { margin: "10px", paddingTop: "10px" };
  const dialogHeaderStyle = { display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px" };
  const searchResultsHeaderStyle = { fontWeight: "bold" };
  const closeButtonStyle = { marginLeft: "auto" };
  
  // content to be shown in the header of the dialog
  const dialogHeader =
    <div style={dialogHeaderStyle}>
      <div
        style={searchResultsHeaderStyle}
      >
        { viewMostRecentSearchResults ?
          <Typography variant="h5" style={{fontWeight: 'bold'}}>Most Recent Search Results</Typography>
          :
          <Typography variant="h5" style={{fontWeight: 'bold'}}>Search Results</Typography>
        }
        {searchResultsList.length} results
      </div>
      <div style={closeButtonStyle}>
        <CloseSettingsButton
          onClick={handleClose}
        />
      </div>
    </div>;

  // content to be shown in the body of the dialog
  const dialogContent = uniqueCategories.map((thread_id, thread_index) => {
    return (
      <BasePaper
        key={thread_id + thread_index}
        elevation={2}
        customStyles={firstPaperStyle}
      >
        <div style={threadNameStyle}>
          {unique_threadnames[thread_index]}
        </div>
        {searchResultsList.filter(search_result => search_result.threadID === thread_id).map((search_result, index) => (
          <SearchResultItem
              handleClose={handleClose}
              key={search_result.messageID}
              index={index}
              language={language}
              content={search_result.content}
              created_at={search_result.created_at}
              messageID={search_result.messageID}
              sender={search_result.sender}
              threadID={search_result.threadID}
              thread_name={search_result.thread_name}
              searchQueryText={searchQueryText}
            />
        ))}
      </BasePaper>
    )
  })
  return (
    <BaseDialog
      open={open}
      dialogTitleProps={{ id: "search-results-dialog-title" }}
      dialogTitle={dialogHeader}
      dialogContentProps={{ id: "search-results-dialog-description" }}
      dialogContentBody={dialogContent}
      maxWidth={'lg'}
      customStyles={{ minWidth: '25vw' }}
      id={'search-dialog-id'}
    >
    </BaseDialog>
  );

};

export default SearchResultsList;