import React, { useEffect, useState, useContext, useRef } from "react";

//MUI components
import Grid from "@mui/material/Grid";

//MUI icons
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import Info from "@mui/icons-material/Info";

// Base components
import ButtonIcon from "../../base_components/ButtonIcon";
import BaseAlert from "../../base_components/BaseAlert";

//custom component
import ChatDownload from "../ChatDownload/ChatDownload";
import MessagePrompt from "../MessagePrompt/MessagePrompt";
import QuickActionsPanel from "../QuickActionsPanel/QuickActionsPanel";
import MessageList from "../MessageList/MessageList";
import { Box, Chip, LinearProgress, Typography } from "@mui/material";
import ThreadPreferenceDialog from "../ThreadPreferenceDialog/ThreadPreferenceDialog";
import MessageWindowNavBar from "../MessageWindowNavBar/MessageWindowNavBar";

// Context
import TokenContext from "../../context/TokenContext";
import ThreadContext from "../../context/ThreadContext";
import { OperationalModeContext } from "../../context/OperationalModeContext";
import { AlertContext } from "../../context/AlertContext";

//themes
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import highContrastTheme from "../../themes/highContrastTheme";

// api
import getMessages from "../../api/getMessages";
import getMessagesByMessageID from "../../api/getMessagesByMessageID";
import getNews from "../../api/getNews";
import putMessage from "../../api/putMessage";
import postMessage from "../../api/postMessage";
import PreferenceContext from "../../context/PreferenceContext";
import SidebarContext from "../../context/SidebarContext";
import { useTheme } from "@emotion/react";
import getThreadPreferences from "../../api/getThreadPreferences";

import { MODEL_WORDS_TO_NUMERIC, MODEL_NUMERIC_TO_WORDS, THEME_NUMERIC_TO_WORDS,
  EMBEDDING_MODEL_NUMERIC_TO_WORDS, CHUNKER_TYPE_NUMERIC_TO_WORDS
 } from "../../config/constants";

import TypingContext from "../../context/TypingContext";
import ThreadPreferenceContext from "../../context/ThreadPreferenceContext";
import StagingThreadPreferenceDialog from "../StagingThreadPreferenceDialog/StagingThreadPreferenceDialog";
//by default the MessageWindow will display the QuickActionsPanel until a thread is clicked or a new chat is created
const MessageWindow = ({
  beginTour,
  handleBeginTourClose,
  onClickTour,
  id,
  handleOpenDocumentList
}) => {
  const [userInputText, setUserInputText] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
  const [promptPlaceholder, setPromptPlaceholder] = useState("");
  const typingCxt = useContext(TypingContext);
  const [responsePlaceholder, setResponsePlaceholder] = useState('█');
  const [loading, setLoading] = useState(false);
  const [requestProgress, setRequestProgress] = useState(null);
  const [isEditing, setIsEditing] = useState(null);
  const [selectedSearchMsgId, setSelectedSearchMsgId] = useState(null);
  const [showScrollDownButton, setShowScrollDownButton] = useState(false);
  const [news, setNews] = useState([]);
  const [openAlerts, setOpenAlerts] = useState();
  const [boxHeight, setBoxHeight] = useState('150');
  const [isThreadPreferenceDialogOpen, setIsThreadPreferenceDialogOpen] = useState(false);
  const [isStagingThreadPreferenceDialogOpen, setIsStagingThreadPreferenceDialogOpen]= useState(false);
  const [stagingThreadPreferences, setStagingThreadPreferences] = useState({});
  const [isStagingThreadPreferenceDisabled, setIsStagingThreadPreferenceDisabled] = useState(false);

  const dummyDivRef = useRef(null);
  const selectedMessageRef = useRef(null);

  const tokenCxt = useContext(TokenContext);
  const threadCxt = useContext(ThreadContext);
  const preferenceCxt = useContext(PreferenceContext);
  const preferredTheme = preferenceCxt.preferenceList.theme;
  const operationalModeCxt = useContext(OperationalModeContext);
  const alertCxt = useContext(AlertContext);
  const { mode, selectedUserDocuments, selectedInternalDocumentSources } =
    operationalModeCxt;
  const { showAlert } = alertCxt;
  const threadPreferenceCxt = useContext(ThreadPreferenceContext);

  //custom styles
  const ThemeBackgroundColor = {
    1: lightTheme.palette.background.default,
    2: darkTheme.palette.background.default,
    3: highContrastTheme.palette.background.default,
  };

  const ThemeColor = {
    1: lightTheme.palette.primary.main,
    2: darkTheme.palette.primary.main,
    3: highContrastTheme.palette.primary.main,
  }

  const regenerateButtonStyle = {
    display: "flex",
    justifyContent: "center",
    background: `linear-gradient(to bottom, transparent, ${ThemeBackgroundColor[preferredTheme]})`,
  };

  const footerStyle = {
    background: `transparent`,
    display: "flex",
    alignItems: "end",
    justifyContent: 'center',
    position: "fixed",
    bottom: "0px",
    paddingBottom: "10px",
    paddingTop: "10px",
    margin: 'auto',
    width: "fill-available",
  };

  const scrollButtonPlacementStyle = {
    position: "absolute",
    bottom: 100,
    right: 20,
  };

  const BoxStyle = {
    position: "relative",
    width: '100%',
    // extra condition for height to differentiate between QAP and messagelist
    height: !threadCxt.threadID ? "100%" : `calc(100vh - 150px)`, // height offset of navbar, followup questions & prompt. controlled by boxHeight state variable.
    overflowY: "auto", //disable scroll for QAP
    overflowX: "hidden",
  };
  const FlexCenterStyle = { display: "flex", justifyContent: "center" };
  const ChatDownloadStyle = { height: "50px" };

  const handleSelectedMessageId = (messageId) => {
    setSelectedSearchMsgId(messageId);
  };

  const MessageWindowContainerStyle = {
    // marginBottom: "2rem",
    minHeight: "100%",
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    // height: "100%",
  };
  const SidebarCxt = useContext(SidebarContext)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const handleResize = () => {
    if (window.innerWidth <= 1000) {
      SidebarCxt.setSidebarWidth(0)
    }
    else {
      SidebarCxt.setSidebarWidth(240)
    }
  }

  const newsAlertStyle = {
    margin: "10px",
    position: "fixed", // Make the alert float
    zIndex: 999, // Make sure the alert is always on top
    width: "70%",
  };

  useEffect(() => {
    const fetchNews = async () => {
      try {
        let response = await getNews(tokenCxt.token);
        if (response && response.status === 200) {
          setNews(response.data);
          setOpenAlerts(response.data.map(() => true));
        } else {
          console.error("Error while retrieving news from backend.");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchNews().catch((error) => console.log(error));

  }, []);

  useEffect(() => {
    let isSubscribed = true;
    const loadMessages = async () => {
      const threadID = threadCxt.threadID;
      //retrieve all messages associated with thread id from backend
      if (!threadID) {
        setMessageList([]);
      } else {
        let response = await getMessages(threadCxt.threadID, tokenCxt.token);
        if (response && response.status === 200) {
          const _messages = response.data.reverse();
          setMessageList(_messages);

          // set height of message window
          setBoxHeight(_messages[_messages.length - 1]?.followupQuestions ? '240' : '150');

        } else {
          // showAlert("Error retrieving messages related to this thread.", "error");
          console.error(
            "Error while retrieving messages related to this thread from backend."
          );
        }
      }
    };

    loadMessages().catch((error) => console.log(error));

    return () => (isSubscribed = false);
  }, [threadCxt.threadID, threadCxt.messageSelected]);

  useEffect(() => {
    !!threadCxt.messageID && handleSelectedMessageId(threadCxt.messageID);
    if (selectedMessageRef.current && threadCxt.messageSelected) {
      selectedMessageRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!threadCxt.messageSelected) {
      !!threadCxt.threadID && handleOnScrollDownClick();
    }
  }, [messageList, threadCxt.messageID]);

  useEffect(() => {
    !threadCxt.threadID && !!preferenceCxt.preferenceList && setStagingThreadPreferences({...preferenceCxt.preferenceList});
    // !threadCxt.threadID && !!preferenceCxt.preferenceList && setStagingThreadPreferences({...preferenceCxt.preferenceList});
  }, [threadCxt.threadID, preferenceCxt.preferenceList])
  

  //handler
  const handleOnUploadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setRequestProgress(percentCompleted);
  };

  const getResponseForPrompt = async (rawInputMessage) => {
    if (
      (mode === "User Uploaded Doc Q&A" &&
        selectedUserDocuments.length === 0) ||
      (mode === "Jarvis" && selectedInternalDocumentSources.length === 0)
    ) {
      showAlert(
        "Please select at least one document or source to proceed, else switch to General mode.",
        "error",
        7000
      );
      return;
    }
    if (rawInputMessage !== "") {

      const displayProcessingMessageTimeout = setTimeout(() => {
        showAlert("Processing your request. This may take longer than usual, possibly due to high demand. Please wait...", "info", 7000);
      }, 60000);

      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(
          () => reject(new Error("Response generation timeout (180s) exceeded")),
          180000
        );
      });

      const responsePromise = new Promise((resolve, reject) => {
        (async () => {
          setIsGeneratingResponse(true);

          const latest_message_id = !threadCxt.threadID
            ? null
            : messageList[messageList.length - 1].messageID;

          let response = {};
          try {
            const stagingThreadPreferencesPayload = !!stagingThreadPreferences && {...stagingThreadPreferences};
            if (!!stagingThreadPreferencesPayload){
              delete stagingThreadPreferencesPayload["theme"];
              stagingThreadPreferencesPayload["model"] = typeof stagingThreadPreferencesPayload["model"] == "number" 
              ? MODEL_NUMERIC_TO_WORDS[stagingThreadPreferencesPayload["model"]] 
              : stagingThreadPreferencesPayload["model"];
              stagingThreadPreferencesPayload["embeddingModel"] = typeof stagingThreadPreferencesPayload["embeddingModel"] == "number" 
              ? EMBEDDING_MODEL_NUMERIC_TO_WORDS[stagingThreadPreferencesPayload["embeddingModel"]] 
              : stagingThreadPreferencesPayload["embeddingModel"];
              stagingThreadPreferencesPayload["chunkerType"] = typeof stagingThreadPreferencesPayload["chunkerType"] == "number" 
              ? CHUNKER_TYPE_NUMERIC_TO_WORDS[stagingThreadPreferencesPayload["chunkerType"]] 
              : stagingThreadPreferencesPayload["chunkerType"];
            }
            response = await postMessage(
              threadCxt.threadID,
              rawInputMessage,
              latest_message_id,
              tokenCxt.token,
              handleOnUploadProgress,
              operationalModeCxt.mode,
              operationalModeCxt.selectedUserDocuments,
              operationalModeCxt.selectedInternalDocumentSources,
              (!!stagingThreadPreferences ? stagingThreadPreferencesPayload : null)
            );

            if (response.status === 400 || response.status === 500) {
              showAlert(
                response.msg,
                "error",
                60000
              );
            }
            else{
              setUserInputText("");
            }

            setIsGeneratingResponse(false);
          } catch (error) {
            setUserInputText(rawInputMessage)
            setIsGeneratingResponse(false);
            reject(error);
            console.log(error);
            showAlert(
              "Oops! Looks like RiskGPT is playing hide and seek. Try again now, or catch up later when it's feeling more chatty.",
              "error",
              60000
            );
          }

          try {
            if (!!response && response.status === 201) {
              if (!threadCxt.threadID) {
                const new_thread_data = {
                  thread_id: response.data[0].threadID,
                  thread_name: response.data[0].content.slice(0, 20) + "...",
                };
                setMessageList(response.data);
                threadCxt.setThreadList([
                  new_thread_data,
                  ...threadCxt.threadList,
                ]);
                threadCxt.threadselect(new_thread_data.thread_id);
                typingCxt.typeMessage(response.data[1].messageID)
              } else {
                setMessageList((messageList) => [
                  ...messageList,
                  ...response.data,
                ]);
                typingCxt.typeMessage(response.data[1].messageID)
              }
              handleOnScrollDownClick();
              resolve(response);
              setUserInputText("");
            } else {
              reject(new Error("Error while making API call"));
            }
          } catch (error) {
            setIsGeneratingResponse(false);
            reject(error);
            // console.log(error);
          }
        })();
      });

      return Promise.race([responsePromise, timeoutPromise])
        .then(() => {
          clearTimeout(displayProcessingMessageTimeout);
        })
        .catch((error) => {
          clearTimeout(displayProcessingMessageTimeout);
          throw error;
        });
    }
  };

  const addNewUserMessageToList = (msg) => {
    if (msg && msg !== "") {
      setMessageList((previousList) => [...previousList, msg]);
    }
  };

  const handleGetHistoricalMessages = async (messageID, currentMessageID) => {
    let response = await getMessagesByMessageID(messageID, tokenCxt.token);

    if (response && response.status === 200) {
      const indexOfPreviousMessage = messageList.findIndex(
        (item) => item.messageID === currentMessageID
      );

      setMessageList((messageList) => [
        ...messageList.slice(0, indexOfPreviousMessage),
        ...response.data,
      ]);
      handleOnScrollDownClick();
    } else {
      console.error("Error while making API call to backend to save settings");
    }
  };

  // handler keeps track of every key that is entered into text field
  const handleChange = (event) => {
    setUserInputText(event.target.value);
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await getResponseForPrompt(userInputText);
    } catch (error) {
      console.error("Error while handling button click");
    } finally {
      setLoading(false); // Set loading state to false after the API call regardless of success or error)
    }
  };


  const handleKeyDown = async (event, followUpQuestion = null) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    // when prompt is a follow-up question that is suggested by the model
    if (followUpQuestion) {
      try {
        setPromptPlaceholder(followUpQuestion);
        setLoading(true);
        await getResponseForPrompt(followUpQuestion);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // Set loading state to false after the API call regardless of success or error)
      }
      return;
    }

    // when prompt is typed by the user
    if (loading) {
      return; // prevent further submissions
    }
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();

      let rawInputMessage = event.target.value;
      try {
        setPromptPlaceholder(event.target.value);
        setLoading(true);
        await getResponseForPrompt(rawInputMessage);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false); // Set loading state to false after the API call regardless of success or error)
      }
    }
  };

  const handleEdit = async (messageID, newMessage) => {
    try {
      if (!newMessage) return;
      setIsEditing(true);
      let response = await putMessage(
        messageID,
        newMessage,
        tokenCxt.token,
        operationalModeCxt.mode,
        operationalModeCxt.selectedUserDocuments,
        operationalModeCxt.selectedInternalDocumentSources
      );
      setIsEditing(false);
      if (response && response.status === 200) {
        const targetIndex = messageList.findIndex(
          (item) => item.messageID === messageID
        );
        setMessageList((messageList) => [
          ...messageList.slice(0, targetIndex),
          ...response.data,
        ]);
        handleOnScrollDownClick();
        typingCxt.typeMessage(response.data[1].messageID)
        return response.data[0].messageID;
      } else {
        console.error("API call returned response of 500.");
      }
    } catch (error) {
      console.error("Error while making API call to edit message.");
    }
  };

  const handleScroll = (event) => {
    const { target } = event;
    const { scrollTop, clientHeight, scrollHeight } = target;

    // setting upper bound of scrollTop to below 0 so that scrollDown button does not disappear when scroll reaches 0
    setShowScrollDownButton(
      scrollTop > -0.5 && scrollTop + clientHeight < scrollHeight
    );
  };

  const handleOnScrollDownClick = () => {
    const elementNode = dummyDivRef.current;
    elementNode.scrollIntoView({ behavior: "smooth" });
  };

  const handleNewsClose = (index) => {
    setOpenAlerts((prevOpenAlerts) =>
      prevOpenAlerts.map((isOpen, i) => (i === index ? false : isOpen))
    );
  };

  return (
    <Box display={"flex"}>
      {isGeneratingResponse || isEditing ? (
        <LinearProgress value={requestProgress} width="20vh" />
      ) : (
        ""
      )}

      <Box sx={BoxStyle} onScroll={handleScroll} data-testid={"message-window-body-id"}>
        <Grid container sx={{MessageWindowContainerStyle}}>
          <Grid item xs={12} minHeight={"5vh"} sx={{position: "sticky", top: "0px"}}>
            <MessageWindowNavBar 
              ThemeBackgroundColor={ThemeBackgroundColor} 
              preferredTheme={preferredTheme} 
              ThemeColor={ThemeColor}
              modelLabel={MODEL_NUMERIC_TO_WORDS[!!threadCxt.threadID ? threadPreferenceCxt.preferenceList.model : stagingThreadPreferences.model ]} 
              setIsThreadPreferenceDialogOpen={setIsThreadPreferenceDialogOpen}
              handleOpenDocumentList={handleOpenDocumentList}
              setIsStagingThreadPreferenceDialogOpen={setIsStagingThreadPreferenceDialogOpen}
            /> 
          </Grid>
          {!threadCxt.threadID ? (
            <Grid item xs={12} minHeight={"80%"}>
              {news.map((newsItem, index) => {
                return (
                  openAlerts[index] && (
                    <BaseAlert
                      key={index}
                      severity={newsItem.news_type}
                      customStyles={{
                        ...newsAlertStyle,
                        top: `${60 + index * 60}px`,
                      }}
                      handleOnClose={() => handleNewsClose(index)}
                    >
                      {newsItem.message}
                    </BaseAlert>
                  )
                );
              })}

              <Grid container>
                <Grid item>
                  <QuickActionsPanel
                    beginTour={beginTour}
                    onClickTour={onClickTour}
                    handleBeginTourClose={handleBeginTourClose}
                    setIsStagingThreadPreferenceDialogOpen={setIsStagingThreadPreferenceDialogOpen}
                    stagingThreadPreferences={stagingThreadPreferences}
                    id={"quick-actions-panel-id"}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} minHeight={"80%"}>
              <MessageList
                newUserMessage={userInputText}
                messageList={messageList}
                addNewUserMessageToList={addNewUserMessageToList}
                dummyDivRef={dummyDivRef}
                setMessageList={setMessageList}
                handleGetHistoricalMessages={handleGetHistoricalMessages}
                handleEdit={handleEdit}
                isGeneratingResponse={isGeneratingResponse}
                promptPlaceholder={promptPlaceholder}
                responsePlaceholder={responsePlaceholder}
                selectedSearchMsgId={selectedSearchMsgId}
                selectedMessageRef={selectedMessageRef}
                id={"message-list-id"}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <div style={scrollButtonPlacementStyle}>
        {showScrollDownButton && !!threadCxt.threadID && (
          <ButtonIcon handleSubmit={handleOnScrollDownClick}>
            <ArrowCircleDownRoundedIcon />
          </ButtonIcon>
        )}
      </div>
      <Box sx={footerStyle}>
        <Box sx={{ width: '70%' }}>
          <MessagePrompt
            messageValue={userInputText}
            setUserInputText={setUserInputText}
            handleChange={handleChange}
            getResponseForPrompt={getResponseForPrompt}
            userInputText={userInputText}
            handleButtonClick={handleButtonClick}
            handleKeyDown={handleKeyDown}
            loading={loading}
            isGeneratingResponse={isGeneratingResponse}
            isEditing={isEditing}
            followUpQuestions={messageList[messageList.length - 1]?.followupQuestions} //only interested in the most recent followup questions
            stagingThreadPreferences={stagingThreadPreferences}
          />
        </Box>
        <Box sx={{ width: '5%' }}>
          {!!threadCxt.threadID ? (
            <ChatDownload
              conversation={messageList}
              customStyles={ChatDownloadStyle}
            />
          ) : (<></>
          )}
        </Box>
      </Box>
      {/* // REGENERATE BUTTON 
        <div style={regenerateButtonStyle}>
        <BaseButton
          startIcon={<LoopRoundedIcon sx={{ color: "#FFFFFF" }} />}
        >
          Regenerate Response
        </BaseButton>
      </div> 
      */}
      <ThreadPreferenceDialog 
        open={isThreadPreferenceDialogOpen} 
        handleClose={()=>{setIsThreadPreferenceDialogOpen(false)}} 
      />
      <StagingThreadPreferenceDialog
        open={isStagingThreadPreferenceDialogOpen}
        handleClose={()=>{setIsStagingThreadPreferenceDialogOpen(false)}}
        stagingThreadPreferences={stagingThreadPreferences}
        setStagingThreadPreferences={setStagingThreadPreferences}
        isStagingThreadPreferenceDisabled={isStagingThreadPreferenceDisabled}
      />
    </Box>
  );
};

export default MessageWindow;