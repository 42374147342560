import React, { useState } from "react";
import { Chip, Stack, TextField, IconButton, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BaseTextField from "../../base_components/BaseTextField";

const Tags = ({ selectedTags, onUpdateTags, customTextFieldStyles }) => {
  const [newTag, setNewTag] = useState("");

  const handleAddTag = () => {
    const tag = newTag.trim();
    if (tag && !selectedTags.includes(tag)) {
      const updatedTags = [...selectedTags, tag];
      onUpdateTags(updatedTags);
      setNewTag(""); // Reset input after adding
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    const updatedTags = selectedTags.filter(tag => tag !== tagToDelete);
    onUpdateTags(updatedTags);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddTag();
    }
  };

  return (
    <>
      <Stack direction="row" spacing={1} wrap="wrap" sx={{ mb: 2, mt:1 }}>
        {selectedTags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => handleDeleteTag(tag)}
            color="primary"
            variant="outlined"
            sx={{ mb: 1, mr: 1 }}
          />
        ))}
        <BaseTextField
          value={newTag}
          handleOnChange={(e) => setNewTag(e.target.value)}
          handleOnKeyDown={handleKeyDown}
          placeholder="Add tag(s) (Optional)"
          size="small"
          customStyles={{ ...customTextFieldStyles, width: 300, flexGrow: 0,}}
        />
        <Tooltip title="Add tag">
          <IconButton onClick={handleAddTag} color="primary" disabled={!newTag.trim()} sx={{ mb: 1 }}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </>
  );
};

export default Tags;
