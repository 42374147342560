import flask from "./flask";

// Delete user's selected thread
const deleteThread = async (threadID, token) => {
  try {
    const response = await flask.delete('thread',
      {
        data: {
          'thread_id' : threadID
        },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response;
  } catch (e) {
    return {
      status: e.response.status,
      error: e.response.statusText,
      data: "",
    };
  }
};

export default deleteThread;