import flask from "./flask";

const postDocument = async (
  formData, // Use formData to handle both regular fields and file uploads
  token,
  owner,
  handleOnUploadProgress = null
) => {
  try {
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    };
    
    if (handleOnUploadProgress) {
      config.onUploadProgress = handleOnUploadProgress;
    }

    const response = await flask.post(`document`, formData, config);

    // Capture the URL of the document from the response
    const documentUrls = response.data["documents"].map(document => document.url);
    console.log('DOCUMENT URLS', documentUrls);

    return {
      status: response.status,
      data: documentUrls,
    };
  }
  catch (e) {
    console.log(e);
    return {
      status: e.response ? e.response.status : 500,
      error: e.response ? e.response.statusText : 'Unknown Error',
      data: e.response ? e.response.data : "",
    };
  }
};

export default postDocument;
